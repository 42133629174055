import { AsyncData, Result } from "@swan-io/boxed";
import { ClientError } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { setClipboardText } from "@swan-io/lake/src/utils/clipboard";
import { isNotNullishOrEmpty, isNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { useState } from "react";
import { StyleSheet, Text } from "react-native";
import { P, match } from "ts-pattern";
import { t } from "../utils/i18n";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  container: {
    width: 1,
    flexGrow: 1,
    paddingHorizontal: 16,
    flexWrap: "wrap",
  },
  labelledValue: {
    paddingVertical: spacings[8],
  },
  rejectionCode: {
    userSelect: "all",
  },
  value: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 320,
  },
});

type Props = {
  results: AsyncData<Result<{ key: string; value: string }[], ClientError | { rejection: string }>>;
  fields?: { key: string; placeholder?: string; label: string }[];
};

export const SimulatorResponses = ({ fields, results }: Props) => {
  const [visibleState, setVisibleState] = useState<"copy" | "copied">("copy");
  const resultsToDisplay = fields?.map(({ key, label, placeholder }) => ({
    key,
    label,
    placeholder,
    value: match(results)
      .with(
        AsyncData.P.Done(Result.P.Ok(P.select())),
        values => values.find(({ key: k }) => k === key)?.value,
      )
      .otherwise(() => placeholder),
  }));

  return (
    <Box direction="row" alignItems="center" justifyContent="start" style={styles.container}>
      <Box direction="row" alignItems="center" style={styles.labelledValue}>
        <LakeText color={colors.gray[900]} variant="medium">
          {t("simulator.status")}:
        </LakeText>

        <Space width={12} />

        {match(results)
          .with(AsyncData.P.NotAsked, () => (
            <Tag color="gray">{t("simulator.status.neverExecuted")}</Tag>
          ))
          .with(AsyncData.P.Loading, () => (
            <Tag color="warning">{t("simulator.status.loading")}</Tag>
          ))
          .with(AsyncData.P.Done(Result.P.Ok(P.any)), () => (
            <Tag color="positive">{t("simulator.status.success")}</Tag>
          ))
          .with(AsyncData.P.Done(Result.P.Error({ rejection: P.select() })), rejectionCode => (
            <Tag color="negative">
              <Text>
                {t("simulator.status.rejected")}: {t("simulator.status.rejectionCode")}
              </Text>

              <Space width={4} />
              <Text style={styles.rejectionCode}>{rejectionCode}</Text>
            </Tag>
          ))
          .with(AsyncData.P.Done(Result.P.Error(P.any)), () => (
            <Tag color="negative">{t("simulator.status.error")}</Tag>
          ))
          .exhaustive()}
      </Box>

      <Space width={12} />

      {resultsToDisplay?.map(({ key, label, value, placeholder }) =>
        isNotNullishOrEmpty(value) || isNotNullishOrEmpty(placeholder) ? (
          <LakeTooltip
            key={`${key}-result`}
            describedBy="copy"
            placement="center"
            onHide={() => setVisibleState("copy")}
            togglableOnFocus={true}
            content={
              visibleState === "copy" ? t("copyButton.copyTooltip") : t("copyButton.copiedTooltip")
            }
          >
            <TrackPressable action="Copy simulator response">
              <LakeButton
                mode="tertiary"
                size="small"
                icon="copy-regular"
                disabled={isNullishOrEmpty(value)}
                iconPosition="start"
                onPress={() => {
                  setClipboardText(value ?? "");
                  setVisibleState("copied");
                }}
              >
                <LakeText color={colors.gray[900]} variant="medium">
                  {label}:
                </LakeText>

                <Space width={12} />

                <LakeText color={colors.gray[900]} style={styles.value}>
                  {value}
                </LakeText>
              </LakeButton>
            </TrackPressable>
          </LakeTooltip>
        ) : undefined,
      )}

      <Space width={24} />
    </Box>
  );
};
