import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { useCallback, useState } from "react";
import { View } from "react-native";
import { t } from "../utils/i18n";
import { TrackPressable } from "./TrackPressable";

const FILE_NAME = "swan-apis.postman_collection.json";

type PostmanJsonLinkProps = {
  clientId: string;
  clientSecret: string;
  name: string;
};

const PostmanJsonLink = ({ clientId, clientSecret, name }: PostmanJsonLinkProps) => {
  const [loading, setLoading] = useState(false);

  const handlePress = useCallback(() => {
    const url = `https://raw.githubusercontent.com/swan-io/postmans/master/.gitbook/assets/${encodeURI(
      name,
    )}`;

    setLoading(true);

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to load "${url}" ${response.statusText}`);
        }
        return response.text();
      })
      .then(text => {
        const customerFileContent = text
          .replace(/CLIENT_ID_TO_REPLACE/g, clientId)
          .replace(/CLIENT_SECRET_TO_REPLACE/g, clientSecret);

        const blob = new Blob([customerFileContent], {
          type: "application/json",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      })
      .catch((error: unknown) => {
        showToast({ variant: "error", error, title: t("toast.error.network") });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, clientId, clientSecret, name]);

  return (
    <TrackPressable action="Get postman JSON">
      <LakeButton
        size="small"
        mode="secondary"
        icon="link-filled"
        loading={loading}
        onPress={handlePress}
      >
        {name}
      </LakeButton>
    </TrackPressable>
  );
};

type Props = {
  clientId: string;
  clientSecret: string;
};

export const PostmanLinks = ({ clientId, clientSecret }: Props) => {
  return (
    <View>
      <PostmanJsonLink clientId={clientId} clientSecret={clientSecret} name={FILE_NAME} />
    </View>
  );
};
