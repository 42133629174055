import { AsyncData, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { P, match } from "ts-pattern";
import {
  GetServerConsentProjectSettingsDocument,
  RevokeServerConsentProjectCredentialDocument,
} from "../graphql/admin";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { ErrorView } from "./ErrorView";
import { ServerConsentForm } from "./ServerConsentForm";
import { TrackPressable } from "./TrackPressable";

export const ServerConsentCurrentTab = () => {
  const { projectId, projectEnv } = useProjectInfo();

  const [consentRevokeVisible, { open: openRevokeConfirmModal, close: closeRevokeConfirmModal }] =
    useDisclosure(false);

  const [data] = useQuery(GetServerConsentProjectSettingsDocument, {
    projectId,
    env: capitalize(projectEnv),
  });

  const [revokeServerConsentProjectCredential, revocation] = useMutation(
    RevokeServerConsentProjectCredentialDocument,
  );

  const onPressRevoke = () => {
    revokeServerConsentProjectCredential({
      input: {
        env: capitalize(projectEnv),
        projectId,
      },
    })
      .mapOk(data => data.revokeServerConsentProjectCredential)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        showToast({
          variant: "success",
          title: t("toast.success.serverConsent.publicKeyRevoked"),
        });
        Router.push("DevelopersServerConsentDraft", { projectId, projectEnv });
      })
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TileGridPlaceholder withTabs={false} />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => {
      const settings = data?.serverConsentProjectSettings;
      const hasCurrentConsent = isNotNullish(settings?.serverConsentProjectCredential?.publicKey);
      const initialAllowedIpAddresses = settings?.allowedIpAddresses;
      const initialPublicKey = settings?.serverConsentProjectCredential?.publicKey;

      if (!hasCurrentConsent) {
        return (
          <Box alignItems="center" justifyContent="center" style={commonStyles.fill}>
            <Icon color={colors.current.primary} name="cloud-sync-regular" size={42} />
            <Space height={12} />
            <LakeText variant="medium">{t("serverConsent.pending.empty.title")}</LakeText>
            <LakeText variant="medium">{t("serverConsent.pending.empty.subtitle")}</LakeText>
          </Box>
        );
      }

      return (
        <>
          <LakeModal
            visible={consentRevokeVisible}
            title={t("serverConsent.revoke.title")}
            icon="delete-regular"
            color="negative"
            onPressClose={closeRevokeConfirmModal}
          >
            <LakeText>{t("serverConsent.revoke.subtitle")}</LakeText>
            <Space height={24} />

            <TrackPressable action="Revoke server consent">
              <LakeButton color="negative" onPress={onPressRevoke}>
                {t("serverConsent.revoke")}
              </LakeButton>
            </TrackPressable>
          </LakeModal>

          <ServerConsentForm
            initialPublicKey={initialPublicKey}
            initialAllowedIpAddresses={initialAllowedIpAddresses}
            variant="Current"
            readOnly={true}
          >
            <Box direction="row" alignItems="start">
              <TrackPressable action="Revoke server consent">
                <LakeButton
                  color={"negative"}
                  size="small"
                  onPress={openRevokeConfirmModal}
                  loading={revocation.isLoading()}
                >
                  {t("serverConsent.revoke")}
                </LakeButton>
              </TrackPressable>
            </Box>
          </ServerConsentForm>
        </>
      );
    })
    .exhaustive();
};
