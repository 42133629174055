import { Option } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { colors } from "@swan-io/lake/src/constants/design";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useForm } from "@swan-io/use-form";
import {
  CancelCardDocument,
  CancelDigitalCardDocument,
  CancelPhysicalCardDocument,
  CancelPhysicalCardReason,
  SuspendPhysicalCardDocument,
} from "../graphql/partner";
import { t } from "../utils/i18n";
import { validateNullableRequired } from "../utils/validations";
import { TrackPressable } from "./TrackPressable";

export const CancelContractModal = ({
  cardId,
  cardHolderName,
  onClose,
  visible,
  onSuccess,
  canCancelCardContracts,
}: {
  cardId: string;
  cardHolderName: string;
  onClose: (shouldReload: boolean) => void;
  visible: boolean;
  onSuccess?: () => void;
  canCancelCardContracts: boolean;
}) => {
  const [cancelCard, cancellation] = useMutation(CancelCardDocument);

  const cancelContract = () => {
    cancelCard({ cardId })
      .mapOk(data => data.cancelCard)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        showToast({ variant: "success", title: t("toast.success.cardContractCanceled") });
        onClose(true);
        onSuccess?.();
      })
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  return (
    <LakeModal
      visible={visible}
      title={t("cards.general.physicalCard.cancelContract.title")}
      icon="subtract-circle-regular"
      color="negative"
      onPressClose={() => onClose(false)}
    >
      <LakeText color={colors.gray[900]}>
        {t("cards.general.physicalCard.suspend.description", { name: cardHolderName })}
      </LakeText>

      <Space height={32} />

      <TrackPressable action="Cancel physical card contract">
        <LakeTooltip
          placement="left"
          content={t("common.action.denied")}
          disabled={canCancelCardContracts}
        >
          <LakeButton
            icon="subtract-circle-filled"
            loading={cancellation.isLoading()}
            onPress={() => cancelContract()}
            color="negative"
            disabled={!canCancelCardContracts}
          >
            {t("cards.general.physicalCard.cancelContract")}
          </LakeButton>
        </LakeTooltip>
      </TrackPressable>
    </LakeModal>
  );
};

export const SuspendModal = ({
  cardId,
  cardHolderName,
  onClose,
  visible,
}: {
  cardId: string;
  cardHolderName: string;
  onClose: (shouldReload: boolean) => void;
  visible: boolean;
}) => {
  const [suspendPhysicalCard, suspension] = useMutation(SuspendPhysicalCardDocument);

  const suspendCard = () => {
    suspendPhysicalCard({ cardId })
      .mapOk(data => data.suspendPhysicalCard)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        onClose(true);
        showToast({ variant: "success", title: t("toast.success.cardSuspended") });
      })
      .tapError((error: unknown) => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  return (
    <LakeModal
      visible={visible}
      title={t("cards.general.physicalCard.suspend.title")}
      icon="lock-closed-regular"
      color="warning"
      onPressClose={() => onClose(false)}
    >
      <LakeText color={colors.gray[900]}>
        {t("cards.general.physicalCard.suspend.description", { name: cardHolderName })}
      </LakeText>

      <Space height={32} />

      <TrackPressable action="Suspend physical card">
        <LakeButton
          icon="lock-closed-filled"
          loading={suspension.isLoading()}
          onPress={() => suspendCard()}
          color="warning"
        >
          {t("cards.general.physicalCard.suspend")}
        </LakeButton>
      </TrackPressable>
    </LakeModal>
  );
};

const cancelReasons: { name: string; value: CancelPhysicalCardReason }[] = [
  { name: t("cards.general.physicalCard.cancel.reason.malfunction"), value: "Defected" },
  { name: t("cards.general.physicalCard.cancel.reason.lost"), value: "Lost" },
  { name: t("cards.general.physicalCard.cancel.reason.stolen"), value: "Stolen" },
  { name: t("cards.general.physicalCard.cancel.reason.neverReceived"), value: "NonReceived" },
];

export const CancelPhysicalModal = ({
  cardId,
  cardHolderName,
  onClose,
  visible,
}: {
  cardId: string;
  cardHolderName: string;
  onClose: (shouldReload: boolean) => void;
  visible: boolean;
}) => {
  const [cancelPhysicalCard, cancelation] = useMutation(CancelPhysicalCardDocument);

  const { Field, submitForm } = useForm<{ reason: CancelPhysicalCardReason | undefined }>({
    reason: {
      initialValue: undefined,
      validate: validateNullableRequired,
    },
  });

  const confirm = () => {
    submitForm({
      onSuccess: values => {
        const reason = values.reason.flatMap(value => Option.fromNullable(value));

        if (reason.isSome()) {
          cancelPhysicalCard({ input: { cardId, reason: reason.get() } })
            .mapOk(data => data.cancelPhysicalCard)
            .mapOkToResult(filterRejectionsToResult)
            .tapOk(() => {
              onClose(true);
              showToast({ variant: "success", title: t("toast.success.physicalCardCanceled") });
            })
            .tapError((error: unknown) => {
              showToast({ variant: "error", error, title: translateError(error) });
            });
        }
      },
    });
  };

  return (
    <LakeModal
      visible={visible}
      title={t("cards.general.physicalCard.cancel.title")}
      icon="subtract-circle-regular"
      color="negative"
      onPressClose={() => onClose(false)}
    >
      <LakeText color={colors.gray[900]}>
        {t("cards.general.physicalCard.suspend.description", { name: cardHolderName })}
      </LakeText>

      <Space height={24} />

      <Field name="reason">
        {({ value, error, onChange }) => {
          return (
            <LakeLabel
              label={t("cards.general.physicalCard.cancel.reason")}
              render={id => (
                <LakeSelect
                  error={error}
                  id={id}
                  placeholder={t("cards.general.physicalCard.cancel.reason.placeholder")}
                  items={cancelReasons}
                  value={value}
                  onValueChange={onChange}
                />
              )}
            />
          );
        }}
      </Field>

      <Space height={40} />

      <TrackPressable action="Cancel physical card">
        <LakeButton
          icon="subtract-circle-filled"
          loading={cancelation.isLoading()}
          onPress={() => confirm()}
          color="negative"
        >
          {t("cards.general.physicalCard.cancel.submit")}
        </LakeButton>
      </TrackPressable>
    </LakeModal>
  );
};

export const CancelCardDigital = ({
  cardId,
  cardHolderName,
  onClose,
  visible,
}: {
  cardId: string;
  cardHolderName: string;
  onClose: (shouldReload: boolean) => void;
  visible: boolean;
}) => {
  const [cancelDigitalCard, cancelation] = useMutation(CancelDigitalCardDocument);

  const confirm = () => {
    cancelDigitalCard({ digitalCardId: cardId })
      .mapOk(data => data.cancelDigitalCard)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        onClose(true);
        showToast({ variant: "success", title: t("toast.success.digitalCardCanceled") });
      })
      .tapError((error: unknown) => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  return (
    <LakeModal
      visible={visible}
      title={t("cards.digitalCards.cancel.title")}
      icon="subtract-circle-regular"
      color="negative"
      onPressClose={() => onClose(false)}
    >
      <LakeText color={colors.gray[900]}>
        {t("cards.general.physicalCard.suspend.description", { name: cardHolderName })}
      </LakeText>

      <Space height={32} />

      <TrackPressable action="Cancel physical card">
        <LakeButton
          icon="subtract-circle-filled"
          loading={cancelation.isLoading()}
          onPress={() => confirm()}
          color="negative"
        >
          {t("cards.general.physicalCard.cancel.submit")}
        </LakeButton>
      </TrackPressable>
    </LakeModal>
  );
};
