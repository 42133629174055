import { Future, Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { noop } from "@swan-io/lake/src/utils/function";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { pick } from "@swan-io/lake/src/utils/object";
import { FileInput } from "@swan-io/shared-business/src/components/FileInput";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { combineValidators, toOptionalValidator, useForm } from "@swan-io/use-form";
import { useCallback } from "react";
import { P, match } from "ts-pattern";
import {
  AddMerchantProfileDocument,
  MerchantProfileDetailFragment,
  ProductType,
  UpdateMerchantProfileDocument,
} from "../graphql/partner";
import { t } from "../utils/i18n";
import {
  validateNullableRequired,
  validateNumeric,
  validateRequired,
  validateUrl,
} from "../utils/validations";
import { TrackPressable } from "./TrackPressable";

type Props = {
  visible: boolean;
  accountId: string;
  onClose: () => void;
  reload: () => void;
  defaultValues?: MerchantProfileDetailFragment;
};

const productTypeList: { value: ProductType; name: string }[] = [
  {
    value: "GiftsAndDonations" as const,
    name: t("merchantProfiles.productType.giftsAndDonations"),
  },
  {
    value: "Goods" as const,
    name: t("merchantProfiles.productType.goods"),
  },
  {
    value: "Services" as const,
    name: t("merchantProfiles.productType.services"),
  },
  {
    value: "VirtualGoods" as const,
    name: t("merchantProfiles.productType.virtualGoods"),
  },
];

export const MerchantProfileFormLegacy = ({
  visible,
  accountId,
  onClose,
  reload,
  defaultValues,
}: Props) => {
  // If this is the first time the user has edited their merchant profile, the object requestedMerchantProfileUpdates doesn't exists.
  // If it's not, the initial values to be displayed are those with the PendingReview status
  const requestedUpdate = defaultValues?.requestedMerchantProfileUpdates?.find(
    ({ status }) => status === "PendingReview",
  );

  const {
    merchantName,
    productType,
    expectedMonthlyPaymentVolume,
    expectedAverageBasket,
    merchantWebsite,
    merchantLogoUrl,
  } = requestedUpdate ?? defaultValues ?? {};

  const { Field, submitForm, resetForm } = useForm<{
    merchantName: string;
    productType: ProductType;
    expectedMonthlyPaymentVolume: string;
    expectedAverageBasket: string;
    merchantWebsite: string;
    merchantLogoUrl: File | undefined;
  }>({
    merchantName: {
      initialValue: merchantName ?? "",
      validate: validateNullableRequired,
    },
    productType: {
      initialValue: productType ?? "GiftsAndDonations",
      validate: validateNullableRequired,
    },
    expectedMonthlyPaymentVolume: {
      initialValue: expectedMonthlyPaymentVolume?.value ?? "",
      validate: combineValidators(validateRequired, validateNumeric({ min: 0 })),
    },
    expectedAverageBasket: {
      initialValue: expectedAverageBasket?.value ?? "",
      validate: combineValidators(validateRequired, validateNumeric({ min: 0 })),
    },
    merchantWebsite: {
      initialValue: merchantWebsite ?? "",
      sanitize: value => value.trim(),
      validate: toOptionalValidator(validateUrl),
    },
    merchantLogoUrl: {
      initialValue: undefined,
    },
  });

  const [addMerchantProfile, addMerchantProfileData] = useMutation(AddMerchantProfileDocument);

  const [updateMerchantProfile, udpateMerchantProfileData] = useMutation(
    UpdateMerchantProfileDocument,
  );

  const onSubmit = useCallback(() => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(
          pick(values, [
            "merchantName",
            "productType",
            "expectedMonthlyPaymentVolume",
            "expectedAverageBasket",
            "merchantWebsite",
          ]),
        );

        if (option.isSome()) {
          const {
            expectedAverageBasket,
            expectedMonthlyPaymentVolume,
            merchantName,
            productType,
            merchantWebsite,
          } = option.get();

          const merchantLogoUrl = values.merchantLogoUrl.flatMap(value =>
            Option.fromNullable(value),
          );

          const base64Logo = merchantLogoUrl.isNone()
            ? Future.value(Option.None<string>())
            : Future.make<Option<string>>(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(merchantLogoUrl.get());

                reader.onload = () => {
                  const base64 = match(reader.result)
                    .with(P.string, value => value.split(";base64,")[1])
                    .otherwise(noop);

                  resolve(
                    match(base64)
                      .with(P.string, value => Option.Some(value))
                      .otherwise(() => Option.None()),
                  );
                };
                reader.onerror = () => {
                  resolve(Option.None());
                };
              });

          return base64Logo.flatMap(logo => {
            const inputValues = {
              expectedAverageBasket: { value: expectedAverageBasket, currency: "EUR" },
              expectedMonthlyPaymentVolume: {
                value: expectedMonthlyPaymentVolume,
                currency: "EUR",
              },
              merchantName,
              merchantWebsite: merchantWebsite ?? "",
              productType,
              merchantLogo: logo.toUndefined(),
            };

            if (isNotNullish(defaultValues)) {
              return updateMerchantProfile({
                input: {
                  ...inputValues,
                  merchantProfileId: defaultValues.id,
                },
              })
                .mapOk(data => data.requestMerchantProfileUpdate)
                .mapOkToResult(data =>
                  isNotNullish(data) ? Result.Ok(data) : Result.Error(undefined),
                )
                .mapOkToResult(filterRejectionsToResult)
                .tapOk(() => {
                  showToast({
                    variant: "success",
                    title: t("toast.success.merchantProfileUpdated"),
                  });
                  reload();
                  onClose();
                })
                .tapError(error => {
                  showToast({ variant: "error", error, title: translateError(error) });
                })
                .map(() => undefined);
            } else {
              return addMerchantProfile({
                input: {
                  ...inputValues,
                  accountId,
                },
              })
                .mapOk(data => data.addMerchantProfile)
                .mapOkToResult(data =>
                  isNotNullish(data) ? Result.Ok(data) : Result.Error(undefined),
                )
                .mapOkToResult(filterRejectionsToResult)
                .tapOk(() => {
                  showToast({
                    variant: "success",
                    title: t("toast.success.merchantProfileAdded"),
                  });
                  reload();
                  onClose();
                  resetForm();
                })
                .tapError(error => {
                  showToast({ variant: "error", error, title: translateError(error) });
                })
                .map(() => undefined);
            }
          });
        }
      },
    });
  }, [
    accountId,
    addMerchantProfile,
    onClose,
    defaultValues,
    reload,
    resetForm,
    submitForm,
    updateMerchantProfile,
  ]);

  return (
    <LakeModal
      maxWidth={850}
      icon={defaultValues ? "edit-regular" : "add-circle-regular"}
      title={
        defaultValues
          ? t("merchantProfiles.editMerchantProfile")
          : t("merchantProfiles.addNewMerchantProfile")
      }
      visible={visible}
    >
      <Grid numColumns={2} horizontalSpace={40}>
        <Field name="merchantName">
          {({ value, onChange, error }) => (
            <LakeLabel
              label={t("merchantProfiles.merchantName")}
              render={() => <LakeTextInput error={error} value={value} onChangeText={onChange} />}
            />
          )}
        </Field>

        <Field name="productType">
          {({ value, onChange, error }) => (
            <LakeLabel
              label={t("merchantProfiles.productType")}
              render={() => (
                <LakeSelect
                  value={value}
                  items={productTypeList}
                  onValueChange={onChange}
                  error={error}
                />
              )}
            />
          )}
        </Field>

        <Field name="expectedMonthlyPaymentVolume">
          {({ value, valid, onChange, error, onBlur }) => (
            <LakeLabel
              label={t("merchantProfiles.expectedMonthlyPaymentVolume")}
              render={() => (
                <LakeTextInput
                  unit="€"
                  inputMode="decimal"
                  onChangeText={onChange}
                  error={error}
                  valid={valid}
                  value={value}
                  onBlur={onBlur}
                />
              )}
            />
          )}
        </Field>

        <Field name="expectedAverageBasket">
          {({ value, valid, onChange, error, onBlur }) => (
            <LakeLabel
              label={t("merchantProfiles.expectedAverageBasket")}
              render={() => (
                <LakeTextInput
                  unit="€"
                  inputMode="decimal"
                  onChangeText={onChange}
                  error={error}
                  valid={valid}
                  value={value}
                  onBlur={onBlur}
                />
              )}
            />
          )}
        </Field>
      </Grid>

      <Field name="merchantWebsite">
        {({ value, onChange, error }) => (
          <LakeLabel
            label={t("merchantProfiles.website")}
            render={() => <LakeTextInput error={error} value={value} onChangeText={onChange} />}
          />
        )}
      </Field>

      <Field name="merchantLogoUrl">
        {({ value, error, onChange }) => (
          <LakeLabel
            label={t("merchantProfiles.logoInputLabel")}
            render={() => (
              <FileInput
                value={value ?? (merchantLogoUrl != null ? { url: merchantLogoUrl } : undefined)}
                error={error}
                icon="image-regular"
                accept={["image/png"]}
                maxSize={1000 * 1000} // 1MB
                description={t("merchantProfiles.logo.acceptedImageUpload")}
                onFiles={files => {
                  const file = files[0];

                  if (file != null) {
                    void onChange(file);
                  }
                }}
              />
            )}
          />
        )}
      </Field>

      <LakeButtonGroup paddingBottom={0}>
        <TrackPressable action="Cancel merchant profile form">
          <LakeButton
            mode="secondary"
            color="gray"
            onPress={() => {
              onClose();
              resetForm();
            }}
            grow={true}
          >
            {t("common.cancel")}
          </LakeButton>
        </TrackPressable>

        <TrackPressable action="Save merchant profile form">
          <LakeButton
            grow={true}
            color="current"
            onPress={() => {
              onSubmit();
            }}
            loading={
              isNotNullish(defaultValues)
                ? udpateMerchantProfileData.isLoading()
                : addMerchantProfileData.isLoading()
            }
          >
            {defaultValues ? t("merchantProfiles.button.edit") : t("merchantProfiles.button.add")}
          </LakeButton>
        </TrackPressable>
      </LakeButtonGroup>
    </LakeModal>
  );
};
