import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { StyleSheet, ViewProps } from "react-native";

const styles = StyleSheet.create({
  emptyTile: {
    paddingHorizontal: 0,
    paddingTop: spacings[32],
    flexGrow: 1,
  },
  emptyTileContents: {
    flexGrow: 1,
    justifyContent: "center",
  },
  listTitleContainer: {
    paddingHorizontal: spacings[32],
  },
});

type EmptyTileProps = {
  title: string;
  link?: string;
  icon: IconName;
  subTitle: string;
  description: string;
  style?: ViewProps["style"];
};

export const EmptyTile = ({ title, link, icon, subTitle, description, style }: EmptyTileProps) => {
  return (
    <Tile style={[styles.emptyTile, style]}>
      <Box direction="row" alignItems="center" style={styles.listTitleContainer}>
        <LakeHeading level={2} variant="h3">
          {title}
        </LakeHeading>

        {isNotNullishOrEmpty(link) && (
          <>
            <Fill minWidth={24} />

            <Link to="" disabled={true}>
              <LakeText>{link}</LakeText>
            </Link>
          </>
        )}
      </Box>

      <Space height={32} />

      <Box alignItems="center" style={styles.emptyTileContents}>
        <Icon name={icon} size={50} color={colors.current[500]} />
        <Space height={20} />

        <LakeText variant="smallMedium" color={colors.gray[900]}>
          {subTitle}
        </LakeText>

        <LakeText variant="smallRegular" color={colors.gray[700]}>
          {description}
        </LakeText>
      </Box>
    </Tile>
  );
};
