import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Popover } from "@swan-io/lake/src/components/Popover";
import { Space } from "@swan-io/lake/src/components/Space";
import { colors } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { useForm } from "@swan-io/use-form";
import { ReactNode, useRef } from "react";
import { View } from "react-native";
import { PopoverWrapper } from "../components/PopoverWrapper";
import { RemoveWebhookSubscriptionDocument } from "../graphql/partner";
import { t } from "../utils/i18n";
import { TrackPressable } from "./TrackPressable";

type WebhookDeleteActionProps = {
  webhookId: string;
  webhookName: string;
  children: (props: { onPress: () => void }) => ReactNode;
  onSuccess: () => void;
};

export const WebhookDeleteAction = ({
  webhookId,
  webhookName,
  children,
  onSuccess,
}: WebhookDeleteActionProps) => {
  const ref = useRef<View>(null);
  const [visible, { close, open }] = useDisclosure(false);

  return (
    <>
      <View ref={ref}>{children({ onPress: open })}</View>

      <Popover
        referenceRef={ref}
        describedBy={t("webhook.delete.title", { name: webhookName })}
        onDismiss={close}
        visible={visible}
      >
        <PopoverWrapper title={t("webhook.delete.title", { name: webhookName })}>
          <WebhookDeleteForm
            webhookId={webhookId}
            webhookName={webhookName}
            onSuccess={onSuccess}
          />
        </PopoverWrapper>
      </Popover>
    </>
  );
};

type WebhookDeleteFormProps = {
  webhookId: string;
  webhookName: string;
  onSuccess: () => void;
};

const WebhookDeleteForm = ({ webhookId, webhookName, onSuccess }: WebhookDeleteFormProps) => {
  const { Field, submitForm } = useForm({
    name: {
      initialValue: "",
      validate: value => {
        if (value !== webhookName) {
          return t("webhook.delete.inputError");
        }
      },
      sanitize: value => value.trim(),
    },
  });

  const [removeWebhookSubscription, webhookSubscriptionRemoval] = useMutation(
    RemoveWebhookSubscriptionDocument,
  );

  const submit = () =>
    submitForm({
      onSuccess: () => {
        removeWebhookSubscription({
          input: {
            webhookSubscriptionId: webhookId,
          },
        })
          .mapOk(data => data.removeWebhookSubscription)
          .mapOkToResult(filterRejectionsToResult)
          .tapOk(() => {
            showToast({ variant: "success", title: t("toast.success.webhookDeleted") });
            onSuccess();
          })
          .tapError((error: unknown) => {
            showToast({ variant: "error", error, title: t("toast.error.webhookDeleted") });
          });
      },
    });

  return (
    <View>
      <LakeText color={colors.gray[900]}>{t("webhook.delete.actionIsIrreversible")}</LakeText>
      <Space height={8} />
      <LakeText color={colors.gray[900]}>{t("webhook.delete.intructions")}</LakeText>
      <Space height={12} />

      <Field name="name">
        {({ value, valid, error, onChange, onBlur }) => (
          <LakeTextInput
            value={value}
            valid={valid}
            error={error}
            onChangeText={onChange}
            onBlur={onBlur}
          />
        )}
      </Field>

      <Space height={4} />

      <Box alignItems="end">
        <TrackPressable action="Confirm webhook deletion">
          <LakeButton
            size="small"
            color="negative"
            loading={webhookSubscriptionRemoval.isLoading()}
            onPress={submit}
          >
            {t("webhook.delete.confirm")}
          </LakeButton>
        </TrackPressable>
      </Box>
    </View>
  );
};
