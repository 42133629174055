import { AsyncData, Future, Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import envNxUrl from "@swan-io/lake/src/assets/3d-card/environment/nx.png?url";
import envNyUrl from "@swan-io/lake/src/assets/3d-card/environment/ny.png?url";
import envNzUrl from "@swan-io/lake/src/assets/3d-card/environment/nz.png?url";
import envPxUrl from "@swan-io/lake/src/assets/3d-card/environment/px.png?url";
import envPyUrl from "@swan-io/lake/src/assets/3d-card/environment/py.png?url";
import envPzUrl from "@swan-io/lake/src/assets/3d-card/environment/pz.png?url";
import fontMaisonNeueBookUrl from "@swan-io/lake/src/assets/3d-card/model/MaisonNeue-Book.woff?url";
import fontMarkProRegularUrl from "@swan-io/lake/src/assets/3d-card/model/MarkPro-Regular.ttf?url";
import bandRoughnessUrl from "@swan-io/lake/src/assets/3d-card/model/band_roughness.jpg?url";
import cardGltfUrl from "@swan-io/lake/src/assets/3d-card/model/card.gltf?url";
import chipUrl from "@swan-io/lake/src/assets/3d-card/model/chip.jpg?url";
import colorBlackUrl from "@swan-io/lake/src/assets/3d-card/model/color_black.jpg?url";
import colorSilverUrl from "@swan-io/lake/src/assets/3d-card/model/color_silver.jpg?url";
import { Box } from "@swan-io/lake/src/components/Box";
import type { Card3dAssetsUrls } from "@swan-io/lake/src/components/Card3dPreview";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeRadio } from "@swan-io/lake/src/components/LakeRadio";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { LakeSlider } from "@swan-io/lake/src/components/LakeSlider";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { Space } from "@swan-io/lake/src/components/Space";
import { Switch } from "@swan-io/lake/src/components/Switch";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { colors, invariantColors, texts } from "@swan-io/lake/src/constants/design";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { noop } from "@swan-io/lake/src/utils/function";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { deburr } from "@swan-io/lake/src/utils/string";
import { Request, emptyToError } from "@swan-io/request";
import { FileInput } from "@swan-io/shared-business/src/components/FileInput";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useForm } from "@swan-io/use-form";
import { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import swanLogoUrl from "../assets/img/logo-swan.svg";
import {
  GetCardProductsQuery,
  UpdateCardProductCardDesignDocument,
} from "../graphql/exposed-internal";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { changeSvgColor, changeSvgSize, getSvgSize } from "../utils/svg";
import { CardBackground, CardColor, CreditCard } from "./CreditCard";
import { TrackPressable } from "./TrackPressable";

const Card3dPreview = lazy(() => import("@swan-io/lake/src/components/Card3dPreview"));

const assetsUrls: Card3dAssetsUrls = {
  envNx: envNxUrl,
  envNy: envNyUrl,
  envNz: envNzUrl,
  envPx: envPxUrl,
  envPy: envPyUrl,
  envPz: envPzUrl,
  fontMaisonNeueBook: fontMaisonNeueBookUrl,
  fontMarkProRegular: fontMarkProRegularUrl,
  bandRoughness: bandRoughnessUrl,
  cardGltf: cardGltfUrl,
  chipTexture: chipUrl,
  colorBlack: colorBlackUrl,
  colorSilver: colorSilverUrl,
};

// Compute values here to make it easy to change card size
const CARD_REAL_WIDTH = 85.6;
const LOGO_REAL_MAX_WIDTH = 50;
const LOGO_REAL_MAX_HEIGHT = 10;
const LOGO_REAL_MARGIN = 3;
const CARD_WIDTH = 384;
const CARD_HEIGHT = 248;
const LOGO_MAX_WIDTH = Math.round((CARD_WIDTH * LOGO_REAL_MAX_WIDTH) / CARD_REAL_WIDTH);
const LOGO_MAX_HEIGHT = Math.round((CARD_WIDTH * LOGO_REAL_MAX_HEIGHT) / CARD_REAL_WIDTH);
const LOGO_MARGIN = Math.round((CARD_WIDTH * LOGO_REAL_MARGIN) / CARD_REAL_WIDTH);
const DEFAULT_CARD_LOGO_ZOOM = 60;
const SVG_MAX_PX_SIZE = 500;

const cardLogoColors: Record<CardColor, string> = {
  Black: invariantColors.white,
  Silver: invariantColors.black,
  Custom: invariantColors.black,
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "stretch",
  },
  controlsContainer: {
    flexGrow: 1,
  },
  previewContainer: {
    width: "40%",
    minWidth: 470,
    height: 550,
  },
  switch3dContainer: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  previewContent: {
    height: "100%",
    maxHeight: 550, // make the card center aligned with the form
  },
  radioGroup: {
    flexDirection: "row",
    alignItems: "center",
  },
  radio: {
    flexDirection: "row",
    alignItems: "center",
  },
  creditCardContainer: {
    width: CARD_WIDTH,
    height: CARD_HEIGHT,
    borderRadius: 15,
    overflow: "hidden",
  },
  creditCardLogoMask: {
    position: "absolute",
    top: LOGO_MARGIN,
    right: LOGO_MARGIN,
    width: LOGO_MAX_WIDTH,
    height: LOGO_MAX_HEIGHT,
    overflow: "hidden",
    alignItems: "flex-end",
  },
  creditCardLogo: {
    transformOrigin: "100% 0",
  },
  mailLink: {
    ...texts.medium,
    textDecorationLine: "underline",
    color: colors.gray[900],
  },
});

type Props = {
  firstName?: string;
  lastName?: string;
  readOnly?: boolean;
  cardDesign: NonNullable<
    NonNullable<GetCardProductsQuery["projectInfo"]["cardProducts"]>[number]
  >["cardDesigns"][number];
  onSave?: () => void;
  cardProductId: string;
  canEditCardProducts: boolean;
};

const colorOptions: { label: string; value: CardColor }[] = [
  { label: t("projectSettings.cards.black"), value: "Black" },
  { label: t("projectSettings.cards.silver"), value: "Silver" },
  { label: t("projectSettings.cards.custom"), value: "Custom" },
];

const computeCardLogoSize = (logoSize: {
  width: number;
  height: number;
}): { width: number; height: number } => {
  const logoRatio = logoSize.width / logoSize.height;
  const cardSpaceRatio = LOGO_MAX_WIDTH / LOGO_MAX_HEIGHT;

  // if logo is wider than available space
  // logo will have the same width than available space
  if (logoRatio >= cardSpaceRatio) {
    const width = LOGO_MAX_WIDTH;
    const height = Math.round(width / logoRatio);
    return { width, height };
  } else {
    // if logo is higher than available space
    // logo will have the same height than available space
    const height = LOGO_MAX_HEIGHT;
    const width = Math.round(height * logoRatio);
    return { width, height };
  }
};

const computeRealSize = (
  logoSize: {
    width: number;
    height: number;
  },
  zoom: number,
): { width: number; height: number } => {
  const pxToRealityFactor = ((CARD_REAL_WIDTH / CARD_WIDTH) * zoom) / 100;

  return {
    width: Math.round(logoSize.width * pxToRealityFactor),
    height: Math.round(logoSize.height * pxToRealityFactor),
  };
};

const SVG_MAX_SIZE = 100000; // 100ko

const cleanUpLogo = (file: File): Future<Result<File, unknown>> =>
  Future.fromPromise(import("dompurify"))
    .mapOk(module => module.default)
    .flatMapOk(({ sanitize }) =>
      Future.make(resolve => {
        const reader = new FileReader();

        reader.onload = () => {
          const container = document.createElement("div");
          const cleanLogo = deburr(sanitize((reader.result as string).toString()));

          container.innerHTML = cleanLogo;

          const logo = container.firstElementChild as unknown as SVGElement;
          const { width, height } = getSvgSize(logo);

          logo.setAttribute("width", width.toString());
          logo.setAttribute("height", height.toString());

          const resizedSvg = changeSvgSize(logo, SVG_MAX_PX_SIZE);
          const blackSvg = changeSvgColor(resizedSvg, "#000000");

          resolve(
            Result.Ok(
              new File([new Blob([blackSvg.outerHTML])], "logo.svg", { type: "image/svg+xml" }),
            ),
          );
        };

        reader.readAsText(file);
      }),
    );

export const CardDesignEditor = ({
  firstName,
  lastName,
  cardProductId,
  readOnly = false,
  canEditCardProducts,
  cardDesign,
  onSave,
}: Props) => {
  const { projectEnv } = useProjectInfo();

  const [logoElement, setLogoElement] = useState<SVGElement | null>(null);
  const [show3dPreview, setShow3dPreview] = useState(false);

  // compute logo card size
  const logoSize = useMemo(
    () =>
      isNotNullish(logoElement)
        ? computeCardLogoSize(getSvgSize(logoElement))
        : { width: 0, height: 0 },
    [logoElement],
  );

  const { Field, FieldsListener, listenFields, submitForm, getFieldValue } = useForm<{
    color: CardColor;
    logo: File | undefined;
    zoom: number;
  }>({
    color: { initialValue: cardDesign.cardBackground.type as CardColor },
    logo: { initialValue: undefined },
    zoom: { initialValue: cardDesign.zoomRatioProjectLogo ?? DEFAULT_CARD_LOGO_ZOOM },
  });

  useEffect(() => {
    // disable 3d preview if custom color is selected
    const unsubscribe = listenFields(["color"], ({ color }) => {
      if (color.value === "Custom") {
        setShow3dPreview(false);
      }
    });

    return unsubscribe;
  }, [listenFields]);

  const updateLogoElement = useCallback(
    (file: File) => {
      const container = document.createElement("div");
      const reader = new FileReader();

      reader.onload = () => {
        container.innerHTML = reader.result as string;

        const svg = changeSvgColor(
          container.firstElementChild as unknown as SVGElement,
          cardLogoColors[getFieldValue("color")],
        );

        setLogoElement(svg);
      };

      reader.readAsText(file);
    },
    [getFieldValue],
  );

  const [originalLogo, setOriginalLogo] = useState<AsyncData<File>>(AsyncData.NotAsked());
  // fetch original logo as file
  useEffect(() => {
    setOriginalLogo(AsyncData.Loading());
    const request = Request.make({
      url: cardDesign.cardProjectLogoSvgUrl ?? swanLogoUrl,
      withCredentials: false,
      responseType: "blob",
    })
      .mapOkToResult(emptyToError)
      .mapOk(blob => new File([blob], "logo.svg", { type: "image/svg+xml" }))
      .tapOk(value => setOriginalLogo(AsyncData.Done(value)))
      .tapOk(updateLogoElement);

    return () => {
      setOriginalLogo(AsyncData.NotAsked());
      request.cancel();
    };
  }, [cardDesign, updateLogoElement]);

  useEffect(() => {
    const unsubscribe = listenFields(["logo", "color"], ({ logo }) => {
      match({ distLogo: originalLogo, localLogo: logo.value })
        .with({ localLogo: P.instanceOf(File) }, ({ localLogo }) => updateLogoElement(localLogo))
        .with({ distLogo: AsyncData.Done(P.select()) }, distLogo => updateLogoElement(distLogo))
        .otherwise(() => {});
    });

    return unsubscribe;
  }, [listenFields, originalLogo, updateLogoElement]);

  const [updateCardProductDesign, cardProductDesignUpdate] = useMutation(
    UpdateCardProductCardDesignDocument,
  );

  const onSubmit = () => {
    submitForm({
      onSuccess: ({ color, logo, zoom }) => {
        if (color.isNone() || zoom.isNone()) {
          return;
        }

        const file = logo.flatMap(value => Option.fromNullable(value));

        const base64Logo = file.isNone()
          ? Future.value(Option.None<string>())
          : Future.make<Option<string>>(resolve => {
              const reader = new FileReader();
              reader.readAsDataURL(file.get());

              reader.onload = () => {
                const base64 = match(reader.result)
                  .with(P.string, value => value.split(";base64,")[1])
                  .otherwise(noop);

                resolve(
                  match(base64)
                    .with(P.string, value => Option.Some(value))
                    .otherwise(() => Option.None()),
                );
              };
              reader.onerror = () => {
                resolve(Option.None());
              };
            });

        return base64Logo
          .flatMap(logo =>
            updateCardProductDesign({
              input: {
                backgroundType: color.get(),
                cardProductId,
                cardDesignId: cardDesign.id,
                logo: logo.toUndefined(),
                zoomRatioLogo: zoom.get(),
              },
            }),
          )
          .tapOk(result => {
            match(result.updateCardProductCardDesign)
              .with({ __typename: "ForbiddenRejection" }, () => {
                showToast({ variant: "error", title: t("error.forbidden") });
              })
              .with({ __typename: "CardProductNotFoundRejection" }, () => {
                showToast({ variant: "error", title: t("error.forbidden") });
              })
              .with({ __typename: "ValidationRejection" }, () => {
                // TODO: better error
                showToast({ variant: "error", title: t("error.generic") });
              })
              .with(P.nullish, () => showToast({ variant: "error", title: t("error.forbidden") }))
              .with({ __typename: "UpdateCardProductCardDesignSuccessPayload" }, () => {
                showToast({ variant: "success", title: t("toast.success.cardSettingsSaved") });
                onSave?.();
              })
              .exhaustive();
          });
      },
    });
  };

  return (
    <Box direction="row" style={styles.container}>
      <View style={styles.controlsContainer}>
        <LakeScrollView>
          <TileGrid breakpoint={500}>
            <Tile title={t("cardProducts.design.chooseStyle")}>
              <Field name="color">
                {({ value, onChange }) => (
                  <View style={styles.radioGroup}>
                    {colorOptions.map(({ label, value: color }) => (
                      <TrackPressable key={color} action="Choose card product style">
                        <Pressable
                          role="radio"
                          style={styles.radio}
                          disabled={readOnly}
                          onPress={() => onChange(color)}
                        >
                          <LakeRadio value={color === value} />
                          <Space width={8} />
                          <LakeText color={colors.gray[900]}>{label}</LakeText>
                          <Space width={24} />
                        </Pressable>
                      </TrackPressable>
                    ))}
                  </View>
                )}
              </Field>
            </Tile>

            <FieldsListener names={["color", "zoom"]}>
              {({ color, zoom }) => {
                const realSize = computeRealSize(logoSize, zoom.value);

                return (
                  <Tile
                    title={t("cardProducts.design.logoSize")}
                    disabled={readOnly || color.value === "Custom"}
                    headerEnd={
                      color.value === "Custom" ? null : (
                        <LakeText>{`${realSize.width} x ${realSize.height} mm`}</LakeText>
                      )
                    }
                  >
                    <Field name="zoom">
                      {({ value, onChange }) => (
                        <LakeSlider
                          min={0}
                          max={100}
                          step={1}
                          value={value}
                          onChange={onChange}
                          // TODO: disabled if default logo
                          disabled={readOnly || color.value === "Custom"}
                        />
                      )}
                    </Field>
                  </Tile>
                );
              }}
            </FieldsListener>
          </TileGrid>

          <FieldsListener names={["color"]}>
            {({ color }) =>
              color.value === "Custom" ? (
                <Tile title={t("projectSettings.cards.tellUsDesign")}>
                  <LakeText>{t("projectSettings.cards.customMessage")}</LakeText>

                  <Link style={styles.mailLink} to={`mailto:customcard@swan.io`}>
                    {"customcard@swan.io"}
                  </Link>

                  <Space height={16} />
                  <LakeText>{t("projectSettings.cards.customDelivery")}</LakeText>
                </Tile>
              ) : (
                <Tile title={t("cardProducts.design.customizeBrand")}>
                  <Field name="logo">
                    {({ value, onChange }) => (
                      <LakeLabel
                        label={t("cardProducts.design.customizeBrand.cardLogo")}
                        render={() => (
                          <FileInput
                            disabled={readOnly}
                            value={value}
                            icon="image-regular"
                            accept={["image/svg+xml"]}
                            description={t("cardProducts.design.customizeBrand.svg")}
                            onFiles={files => {
                              const file = files[0];

                              if (file != null) {
                                cleanUpLogo(file)
                                  .tapOk(file => {
                                    if (file.size > SVG_MAX_SIZE) {
                                      showToast({
                                        variant: "error",
                                        title: t(
                                          "cardProducts.design.customizeBrand.cardLogo.tooLarge",
                                          { name: file.name, size: SVG_MAX_SIZE / 1000 },
                                        ),
                                      });
                                    } else {
                                      void onChange(file);
                                    }
                                  })
                                  .tapError(error => {
                                    showToast({
                                      variant: "error",
                                      error,
                                      title: translateError(error),
                                    });
                                  });
                              }
                            }}
                          />
                        )}
                      />
                    )}
                  </Field>
                </Tile>
              )
            }
          </FieldsListener>

          {readOnly ? null : (
            <LakeButtonGroup>
              <TrackPressable action="Save card product design">
                <LakeTooltip
                  placement="left"
                  content={t("common.action.denied")}
                  disabled={canEditCardProducts}
                >
                  <LakeButton
                    onPress={onSubmit}
                    loading={cardProductDesignUpdate.isLoading()}
                    color="current"
                    icon={projectEnv === "sandbox" ? undefined : "clock-filled"}
                    disabled={!canEditCardProducts}
                  >
                    {projectEnv === "sandbox"
                      ? t("common.save")
                      : t("cardProducts.design.sendToReview")}
                  </LakeButton>
                </LakeTooltip>
              </TrackPressable>
            </LakeButtonGroup>
          )}

          <Space height={24} />
        </LakeScrollView>
      </View>

      <Space width={48} />

      <FieldsListener names={["color", "logo", "zoom"]}>
        {({ color, zoom }) => {
          const hasActualCustomDesign =
            cardDesign.cardBackground.type === "Custom" &&
            !cardDesign.cardBackground.cardBackgroundUrl.includes("swan-dark-card.svg");

          const cardBackground = match({ color: color.value, hasActualCustomDesign })
            .returnType<CardBackground>()
            .with({ color: "Black" }, { color: "Silver" }, ({ color }) => ({ color }))
            .with({ color: "Custom", hasActualCustomDesign: true }, () => ({
              color: "Custom",
              imageUrl: cardDesign.cardBackground.cardBackgroundUrl,
              textColor: cardDesign.cardBackground.cardTextColor,
            }))
            .otherwise(() => ({
              color: "Custom",
            }));

          return (
            <View style={styles.previewContainer}>
              {show3dPreview && color.value !== "Custom" ? (
                <Suspense fallback={<LoadingView />}>
                  <Card3dPreview
                    color={color.value}
                    logo={logoElement ?? null}
                    logoScale={zoom.value / 100}
                    ownerName={[firstName, lastName].filter(Boolean).join(" ")}
                    cardNumber="1234 5678 9012 3456"
                    cvv="123"
                    expirationDate="12/24"
                    assetsUrls={assetsUrls}
                  />
                </Suspense>
              ) : (
                <Box justifyContent="center" alignItems="center" style={styles.previewContent}>
                  <View style={styles.creditCardContainer}>
                    <CreditCard
                      firstName={firstName}
                      lastName={lastName}
                      background={cardBackground}
                    />

                    <View style={styles.creditCardLogoMask}>
                      {logoElement != null && color.value !== "Custom" && (
                        <Image
                          source={{
                            uri: `data:image/svg+xml;base64,${window.btoa(logoElement.outerHTML)}`,
                          }}
                          resizeMode="contain"
                          style={[
                            styles.creditCardLogo,
                            {
                              transform: `scale(${zoom.value / 100})`,
                              width: logoSize.width,
                              height: logoSize.height,
                            },
                          ]}
                        />
                      )}
                    </View>
                  </View>
                </Box>
              )}

              <Box
                alignItems="center"
                direction="row"
                justifyContent="end"
                style={styles.switch3dContainer}
              >
                <Switch
                  disabled={color.value === "Custom"}
                  value={show3dPreview}
                  onValueChange={setShow3dPreview}
                />

                <Space width={8} />
                <LakeText>{t("cardProducts.design.show3dPreview")}</LakeText>
              </Box>
            </View>
          );
        }}
      </FieldsListener>
    </Box>
  );
};
