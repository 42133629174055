import { Option } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Form } from "@swan-io/lake/src/components/Form";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useForm } from "@swan-io/use-form";
import { useCallback } from "react";
import { View } from "react-native";
import { AddFundingLimitChangeRequestDocument } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { validateRequired } from "../utils/validations";

type Props = {
  accountHolderId: string;
  onReload: () => void;
  canCreateFundingLimit: boolean;
};

export const NewFundingLimitChangeRequest = ({
  onReload,
  accountHolderId,
  canCreateFundingLimit,
}: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const [addFundingLimit] = useMutation(AddFundingLimitChangeRequestDocument);

  const onCloseModal = useCallback(() => {
    Router.push("HoldersDetailFundingLimitChangeRequests", {
      projectId,
      projectEnv,
      accountHolderId,
    });
  }, [projectId, projectEnv, accountHolderId]);

  const { Field, submitForm, formStatus } = useForm({
    fundingLimit: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.replace(/ /g, "").replace(/,/g, "."),
    },
    instantFundingLimit: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.replace(/ /g, "").replace(/,/g, "."),
    },
  });

  const onSave = () => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isSome()) {
          const { fundingLimit, instantFundingLimit } = option.get();

          return addFundingLimit({
            input: {
              accountHolderId,
              fundingLimit: {
                amount: { value: fundingLimit, currency: "EUR" },
              },
              instantFundingLimit: {
                amount: { value: instantFundingLimit, currency: "EUR" },
              },
            },
          })
            .mapOk(data => data.addFundingLimitSettingsChangeRequest)
            .mapOkToResult(filterRejectionsToResult)
            .tapOk(() => {
              showToast({ variant: "success", title: t("toast.success.newFundingLimitChange") });
              onCloseModal();
              onReload();
            })
            .tapError((error: unknown) => {
              showToast({ variant: "error", error, title: translateError(error) });
            });
        }
      },
    });
  };

  return (
    <View>
      <Form style={commonStyles.fill}>
        <Field name="fundingLimit">
          {({ value, valid, error, onChange, onBlur }) => (
            <LakeLabel
              label={t("fundingRequests.new.fundingLimit")}
              render={id => (
                <LakeTextInput
                  id={id}
                  value={value}
                  placeholder={t("fundingRequests.new.placeholder")}
                  inputMode="decimal"
                  unit="€"
                  valid={valid}
                  error={error}
                  onChangeText={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          )}
        </Field>

        <Field name="instantFundingLimit">
          {({ value, valid, error, onChange, onBlur }) => (
            <LakeLabel
              label={t("fundingRequests.new.instantLimit")}
              render={id => (
                <LakeTextInput
                  id={id}
                  value={value}
                  placeholder={t("fundingRequests.new.placeholder")}
                  inputMode="decimal"
                  unit="€"
                  valid={valid}
                  error={error}
                  onChangeText={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          )}
        </Field>
      </Form>

      <LakeButtonGroup>
        <LakeButton color="gray" mode="secondary" onPress={onCloseModal} grow={true}>
          {t("common.cancel")}
        </LakeButton>

        <LakeTooltip
          placement="left"
          content={t("common.action.denied")}
          disabled={canCreateFundingLimit}
          containerStyle={commonStyles.fill}
        >
          <LakeButton
            color="current"
            onPress={onSave}
            loading={formStatus === "submitting"}
            grow={true}
            disabled={!canCreateFundingLimit}
          >
            {t("common.submit")}
          </LakeButton>
        </LakeTooltip>
      </LakeButtonGroup>
    </View>
  );
};
