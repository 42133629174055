import { Option } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { Switch } from "@swan-io/lake/src/components/Switch";
import { colors } from "@swan-io/lake/src/constants/design";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useForm } from "@swan-io/use-form";
import { Pressable, View } from "react-native";
import { match } from "ts-pattern";
import { CreateCardProductDocument, UpdateCardProductDocument } from "../graphql/exposed-internal";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { validateRequired } from "../utils/validations";
import { TrackPressable } from "./TrackPressable";

type Props = {
  onSave: () => void;
  canEditCardProducts: boolean;
};

export const CardProductsWizard = ({ onSave, canEditCardProducts }: Props) => {
  const { projectId } = useProjectInfo();

  const [createCardProduct, creation] = useMutation(CreateCardProductDocument);
  const [updateCardProduct, update] = useMutation(UpdateCardProductDocument);

  const { Field, submitForm } = useForm({
    name: {
      initialValue: "",
      sanitize: value => value?.trim(),
      validate: validateRequired,
    },
    applicableToPhysicalCards: {
      initialValue: false,
    },
  });

  const loading = creation.isLoading() || update.isLoading();

  const onSubmit = () => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isSome()) {
          const { name, applicableToPhysicalCards } = option.get();

          createCardProduct({ input: { name, projectId } })
            .tapOk(result => {
              return match(result.createCardProduct)
                .with({ __typename: "ForbiddenRejection" }, () =>
                  showToast({ variant: "error", title: t("error.forbidden") }),
                )
                .with({ __typename: "ProjectNotFoundRejection" }, __typename =>
                  showToast({ variant: "error", title: translateError(__typename) }),
                )
                .with({ __typename: "ValidationRejection" }, () => {
                  // TODO: better error
                  showToast({ variant: "error", title: t("error.generic") });
                })
                .with({ __typename: "CreateCardProductSuccessPayload" }, ({ cardProduct }) => {
                  return updateCardProduct({
                    input: {
                      id: cardProduct.id,
                      applicableToPhysicalCards,
                    },
                  }).tapOk(result => {
                    match(result.updateCardProduct)
                      .with({ __typename: "ForbiddenRejection" }, () =>
                        showToast({ variant: "error", title: t("error.forbidden") }),
                      )
                      .with(
                        { __typename: "BadRequestRejection" },
                        { __typename: "CardProductNotFoundRejection" },
                        __typename =>
                          showToast({ variant: "error", title: translateError(__typename) }),
                      )
                      .with({ __typename: "ValidationRejection" }, () => {
                        // TODO: better error
                        showToast({ variant: "error", title: t("error.generic") });
                      })
                      .with({ __typename: "UpdateCardProductSuccessPayload" }, () => {
                        showToast({
                          variant: "success",
                          title: t("toast.success.newCardProduct"),
                        });
                        onSave();
                      })
                      .exhaustive();
                  });
                })
                .exhaustive();
            })
            .tapError((error: unknown) => {
              showToast({ variant: "error", error, title: translateError(error) });
            });
        }
      },
    });
  };

  return (
    <View>
      <LakeText>{t("cardProducts.newCardProduct.spendingLimitsNotice")}</LakeText>
      <Space height={32} />

      <LakeLabel
        label={t("cardProducts.newCardProduct.cardProductName")}
        render={id => (
          <Field name="name">
            {({ value, onChange, error }) => (
              <LakeTextInput
                readOnly={loading}
                id={id}
                value={value}
                onChangeText={onChange}
                error={error}
                placeholder={t("cardProducts.newCardProduct.cardProductNamePlaceholder")}
              />
            )}
          </Field>
        )}
      />

      <Space height={12} />

      <Field name="applicableToPhysicalCards">
        {({ value, onChange }) => (
          <Box direction="row" alignItems="center">
            <Switch value={value} onValueChange={onChange} />
            <Space width={12} />

            <TrackPressable action="Allow physical cards">
              <Pressable onPress={() => onChange(!value)}>
                <LakeText color={colors.gray[700]}>
                  {t("cardProducts.newCardProduct.allowPhysicalCards")}
                </LakeText>
              </Pressable>
            </TrackPressable>
          </Box>
        )}
      </Field>

      <Space height={40} />

      <LakeTooltip
        placement="left"
        content={t("common.action.denied")}
        disabled={canEditCardProducts}
      >
        <LakeButton
          color="current"
          icon="add-circle-filled"
          loading={loading}
          onPress={onSubmit}
          disabled={!canEditCardProducts}
        >
          {t("common.create")}
        </LakeButton>
      </LakeTooltip>
    </View>
  );
};
