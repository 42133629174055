import { Dict } from "@swan-io/boxed";
import { Accordion } from "@swan-io/lake/src/components/Accordion";
import { BorderedIcon } from "@swan-io/lake/src/components/BorderedIcon";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { isNotNullish, isNullish } from "@swan-io/lake/src/utils/nullish";
import { Fragment } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import {
  MerchantPaymentMethodStatus,
  MerchantPaymentMethodType,
  MerchantProfileDetailFragment,
  RollingReserve,
} from "../graphql/partner";
import { t } from "../utils/i18n";
import { useTgglFlag } from "../utils/tggl";
import {
  ConfirmPaymentMethodModal,
  SddPaymentMethodModal,
} from "./AccountDetailMerchantPaymentSddPaymentMethodModal";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  content: {
    height: spacings[48],
    paddingRight: 24,
  },
});

type Props = {
  merchantProfile: MerchantProfileDetailFragment;
};

type PaymentMethodContentType = {
  paymentMethod: AvailablePaymentMethodType;
  merchantProfileId: string;
  effectiveMethodStatus: MerchantPaymentMethodStatus | undefined;
};

export type AvailablePaymentMethodType = {
  name: MerchantPaymentMethodType;
  icon: IconName;
};

const PaymentMethodAlert = ({ value }: { value: MerchantPaymentMethodStatus | undefined }) => {
  if (isNullish(value) || value === "Enabled") {
    return null;
  }

  return (
    <>
      {match(value)
        .with("Disabled", () => (
          <LakeAlert variant="error" title={t("merchantPaymentMethods.method.alert.disabled")} />
        ))
        .with("PendingReview", () => (
          <LakeAlert variant="info" title={t("merchantPaymentMethods.method.alert.pending")} />
        ))
        .with("Rejected", () => (
          <LakeAlert variant="error" title={t("merchantPaymentMethods.method.alert.rejected")} />
        ))
        .with("Suspended", () => (
          <LakeAlert variant="error" title={t("merchantPaymentMethods.method.alert.suspended")} />
        ))
        .exhaustive()}

      <Space height={24} />
    </>
  );
};

const PaymentRollingReserve = ({ value }: { value: RollingReserve | null | undefined }) => (
  <>
    <Space height={24} />

    <LakeLabel
      color="swan"
      type="view"
      label={t("merchantPaymentMethods.rollingReserve")}
      render={() => (
        <>
          {isNotNullish(value) && (
            <>
              <LakeText color={colors.gray[900]}>
                {t("merchantPaymentMethods.rollingReserve.percentage", {
                  percentage: value.percentage,
                  rollingDays: value.rollingDays,
                })}
              </LakeText>

              <Space height={8} />
            </>
          )}

          <LakeText color={colors.gray[500]}>
            {t("merchantPaymentMethods.rollingReserve.description")}
          </LakeText>
        </>
      )}
    />
  </>
);

const PaymentMethodContent = ({
  paymentMethod,
  merchantProfileId,
  effectiveMethodStatus,
}: PaymentMethodContentType) => {
  const [
    requestSddPaymentMethodVisible,
    { open: openSddPaymentMethodModal, close: closeSddPaymentMethodModal },
  ] = useDisclosure(false);

  const [
    requestConfirmPaymentMethodVisible,
    { open: openConfirmPaymentMethodModal, close: closeConfirmPaymentMethodModal },
  ] = useDisclosure(false);

  return (
    <>
      <SddPaymentMethodModal
        closeSddPaymentMethodModal={closeSddPaymentMethodModal}
        merchantProfileId={merchantProfileId}
        paymentMethod={paymentMethod}
        requestSddPaymentMethodVisible={requestSddPaymentMethodVisible}
      />

      <ConfirmPaymentMethodModal
        paymentMethod={paymentMethod}
        merchantProfileId={merchantProfileId}
        closeConfirmPaymentMethodModal={closeConfirmPaymentMethodModal}
        requestConfirmPaymentMethodVisible={requestConfirmPaymentMethodVisible}
      />

      <Box direction="row" alignItems="center" style={[commonStyles.fill, styles.content]}>
        <BorderedIcon name={paymentMethod.icon} size={32} padding={4} />
        <Space width={12} />

        <LakeText>
          {match(paymentMethod.name)
            .with("Check", () => t("merchantPaymentMethods.check"))
            .with("InternalDirectDebitB2b", () =>
              t("merchantPaymentMethods.internalDirectDebitB2b"),
            )
            .with("InternalDirectDebitStandard", () =>
              t("merchantPaymentMethods.internalDirectDebitStandard"),
            )
            .with("SepaDirectDebitB2b", () => t("merchantPaymentMethods.sepaDirectDebitB2b"))
            .with("SepaDirectDebitCore", () => t("merchantPaymentMethods.sepaDirectDebitCore"))
            .with("Card", () => t("merchantPaymentMethods.card"))
            .exhaustive()}
        </LakeText>

        <Fill minWidth={12} />

        {match(effectiveMethodStatus)
          .with("Disabled", () => (
            <Tag color="gray" size="small">
              {t("merchantPaymentMethods.status.disabled")}
            </Tag>
          ))
          .with("Enabled", () => (
            <Tag color="positive" size="small">
              {t("merchantPaymentMethods.status.enabled")}
            </Tag>
          ))
          .with("PendingReview", () => (
            <Tag color="shakespear" size="small">
              {t("merchantPaymentMethods.status.pendingReview")}
            </Tag>
          ))
          .with("Rejected", () => (
            <Tag color="negative" size="small">
              {t("merchantPaymentMethods.status.rejected")}
            </Tag>
          ))
          .with("Suspended", () => (
            <Tag color="negative" size="small">
              {t("merchantPaymentMethods.status.suspended")}
            </Tag>
          ))
          .with(P.nullish, () => (
            <TrackPressable action="Request merchant payment method">
              <LakeButton
                size="small"
                color="current"
                onPress={
                  paymentMethod.name === "SepaDirectDebitB2b" ||
                  paymentMethod.name === "SepaDirectDebitCore"
                    ? openSddPaymentMethodModal
                    : openConfirmPaymentMethodModal
                }
              >
                {t("merchantPaymentMethods.request")}
              </LakeButton>
            </TrackPressable>
          ))
          .exhaustive()}
      </Box>
    </>
  );
};

export const AccountDetailMerchantPaymentMethodsListLegacy = ({ merchantProfile }: Props) => {
  const isCardPaymentMethodFlagActive = useTgglFlag("activateCardPaymentMethod").getOr(false);

  const availablePaymentMethod: AvailablePaymentMethodType[] = [
    ...(isCardPaymentMethodFlagActive
      ? [{ name: "Card" as const, icon: "lake-card-physical" as const }]
      : []),
    { name: "Check", icon: "check-regular" },
    { name: "InternalDirectDebitB2b", icon: "arrow-swap-regular" },
    { name: "InternalDirectDebitStandard", icon: "arrow-swap-regular" },
    { name: "SepaDirectDebitB2b", icon: "arrow-swap-regular" },
    { name: "SepaDirectDebitCore", icon: "arrow-swap-regular" },
  ];

  const paymentMethods = merchantProfile.merchantPaymentMethods ?? [];
  type PaymentMethod = (typeof paymentMethods)[number];

  const paymentMethodsByType = paymentMethods.reduce(
    (acc, method) => {
      acc[method.type] ??= [];
      acc[method.type].push(method);
      return acc;
    },
    {} as Record<MerchantPaymentMethodType, PaymentMethod[]>,
  );

  const effectivePaymentMethods = Object.fromEntries(
    Dict.entries(paymentMethodsByType).map(([type, methods]) => {
      const statuses = methods.map(method => method.statusInfo.status);

      const status = (["Enabled", "PendingReview", "Suspended", "Rejected"] as const).find(status =>
        statuses.includes(status),
      );

      const method = methods.find(method => method.statusInfo.status === status);
      return [type, method as PaymentMethod] as const;
    }),
  );

  return availablePaymentMethod.map(availablePaymentMethod => {
    const effectivePaymentMethod = effectivePaymentMethods[availablePaymentMethod.name];
    const effectivePaymentMethodStatus = effectivePaymentMethod?.statusInfo.status;

    const displayPaymentMethodId = (paymentMethod: PaymentMethod) => {
      return (
        <>
          <LakeLabel
            label={t("merchantPaymentMethods.id")}
            type="view"
            render={() => <LakeText color={colors.gray[900]}>{paymentMethod.id}</LakeText>}
            actions={
              <LakeCopyButton
                valueToCopy={paymentMethod.id}
                copyText={t("copyButton.copyTooltip")}
                copiedText={t("copyButton.copiedTooltip")}
              />
            }
          />

          <Space height={24} />
        </>
      );
    };

    return (
      <Fragment key={availablePaymentMethod.name}>
        <Accordion
          trigger={
            <PaymentMethodContent
              paymentMethod={availablePaymentMethod}
              merchantProfileId={merchantProfile.id}
              effectiveMethodStatus={effectivePaymentMethodStatus}
            />
          }
        >
          <Box>
            {match(availablePaymentMethod.name)
              .with("Card", () => (
                <>
                  <PaymentMethodAlert value={effectivePaymentMethodStatus} />

                  {effectivePaymentMethod && displayPaymentMethodId(effectivePaymentMethod)}

                  <LakeText>{t("merchantPaymentMethods.card.description")}</LakeText>
                  <PaymentRollingReserve value={effectivePaymentMethod?.rollingReserve} />
                </>
              ))
              .with("Check", () => (
                <>
                  <PaymentMethodAlert value={effectivePaymentMethodStatus} />

                  {effectivePaymentMethod && displayPaymentMethodId(effectivePaymentMethod)}

                  <LakeText>{t("merchantPaymentMethods.check.description")}</LakeText>
                  <PaymentRollingReserve value={effectivePaymentMethod?.rollingReserve} />
                </>
              ))
              .with("InternalDirectDebitB2b", () => (
                <>
                  <PaymentMethodAlert value={effectivePaymentMethodStatus} />

                  {effectivePaymentMethod && displayPaymentMethodId(effectivePaymentMethod)}

                  <LakeText>
                    {t("merchantPaymentMethods.internalDirectDebitB2b.description")}
                  </LakeText>

                  <PaymentRollingReserve value={effectivePaymentMethod?.rollingReserve} />
                </>
              ))
              .with("InternalDirectDebitStandard", () => (
                <>
                  <PaymentMethodAlert value={effectivePaymentMethodStatus} />

                  {effectivePaymentMethod && displayPaymentMethodId(effectivePaymentMethod)}

                  <LakeText>
                    {t("merchantPaymentMethods.internalDirectDebitStandard.description")}
                  </LakeText>

                  <PaymentRollingReserve value={effectivePaymentMethod?.rollingReserve} />
                </>
              ))
              .with("SepaDirectDebitB2b", () => (
                <>
                  <PaymentMethodAlert value={effectivePaymentMethodStatus} />

                  {effectivePaymentMethod && displayPaymentMethodId(effectivePaymentMethod)}

                  <LakeText>{t("merchantPaymentMethods.sepaDirectDebitB2b.description")}</LakeText>

                  {match(effectivePaymentMethod)
                    .with(
                      {
                        __typename: "SepaDirectDebitB2BMerchantPaymentMethod",
                      },
                      ({
                        useSwanSepaCreditorIdentifier,
                        sepaCreditorIdentifier,
                        rollingReserve,
                      }) => (
                        <>
                          <Space height={24} />

                          <LakeLabel
                            color="swan"
                            type="view"
                            label={t("merchantPaymentMethods.sepaCreditorIdentifier")}
                            render={() => (
                              <Box direction="row">
                                <Icon
                                  name="checkmark-filled"
                                  size={24}
                                  color={colors.positive[500]}
                                />

                                <Space width={8} />

                                <LakeText color={colors.gray[900]}>
                                  {useSwanSepaCreditorIdentifier
                                    ? t("merchantPaymentMethods.swanCreditorIdentifier")
                                    : sepaCreditorIdentifier}
                                </LakeText>
                              </Box>
                            )}
                          />

                          <PaymentRollingReserve value={rollingReserve} />
                        </>
                      ),
                    )
                    .with(P.nonNullable, ({ rollingReserve }) => (
                      <PaymentRollingReserve value={rollingReserve} />
                    ))
                    .otherwise(() => null)}
                </>
              ))
              .with("SepaDirectDebitCore", () => (
                <>
                  <PaymentMethodAlert value={effectivePaymentMethodStatus} />

                  {effectivePaymentMethod && displayPaymentMethodId(effectivePaymentMethod)}

                  <LakeText>{t("merchantPaymentMethods.sepaDirectDebitCore.description")}</LakeText>

                  {match(effectivePaymentMethod)
                    .with(
                      {
                        __typename: "SepaDirectDebitCoreMerchantPaymentMethod",
                      },
                      ({
                        useSwanSepaCreditorIdentifier,
                        sepaCreditorIdentifier,
                        rollingReserve,
                      }) => (
                        <>
                          <Space height={24} />

                          <LakeLabel
                            color="swan"
                            type="view"
                            label={t("merchantPaymentMethods.sepaCreditorIdentifier")}
                            render={() => (
                              <Box direction="row">
                                <Icon
                                  name="checkmark-filled"
                                  size={24}
                                  color={colors.positive[500]}
                                />

                                <Space width={8} />

                                <LakeText color={colors.gray[900]}>
                                  {useSwanSepaCreditorIdentifier
                                    ? t("merchantPaymentMethods.swanCreditorIdentifier")
                                    : sepaCreditorIdentifier}
                                </LakeText>
                              </Box>
                            )}
                          />

                          <PaymentRollingReserve value={rollingReserve} />
                        </>
                      ),
                    )
                    .with(P.nonNullable, ({ rollingReserve }) => (
                      <PaymentRollingReserve value={rollingReserve} />
                    ))
                    .otherwise(() => null)}
                </>
              ))
              .exhaustive()}

            <Space height={24} />
          </Box>
        </Accordion>

        <Separator />
      </Fragment>
    );
  });
};
