import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useFocusReset } from "@swan-io/chicane";
import { useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView, tabsViewHeight } from "@swan-io/lake/src/components/TabView";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { GetAccountDocument } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router, accountDetailRoutes } from "../utils/routes";
import { useTgglFlag } from "../utils/tggl";
import { AccountDetailAccountMemberships } from "./AccountDetailAccountMemberships";
import { AccountDetailAccountStatements } from "./AccountDetailAccountStatements";
import { AccountDetailFundingSourceDetail } from "./AccountDetailFundingSourceDetail";
import { AccountDetailFundingSourceList } from "./AccountDetailFundingSourceList";
import { AccountDetailGeneral } from "./AccountDetailGeneral";
import { AccountDetailInvoicesList } from "./AccountDetailInvoicesList";
import { AccountDetailMerchantList } from "./AccountDetailMerchantList";
import { AccountDetailMerchantListLegacy } from "./AccountDetailMerchantListLegacy";
import { AccountDetailMerchantProfileArea } from "./AccountDetailMerchantProfileArea";
import { AccountDetailReceivedDirectDebitMandateDetail } from "./AccountDetailReceivedDirectDebitMandateDetail";
import { AccountDetailReceivedDirectDebitMandateList } from "./AccountDetailReceivedDirectDebitMandateList";
import { AccountDetailTransactionDetailArea } from "./AccountDetailTransactionDetailArea";
import { AccountDetailTransactions } from "./AccountDetailTransactions";
import { AccountDetailVirtualIbanList } from "./AccountDetailVirtualIbanList";
import { ErrorView } from "./ErrorView";
import { Redirect } from "./Redirect";

type Props = {
  accountId: string;
};

const styles = StyleSheet.create({
  routeContainer: {
    flexGrow: 1,
  },
  scrollContainer: {
    flex: 1,
  },
});

export const AccountDetail = ({ accountId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(accountDetailRoutes);
  const containerRef = useRef(null);

  useFocusReset({ route, containerRef });

  const [data] = useQuery(GetAccountDocument, { accountId });

  const isMerchantFlagActive = useTgglFlag("merchantDashboard").getOr(false);

  useCrumb(
    useMemo(() => {
      return data
        .toOption()
        .flatMap(result => result.toOption())
        .flatMap(data => Option.fromNullable(data.account))
        .map(account => ({
          label: `${account.number} - ${account.name}`,
          link: match(route?.name)
            .with("AccountDetailTransactionsDetail", () =>
              Router.AccountDetailTransactions({ projectId, projectEnv, accountId }),
            )
            .with("AccountDetailFundingSourcesDetail", () =>
              Router.AccountDetailFundingSources({ projectId, projectEnv, accountId }),
            )
            .with("AccountDetailReceivedDirectDebitMandatesDetail", () =>
              Router.AccountDetailReceivedDirectDebitMandates({
                projectId,
                projectEnv,
                accountId,
              }),
            )
            .with("AccountDetailMerchantProfile", () =>
              Router.AccountDetailMerchantList({ projectId, projectEnv, accountId }),
            )
            .otherwise(() => Router.AccountDetailRoot({ projectId, projectEnv, accountId })),
        }))
        .toUndefined();
    }, [projectId, accountId, projectEnv, data, route?.name]),
  );

  return match(route)
    .with(
      { name: "AccountDetailTransactionsDetail" },
      { name: "AccountDetailTransactionsDetailSupportingDocuments" },
      ({ params: { transactionId } }) => (
        <AccountDetailTransactionDetailArea transactionId={transactionId} accountId={accountId} />
      ),
    )
    .with({ name: "AccountDetailFundingSourcesDetail" }, ({ params: { fundingSourceId } }) => (
      <LakeScrollView>
        <AccountDetailFundingSourceDetail fundingSourceId={fundingSourceId} accountId={accountId} />
      </LakeScrollView>
    ))
    .with(
      { name: "AccountDetailReceivedDirectDebitMandatesDetail" },
      ({ params: { receivedDirectDebitMandateId } }) => (
        <LakeScrollView>
          <AccountDetailReceivedDirectDebitMandateDetail
            receivedDirectDebitMandateId={receivedDirectDebitMandateId}
            accountId={accountId}
          />
        </LakeScrollView>
      ),
    )
    .with({ name: "AccountDetailMerchantProfile" }, ({ params: { merchantProfileId } }) => (
      <AccountDetailMerchantProfileArea
        accountId={accountId}
        merchantProfileId={merchantProfileId}
      />
    ))
    .otherwise(route => (
      <>
        {match(data)
          .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
            <View style={{ height: tabsViewHeight }} />
          ))
          .with(AsyncData.P.Done(Result.P.Error(P.select())), () => null)
          .with(AsyncData.P.Done(Result.P.Ok({ account: P.nullish })), () => null)
          .with(AsyncData.P.Done(Result.P.Ok({ account: P.select(P.nonNullable) })), account => (
            <TabView
              tabs={[
                {
                  label: t("account.general"),
                  url: Router.AccountDetailRoot({ projectId, projectEnv, accountId }),
                },
                {
                  label: t("account.transactions"),
                  url: Router.AccountDetailTransactions({ projectId, projectEnv, accountId }),
                },
                {
                  label: t("account.accountMemberships"),
                  url: Router.AccountDetailAccountMemberships({
                    projectId,
                    projectEnv,
                    accountId,
                  }),
                },
                {
                  label: t("account.accountStatements"),
                  url: Router.AccountDetailAccountStatementsRoot({
                    projectId,
                    projectEnv,
                    accountId,
                  }),
                },
                {
                  label: t("account.invoices"),
                  url: Router.AccountDetailInvoices({ projectId, projectEnv, accountId }),
                },
                {
                  label: t("account.virtualIbans"),
                  url: Router.AccountDetailVirtualIbans({ projectId, projectEnv, accountId }),
                },
                ...(account.holder.info.type === "Company"
                  ? isMerchantFlagActive === true
                    ? [
                        {
                          label: t("account.merchantProfiles"),
                          url: Router.AccountDetailMerchantList({
                            projectId,
                            projectEnv,
                            accountId,
                          }),
                        },
                      ]
                    : [
                        {
                          label: t("account.merchantProfiles"),
                          url: Router.AccountDetailMerchantListLegacy({
                            projectId,
                            projectEnv,
                            accountId,
                          }),
                        },
                      ]
                  : []),
                {
                  label: t("account.fundingSources"),
                  url: Router.AccountDetailFundingSources({ projectId, projectEnv, accountId }),
                },
                {
                  label: t("account.receivedDirectDebitMandates"),
                  url: Router.AccountDetailReceivedDirectDebitMandates({
                    projectId,
                    projectEnv,
                    accountId,
                  }),
                },
              ]}
              otherLabel={t("common.tabs.other")}
            />
          ))
          .exhaustive()}

        <Space height={24} />

        <View ref={containerRef} style={styles.routeContainer}>
          {match(route)
            .with({ name: "AccountDetailRoot" }, () =>
              match(data)
                .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
                  <TileGridPlaceholder withTabs={false} />
                ))
                .with(AsyncData.P.Done(Result.P.Error(P.select())), error => (
                  <ErrorView error={error} />
                ))
                .with(AsyncData.P.Done(Result.P.Ok({ account: P.nullish })), () => (
                  <Redirect to={Router.AccountsRoot({ projectId, projectEnv })} />
                ))
                .with(
                  AsyncData.P.Done(Result.P.Ok({ account: P.select(P.nonNullable) })),
                  account => (
                    <LakeScrollView>
                      <AccountDetailGeneral account={account} />
                    </LakeScrollView>
                  ),
                )
                .exhaustive(),
            )
            .with({ name: "AccountDetailMerchantListLegacy" }, () => (
              <AccountDetailMerchantListLegacy accountId={accountId} />
            ))
            .with({ name: "AccountDetailMerchantList" }, ({ params }) => (
              <AccountDetailMerchantList accountId={accountId} params={params} />
            ))
            .with({ name: "AccountDetailTransactions" }, ({ params }) => (
              <AccountDetailTransactions accountId={accountId} params={params} />
            ))
            .with({ name: "AccountDetailAccountMemberships" }, ({ params }) => (
              <AccountDetailAccountMemberships accountId={accountId} params={params} />
            ))
            .with({ name: "AccountDetailInvoices" }, () => (
              <AccountDetailInvoicesList accountId={accountId} />
            ))
            .with({ name: "AccountDetailVirtualIbans" }, () => (
              <AccountDetailVirtualIbanList accountId={accountId} />
            ))
            .with({ name: "AccountDetailFundingSources" }, () => (
              <AccountDetailFundingSourceList accountId={accountId} />
            ))
            .with({ name: "AccountDetailReceivedDirectDebitMandates" }, () => (
              <AccountDetailReceivedDirectDebitMandateList accountId={accountId} />
            ))
            .with(
              { name: "AccountDetailAccountStatementsRoot" },
              { name: "AccountDetailAccountStatementsNew" },
              ({ params }) => (
                <AccountDetailAccountStatements accountId={accountId} params={params} />
              ),
            )
            .otherwise(() => (
              <NotFoundPage />
            ))}
        </View>
      </>
    ));
};
