import { encodeSearch, pushUnsafe, useLocation } from "@swan-io/chicane";
import { ClientContext } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { SwanLogo } from "@swan-io/lake/src/components/SwanLogo";
import { backgroundColor, colors, texts } from "@swan-io/lake/src/constants/design";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { useCallback } from "react";
import { StyleSheet, Text, View } from "react-native";
import { match } from "ts-pattern";
import { LoggedUserInfoFragment } from "../graphql/admin";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { env } from "../utils/env";
import {
  livePartnerByProjectIdClient,
  livePartnerByProjectIdClient__projectMember,
  sandboxPartnerByProjectIdClient,
  sandboxPartnerByProjectIdClient__projectMember,
} from "../utils/gql";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { useTgglFlag } from "../utils/tggl";
import { GlobalSearch } from "./GlobalSearch";
import { ProfileButton } from "./ProfileButton";
import { ProjectSelectorButton } from "./ProjectSelectorButton";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  base: {
    backgroundColor: backgroundColor.accented,
    alignItems: "stretch",
    height: 64,
    flexDirection: "row",
    justifyContent: "center",
    boxShadow: "0 1px rgba(0, 0, 0, 0.06)",
  },
  content: {
    width: "100%",
    maxWidth: 2560,
    paddingHorizontal: 20,
  },
  logo: {
    height: "auto",
    width: 70,
  },
  headerLink: {
    ...texts.medium,
    color: colors.partner.primary,
    display: "flex",
    alignItems: "center",
  },
  linkSeparator: {
    marginVertical: "auto",
    height: "55%",
  },
});

const HeaderLink = ({
  desktop,
  href,
  icon,
  text,
}: {
  desktop: boolean;
  href?: string;
  icon: IconName;
  text: string;
}) => (
  <LakeText
    style={styles.headerLink}
    numberOfLines={1}
    {...(isNotNullishOrEmpty(href) && {
      href,
      hrefAttrs: { rel: "noopener noreferrer", target: "blank" },
      role: "link",
    })}
  >
    {desktop ? (
      <>
        <Icon name={icon} color={colors.partner.primary} size={20} />
        <Space width={8} />
        <Text numberOfLines={1}>{text}</Text>
      </>
    ) : (
      <LakeTooltip placement="center" content={text}>
        <Icon name={icon} color={colors.partner.primary} size={20} />
      </LakeTooltip>
    )}
  </LakeText>
);

type Props = {
  user: LoggedUserInfoFragment;
  hasProjectFullLiveAccess: boolean;
};

export const Header = ({ hasProjectFullLiveAccess, user }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const { search } = useLocation();
  const { write: canActivateProject } = usePermissions("live").projectStatusManagement;

  const showActivateButton = !hasProjectFullLiveAccess && canActivateProject;

  const { desktop } = useResponsive(showActivateButton ? 1600 : 1430);

  const onPressSupport = useCallback(() => {
    const redirectUrl = encodeURIComponent("https://swan3071.zendesk.com");
    window.open(`/zendesk/sso/${projectId}?redirect_url=${redirectUrl}`);
  }, [projectId]);

  const shouldUseProjectMemberToken = useTgglFlag("dashboardProjectMemberToken").getOr(false);

  const client = match({ projectEnv, shouldUseProjectMemberToken })
    .with({ projectEnv: "live", shouldUseProjectMemberToken: true }, () =>
      livePartnerByProjectIdClient__projectMember(projectId),
    )
    .with({ projectEnv: "live" }, () => livePartnerByProjectIdClient(projectId))
    .with({ projectEnv: "sandbox", shouldUseProjectMemberToken: true }, () =>
      sandboxPartnerByProjectIdClient__projectMember(projectId),
    )
    .with({ projectEnv: "sandbox" }, () => sandboxPartnerByProjectIdClient(projectId))
    .exhaustive();

  return (
    <View role="banner" style={styles.base}>
      <Box direction="row" alignItems="center" style={styles.content}>
        <Link to={Router.BaseRoot()}>
          <SwanLogo style={styles.logo} />
        </Link>

        <Separator horizontal={true} space={24} style={styles.linkSeparator} />
        <ProjectSelectorButton user={user} />

        {showActivateButton && (
          <>
            <Space width={24} />

            <TrackPressable action="Activate">
              <LakeButton
                icon="flash-filled"
                color="partner"
                size="small"
                onPress={() => {
                  pushUnsafe(`${encodeSearch({ ...search, activate: "true" })}`);
                }}
              >
                {t("header.activate")}
              </LakeButton>
            </TrackPressable>
          </>
        )}

        <Space width={24} />

        <ClientContext.Provider value={client}>
          <GlobalSearch complete={desktop} />
        </ClientContext.Provider>

        <Fill minWidth={24} />

        <HeaderLink
          desktop={desktop}
          href="https://docs.swan.io"
          icon="document-regular"
          text={t("header.documentation")}
        />

        <Space width={20} />

        <HeaderLink
          desktop={desktop}
          href={env.EXPLORER_URL}
          icon="code-regular"
          text={t("header.apiExplorer")}
        />

        <Space width={20} />

        <TrackPressable action="Ask for support">
          <Pressable onPress={onPressSupport}>
            <HeaderLink desktop={desktop} icon="chat-help-regular" text={t("header.support")} />
          </Pressable>
        </TrackPressable>

        <Separator horizontal={true} space={24} style={styles.linkSeparator} />
        <ProfileButton currentUser={user} />
      </Box>
    </View>
  );
};
