import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useForm } from "@swan-io/use-form";
import { useCallback } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import { MerchantPaymentMethodStatus } from "../../graphql/partner";
import {
  MerchantPaymentMethodRejectReason,
  SimulateUpdateMerchantPaymentMethodDocument,
} from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

const statusPaymentMethodList: { value: MerchantPaymentMethodStatus; name: string }[] = [
  { value: "Disabled" as const, name: t("simulatorForm.merchantPaymentMethodStatus.disabled") },
  {
    value: "PendingReview" as const,
    name: t("simulatorForm.merchantPaymentMethodStatus.pendingReview"),
  },
  { value: "Rejected" as const, name: t("simulatorForm.merchantPaymentMethodStatus.rejected") },
  {
    value: "Enabled" as const,
    name: t("simulatorForm.merchantPaymentMethodStatus.enabled"),
  },
  {
    value: "Suspended" as const,
    name: t("simulatorForm.merchantPaymentMethodStatus.suspended"),
  },
];

const rejectedReasonsList: { value: MerchantPaymentMethodRejectReason; name: string }[] = [
  {
    value: "SwanRefused" as const,
    name: t("simulatorForm.merchantPaymentMethodRejectedReason.swanRefused"),
  },
  {
    value: "SwanTechnicalErrorOccurred" as const,
    name: t("simulatorForm.merchantPaymentMethodRejectedReason.swanTechnicalErrorOccurred"),
  },
  {
    value: "UnsupportedBusiness" as const,
    name: t("simulatorForm.merchantPaymentMethodRejectedReason.unsupportedBusiness"),
  },
];

export const UpdateMerchantPaymentMethodPage = () => {
  const [simulate, simulation] = useMutation(SimulateUpdateMerchantPaymentMethodDocument);

  const merchantResult = simulation.mapOkToResult(simulation =>
    match(simulation.simulateMerchantPaymentMethodRequestOutcome)
      .with(
        { __typename: "CardMerchantPaymentMethod" },
        { __typename: "CheckMerchantPaymentMethod" },
        { __typename: "InternalDirectDebitB2BMerchantPaymentMethod" },
        { __typename: "InternalDirectDebitStandardMerchantPaymentMethod" },
        { __typename: "SepaDirectDebitB2BMerchantPaymentMethod" },
        { __typename: "SepaDirectDebitCoreMerchantPaymentMethod" },
        ({ id }) => Result.Ok([{ key: "merchantPaymentMethodId", value: id }]),
      )
      .otherwise(error => Result.Error({ rejection: error?.__typename ?? "Unknown" })),
  );

  const { Field, submitForm, formStatus, FieldsListener } = useForm<{
    merchantPaymentMethodId: string;
    merchantPaymentMethodStatus: MerchantPaymentMethodStatus;
    merchantPaymentMethodRejectReason: MerchantPaymentMethodRejectReason;
  }>({
    merchantPaymentMethodId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    merchantPaymentMethodStatus: {
      initialValue: "Enabled",
    },
    merchantPaymentMethodRejectReason: {
      initialValue: "SwanRefused",
    },
  });

  const handleSubmit = useCallback(() => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isSome()) {
          const {
            merchantPaymentMethodId,
            merchantPaymentMethodStatus,
            merchantPaymentMethodRejectReason,
          } = option.get();

          return simulate({
            input: {
              merchantPaymentMethodId,
              merchantPaymentMethodStatus,
              merchantPaymentMethodRejectReason,
            },
          });
        }
      },
    });
  }, [simulate, submitForm]);

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t(
              "simulatorPage.merchantPaymentCollection.updateMerchantPaymentMethodStatusDescription",
            )}
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="merchantPaymentMethodId">
            {({ value, onChange, error, valid }) => (
              <LakeLabel
                label={`${t("simulatorForm.merchantPaymentMethodId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    error={error}
                    valid={valid}
                    placeholder={t("simulatorForm.merchantPaymentMethodIdPlaceholder")}
                    value={value}
                    onChangeText={onChange}
                  />
                )}
              />
            )}
          </Field>

          <Field name="merchantPaymentMethodStatus">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.merchantPaymentMethodStatus")}
                render={id => (
                  <LakeSelect
                    id={id}
                    items={statusPaymentMethodList}
                    value={value}
                    onValueChange={onChange}
                  />
                )}
              />
            )}
          </Field>

          <Field name="merchantPaymentMethodRejectReason">
            {({ value, onChange }) => (
              <FieldsListener names={["merchantPaymentMethodStatus"]}>
                {({ merchantPaymentMethodStatus }) =>
                  merchantPaymentMethodStatus.value !== "Rejected" ? null : (
                    <LakeLabel
                      label={t("simulatorForm.merchantPaymentMethodRejectedReason")}
                      render={id => (
                        <LakeSelect
                          id={id}
                          items={rejectedReasonsList}
                          value={value}
                          onValueChange={onChange}
                        />
                      )}
                    />
                  )
                }
              </FieldsListener>
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit merchant payment method form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={handleSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "merchantPaymentMethodId",
              label: t("simulatorForm.merchantPaymentMethodId"),
              placeholder: "-",
            },
          ]}
          results={merchantResult}
        />
      </Box>
    </Form>
  );
};
