import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { RadioGroup, RadioGroupItem } from "@swan-io/lake/src/components/RadioGroup";
import { Space } from "@swan-io/lake/src/components/Space";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useForm } from "@swan-io/use-form";
import { useCallback } from "react";
import { match } from "ts-pattern";
import { RequestMerchantPaymentMethodsDocument } from "../graphql/partner";
import { t } from "../utils/i18n";
import { validateRequired } from "../utils/validations";
import { AvailablePaymentMethodType } from "./AccountDetailMerchantPaymentMethodsListLegacy";
import { TrackPressable } from "./TrackPressable";

const sepaCreditorIdentifierItems: RadioGroupItem<boolean>[] = [
  {
    name: t("merchantPaymentMethods.swanSepaCreditorIdentifier"),
    value: true,
  },
  {
    name: t("merchantPaymentMethods.ownSepaCreditorIdentifier"),
    value: false,
  },
];

type SddPaymentMethodModalProps = {
  paymentMethod: AvailablePaymentMethodType;
  merchantProfileId: string;
  requestSddPaymentMethodVisible: boolean;
  closeSddPaymentMethodModal: () => void;
};

type ConfirmPaymentMethodModalProps = {
  paymentMethod: AvailablePaymentMethodType;
  merchantProfileId: string;
  requestConfirmPaymentMethodVisible: boolean;
  closeConfirmPaymentMethodModal: () => void;
};

export const SddPaymentMethodModal = ({
  paymentMethod,
  merchantProfileId,
  closeSddPaymentMethodModal,
  requestSddPaymentMethodVisible,
}: SddPaymentMethodModalProps) => {
  const { Field, FieldsListener, submitForm, resetForm } = useForm<{
    sepaCreditorIdentifier: string;
    useSwanSepaCreditorIdentifier: boolean;
  }>({
    useSwanSepaCreditorIdentifier: {
      initialValue: true,
    },
    sepaCreditorIdentifier: {
      initialValue: "",
      validate: (value, { getFieldValue }) => {
        const useSwanSepaCreditorIdentifier = getFieldValue("useSwanSepaCreditorIdentifier");
        return !useSwanSepaCreditorIdentifier ? validateRequired(value) : undefined;
      },
      sanitize: value => value.trim(),
    },
  });

  const [requestMerchantPaymentMethods, requestMerchantPaymentMethodsData] = useMutation(
    RequestMerchantPaymentMethodsDocument,
  );

  const onSubmit = useCallback(() => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isSome()) {
          const { sepaCreditorIdentifier, useSwanSepaCreditorIdentifier } = option.get();

          return requestMerchantPaymentMethods({
            input: {
              merchantProfileId,
              ...match(paymentMethod)
                .with({ name: "SepaDirectDebitB2b" }, () => {
                  return {
                    sepaDirectDebitB2B: {
                      activate: true,
                      sepaCreditorIdentifier,
                      useSwanSepaCreditorIdentifier,
                    },
                  };
                })
                .with({ name: "SepaDirectDebitCore" }, () => {
                  return {
                    sepaDirectDebitCore: {
                      activate: true,
                      sepaCreditorIdentifier,
                      useSwanSepaCreditorIdentifier,
                    },
                  };
                })
                //Other payment methods cases are managed in the PaymentMethodContent component
                .otherwise(() => {}),
            },
          })
            .mapOk(data => data.requestMerchantPaymentMethods)
            .mapOkToResult(data => (isNotNullish(data) ? Result.Ok(data) : Result.Error(undefined)))
            .mapOkToResult(filterRejectionsToResult)
            .tapOk(() => {
              closeSddPaymentMethodModal();

              showToast({
                variant: "success",
                title: t("toast.success.merchantPaymentMethod.requested"),
              });
            })
            .tapError(error => {
              showToast({ variant: "error", error, title: translateError(error) });
            });
        }
      },
    });
  }, [
    closeSddPaymentMethodModal,
    merchantProfileId,
    paymentMethod,
    requestMerchantPaymentMethods,
    submitForm,
  ]);

  return (
    <LakeModal
      visible={requestSddPaymentMethodVisible}
      title={t("merchantPaymentMethods.sddModalTitle")}
    >
      <Space height={32} />

      <Field name="useSwanSepaCreditorIdentifier">
        {({ value, onChange }) => (
          <RadioGroup
            direction="row"
            items={sepaCreditorIdentifierItems}
            value={value}
            onValueChange={onChange}
          />
        )}
      </Field>

      <Field name="sepaCreditorIdentifier">
        {({ value, onChange, error }) => (
          <FieldsListener names={["useSwanSepaCreditorIdentifier"]}>
            {({ useSwanSepaCreditorIdentifier }) =>
              useSwanSepaCreditorIdentifier.value === true ? null : (
                <LakeTextInput
                  error={!useSwanSepaCreditorIdentifier.value ? error : undefined}
                  value={value}
                  onChangeText={onChange}
                />
              )
            }
          </FieldsListener>
        )}
      </Field>

      <LakeButtonGroup paddingBottom={0}>
        <TrackPressable action="Cancel merchant payment method">
          <LakeButton
            mode="secondary"
            color="gray"
            onPress={() => {
              closeSddPaymentMethodModal();
              resetForm();
            }}
            grow={true}
          >
            {t("common.cancel")}
          </LakeButton>
        </TrackPressable>

        <TrackPressable action="Save merchant payment method">
          <LakeButton
            grow={true}
            loading={requestMerchantPaymentMethodsData.isLoading()}
            color="current"
            onPress={() => {
              onSubmit();
            }}
          >
            {t("merchantPaymentMethods.confirm")}
          </LakeButton>
        </TrackPressable>
      </LakeButtonGroup>
    </LakeModal>
  );
};

export const ConfirmPaymentMethodModal = ({
  paymentMethod,
  merchantProfileId,
  requestConfirmPaymentMethodVisible,
  closeConfirmPaymentMethodModal,
}: ConfirmPaymentMethodModalProps) => {
  const [requestMerchantPaymentMethods, requestMerchantPaymentMethodsData] = useMutation(
    RequestMerchantPaymentMethodsDocument,
  );

  const onSubmit = useCallback(() => {
    return requestMerchantPaymentMethods({
      input: {
        merchantProfileId,
        ...match(paymentMethod)
          .with({ name: "Check" }, () => {
            return {
              check: {
                activate: true,
              },
            };
          })
          .with({ name: "InternalDirectDebitB2b" }, () => {
            return {
              internalDirectDebitB2B: {
                activate: true,
              },
            };
          })
          .with({ name: "InternalDirectDebitStandard" }, () => {
            return {
              internalDirectDebitStandard: {
                activate: true,
              },
            };
          })
          .with({ name: "Card" }, () => {
            return {
              card: {
                activate: true,
              },
            };
          })
          //SepaDirectDebitB2b and SepaDirectDebitCore specific cases are managed in the SddPaymentMethodModal component
          .otherwise(() => null),
      },
    })
      .mapOk(data => data.requestMerchantPaymentMethods)
      .mapOkToResult(data => (isNotNullish(data) ? Result.Ok(data) : Result.Error(undefined)))
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        closeConfirmPaymentMethodModal();

        showToast({
          variant: "success",
          title: t("toast.success.merchantPaymentMethod.requested"),
        });
      })
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  }, [
    merchantProfileId,
    paymentMethod,
    closeConfirmPaymentMethodModal,
    requestMerchantPaymentMethods,
  ]);

  return (
    <LakeModal
      visible={requestConfirmPaymentMethodVisible}
      title={t("merchantPaymentMethods.confirmModal")}
    >
      <Space height={20} />
      <LakeText>{t("merchantPaymentMethods.confirmModal.description")}</LakeText>
      <Space height={16} />

      <LakeButtonGroup paddingBottom={0}>
        <TrackPressable action="Cancel merchant payment method">
          <LakeButton
            mode="secondary"
            color="gray"
            onPress={() => {
              closeConfirmPaymentMethodModal();
            }}
            grow={true}
          >
            {t("common.cancel")}
          </LakeButton>
        </TrackPressable>

        <TrackPressable action="Save merchant payment method">
          <LakeButton
            grow={true}
            loading={requestMerchantPaymentMethodsData.isLoading()}
            color="current"
            onPress={() => {
              onSubmit();
            }}
          >
            {t("merchantPaymentMethods.confirmRequest")}
          </LakeButton>
        </TrackPressable>
      </LakeButtonGroup>
    </LakeModal>
  );
};
