import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { MultiSelect } from "@swan-io/lake/src/components/MultiSelect";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { pick } from "@swan-io/lake/src/utils/object";
import { useForm } from "@swan-io/use-form";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import {
  IdentificationInvalidReason,
  IdentificationProcess,
  SwanIdentificationStatus,
} from "../../graphql/partner";
import { UpdateSandboxIdentificationDocument } from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";
import { errorReasons, levels, process } from "./CreateSandboxUserIdentificationPage";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

export const UpdateSandboxUserIdentification = () => {
  const [simulate, simulation] = useMutation(UpdateSandboxIdentificationDocument);

  const result = simulation.mapOkToResult(simulation =>
    match(simulation.updateSandboxIdentification)
      .with(
        { __typename: "UpdateSandboxIdentificationSuccessPayload" },
        ({ sandboxIdentification: { id } }) => Result.Ok([{ key: "identificationId", value: id }]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const { Field, FieldsListener, submitForm, formStatus } = useForm<{
    identificationId: string;
    process: IdentificationProcess;
    expert: SwanIdentificationStatus;
    qes: SwanIdentificationStatus | undefined;
    pvid: SwanIdentificationStatus | undefined;
    errorReasons: IdentificationInvalidReason[] | undefined;
  }>({
    identificationId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    process: {
      initialValue: "Expert",
      strategy: "onBlur",
    },
    expert: {
      initialValue: "NotStarted",
    },
    qes: {
      initialValue: "NotStarted",
    },
    pvid: {
      initialValue: "NotStarted",
    },
    errorReasons: {
      initialValue: [],
    },
  });

  const onSubmit = () =>
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(pick(values, ["identificationId", "process", "expert"]));

        if (option.isSome()) {
          const { identificationId, process, expert } = option.get();
          const qes = values.qes.toUndefined();
          const errorReasons = values.errorReasons.toUndefined();
          const pvid = values.pvid.toUndefined();

          return simulate({
            input: {
              identificationId,
              process,
              levels: {
                expert,
                qes,
                pvid,
              },
              errorReasons,
            },
          });
        }
      },
    });

  return (
    <Form style={commonStyles.fill}>
      <Tile>
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="identificationId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.identificationId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.identificationIdPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="process">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.process")}
                render={id => (
                  <LakeSelect id={id} value={value} items={process} onValueChange={onChange} />
                )}
              />
            )}
          </Field>

          <Field name="expert">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.expert")}
                render={id => (
                  <LakeSelect id={id} value={value} items={levels} onValueChange={onChange} />
                )}
              />
            )}
          </Field>

          <>
            <FieldsListener names={["process"]}>
              {({ process }) =>
                process.value === "QES" ? (
                  <Field name="qes">
                    {({ value, onChange }) => (
                      <LakeLabel
                        label={t("simulatorForm.qes")}
                        render={id => (
                          <LakeSelect
                            id={id}
                            value={value}
                            items={levels}
                            onValueChange={onChange}
                          />
                        )}
                      />
                    )}
                  </Field>
                ) : null
              }
            </FieldsListener>

            <FieldsListener names={["process"]}>
              {({ process }) =>
                process.value === "PVID" ? (
                  <Field name="pvid">
                    {({ value, onChange }) => (
                      <LakeLabel
                        label={t("simulatorForm.pvid")}
                        render={id => (
                          <LakeSelect
                            id={id}
                            value={value}
                            items={levels}
                            onValueChange={onChange}
                          />
                        )}
                      />
                    )}
                  </Field>
                ) : null
              }
            </FieldsListener>
          </>

          <FieldsListener names={["expert", "qes", "pvid"]}>
            {({ expert, qes, pvid }) =>
              [expert.value, qes.value, pvid.value].some(item => item === "Invalid") ? (
                <Field name="errorReasons">
                  {({ value, onChange }) => (
                    <LakeLabel
                      label={t("simulatorForm.errorReasons")}
                      render={id => (
                        <MultiSelect
                          id={id}
                          color="current"
                          emptyResultText={t("common.noResult")}
                          filterPlaceholder={t("common.search.placeholder")}
                          items={errorReasons}
                          onValueChange={x => onChange(x as IdentificationInvalidReason[])}
                          values={value ?? []}
                        />
                      )}
                    />
                  )}
                </Field>
              ) : null
            }
          </FieldsListener>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit physical card delivery form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          results={result}
          fields={[
            {
              key: "identificationId",
              label: t("simulatorForm.identificationId"),
              placeholder: "-",
            },
          ]}
        />
      </Box>
    </Form>
  );
};
