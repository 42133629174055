import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { colors } from "@swan-io/lake/src/constants/design";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { BrandingSettingsForm } from "../components/BrandingSettingsForm";
import {
  CancelPendingBrandingSettingsDocument,
  GetPendingBrandingPageDocument,
} from "../graphql/admin";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  emptyContainer: {
    alignItems: "center",
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: "center",
  },
  button: {
    width: 170,
  },
});

export const BrandingPendingTab = () => {
  const { projectId, projectEnv } = useProjectInfo();
  const canEditBranding = usePermissions(projectEnv).settingsBranding.write;

  const [data, { reload }] = useQuery(GetPendingBrandingPageDocument, { projectId });

  const [cancelPendingBranding, cancellation] = useMutation(CancelPendingBrandingSettingsDocument);

  const onCancel = () => {
    cancelPendingBranding({ input: { projectId } })
      .mapOk(data => data.cancelPendingProjectBrandingSettings)
      .mapOkToResult(data => Option.fromNullable(data).toResult(new Error("Empty")))
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        reload();
        showToast({ variant: "success", title: t("toast.success.brandingCancel") });
      })
      .tapError((error: unknown) => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ project }) => {
      const defaultValues = {
        projectName: project.pendingProjectBrandingSettings?.name,
        // It's currently typed as "any".
        logoUri: project.pendingProjectBrandingSettings?.logoUri as string,
        accentColor: project.pendingProjectBrandingSettings?.accentColor,
      };

      const hasPendingBranding = isNotNullish(project.pendingProjectBrandingSettings);

      if (!hasPendingBranding) {
        return (
          <View style={styles.emptyContainer}>
            <Icon name="color-regular" size={32} color={colors.current[500]} />
            <LakeText>{t("projectSettings.branding.pending.empty")}</LakeText>
            <LakeText>{t("projectSettings.branding.pending.emptySubtitle")}</LakeText>
          </View>
        );
      }

      return (
        <BrandingSettingsForm
          defaultValues={defaultValues}
          readonly={true}
          onSave={() => {}}
          Header={<LakeAlert variant="info" title={t("projectSettings.branding.pending.info")} />}
          Footer={
            <View style={styles.button}>
              <TrackPressable action="Cancel branding review">
                <LakeTooltip
                  placement="left"
                  content={t("common.action.denied")}
                  disabled={canEditBranding}
                >
                  <LakeButton
                    onPress={onCancel}
                    loading={cancellation.isLoading()}
                    color="negative"
                    disabled={!canEditBranding}
                  >
                    {t("projectSettings.branding.pending.cancelReview")}
                  </LakeButton>
                </LakeTooltip>
              </TrackPressable>
            </View>
          }
        />
      );
    })
    .exhaustive();
};
