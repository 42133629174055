import { Option } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { InformationTooltip, LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Switch } from "@swan-io/lake/src/components/Switch";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors } from "@swan-io/lake/src/constants/design";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useForm } from "@swan-io/use-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { CardDesignEditor } from "../components/CardDesignEditor";
import { ErrorView } from "../components/ErrorView";
import { TrackPressable } from "../components/TrackPressable";
import { LoggedUserInfoFragment } from "../graphql/admin";
import {
  GetCardProductsQuery,
  SuspendCardProductDocument,
  UpdateCardProductDocument,
} from "../graphql/exposed-internal";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { formatCurrency, t } from "../utils/i18n";
import { Router, settingsCardProductsDetailRoots } from "../utils/routes";

type Props = {
  cardProduct: NonNullable<
    NonNullable<GetCardProductsQuery["projectInfo"]["cardProducts"]>[number]
  >;
  onSave: () => void;
  user: LoggedUserInfoFragment;
  canEditCardProducts: boolean;
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  emptyContainer: {
    alignItems: "center",
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: "center",
  },
  contentContainer: {
    flexGrow: 1,
  },
  grows: {
    flexGrow: 1,
  },
});

const SuspendModal = ({
  cardProductId,
  onClose,
  visible,
  canEditCardProducts,
}: {
  cardProductId: string;
  onClose: (shouldReload: boolean) => void;
  visible: boolean;
  canEditCardProducts: boolean;
}) => {
  const [suspendPhysicalCard, suspension] = useMutation(SuspendCardProductDocument);

  const suspendCard = () => {
    suspendPhysicalCard({ input: { id: cardProductId } })
      .mapOk(data => data.suspendCardProduct)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        showToast({ variant: "success", title: t("toast.success.cardProductSuspended") });
        onClose(true);
      })
      .tapError((error: unknown) => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  return (
    <LakeModal
      visible={visible}
      title={t("cardsProduct.options.suspend.title")}
      icon="lock-closed-regular"
      color="warning"
    >
      <LakeText color={colors.gray[900]}>{t("cardsProduct.options.suspend.description")}</LakeText>
      <Space height={32} />

      <Box direction="row">
        <LakeButton style={styles.grows} mode="secondary" onPress={() => onClose(false)}>
          {t("common.cancel")}
        </LakeButton>

        <Space width={24} />

        <TrackPressable action="Suspend card product">
          <LakeTooltip
            placement="left"
            content={t("common.action.denied")}
            disabled={canEditCardProducts}
            containerStyle={commonStyles.fill}
          >
            <LakeButton
              loading={suspension.isLoading()}
              style={styles.grows}
              icon="lock-closed-filled"
              color="warning"
              onPress={() => suspendCard()}
              disabled={!canEditCardProducts}
            >
              {t("cardsProduct.options.suspend.suspend")}
            </LakeButton>
          </LakeTooltip>
        </TrackPressable>
      </Box>
    </LakeModal>
  );
};

const UnsuspendModal = ({ onClose, visible }: { onClose: () => void; visible: boolean }) => (
  <LakeModal
    visible={visible}
    title={t("cardsProduct.options.unsuspend.title")}
    icon="lock-closed-regular"
    color="warning"
    onPressClose={() => onClose()}
  >
    <LakeText color={colors.gray[900]}>{t("cardsProduct.options.unsuspend.description")}</LakeText>
    <Space height={32} />
  </LakeModal>
);

const CardProductOptions = ({ cardProduct, onSave, canEditCardProducts }: Props) => {
  const [updateCardProductOption, cardProductUpdate] = useMutation(UpdateCardProductDocument);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [isUnsuspendModalOpen, setIsUnsuspendModalOpen] = useState(false);
  const { Field, submitForm, listenFields } = useForm({
    name: {
      initialValue:
        cardProduct.name ??
        (cardProduct.defaultCardProduct
          ? t("cardProducts.defaultCardProduct")
          : t("cardProducts.untitledCard")),
      sanitize: value => value?.trim(),
    },
    applicableToPhysicalCards: {
      initialValue: cardProduct.applicableToPhysicalCards,
    },
  });

  const onSubmit = useCallback(() => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isSome()) {
          updateCardProductOption({ input: { id: cardProduct.id, ...option.get() } })
            .mapOk(data => data.updateCardProduct)
            .mapOkToResult(filterRejectionsToResult)
            .tapOk(onSave)
            .tapError(error => {
              showToast({ variant: "error", error, title: translateError(error) });
            });
        }
      },
    });
  }, [submitForm, updateCardProductOption, onSave, cardProduct.id]);

  useEffect(() => {
    return listenFields(["applicableToPhysicalCards"], () => onSubmit());
  }, [listenFields, onSubmit]);

  const loading = cardProductUpdate.isLoading();

  return (
    <View>
      <Tile>
        <LakeLabel
          label={t("cardProducts.options.name")}
          render={id => (
            <Field name="name">
              {({ value, onChange, error }) => (
                <LakeTextInput
                  value={value}
                  onChangeText={onChange}
                  error={error}
                  id={id}
                  placeholder={t("cardProducts.newCardProduct.cardProductNamePlaceholder")}
                  onBlur={onSubmit}
                />
              )}
            </Field>
          )}
        />

        <ReadOnlyFieldList>
          <LakeLabel
            type="view"
            readOnly={true}
            label={t("cardProducts.options.cardProductId")}
            render={() => <LakeText>{cardProduct.id}</LakeText>}
            actions={
              <LakeCopyButton
                valueToCopy={cardProduct.id}
                copyText={t("copyButton.copyTooltip")}
                copiedText={t("copyButton.copiedTooltip")}
              />
            }
          />

          <LakeLabel
            type="view"
            readOnly={true}
            label={t("cardProducts.options.maxSpendingLimitsIndividuals")}
            render={() => (
              <LakeText>
                {t("cardProducts.options.maxSpendingLimits.perMonth", {
                  value: formatCurrency(
                    Number(cardProduct.individualSpendingLimit.amount.value),
                    cardProduct.individualSpendingLimit.amount.currency,
                  ),
                })}
              </LakeText>
            )}
            actions={
              <InformationTooltip text={t("cardProducts.options.maxSpendingLimits.informations")} />
            }
          />

          <LakeLabel
            type="view"
            readOnly={true}
            label={t("cardProducts.options.maxSpendingLimitsCompanies")}
            render={() => (
              <LakeText>
                {t("cardProducts.options.maxSpendingLimits.perMonth", {
                  value: formatCurrency(
                    Number(cardProduct.companySpendingLimit.amount.value),
                    cardProduct.companySpendingLimit.amount.currency,
                  ),
                })}
              </LakeText>
            )}
            actions={
              <InformationTooltip text={t("cardProducts.options.maxSpendingLimits.informations")} />
            }
          />
        </ReadOnlyFieldList>

        <Space height={16} />

        <Field name="applicableToPhysicalCards">
          {({ value, onChange }) => (
            <Box direction="row" alignItems="center">
              <Switch disabled={loading} value={value} onValueChange={onChange} />
              <Space width={12} />

              <TrackPressable action="Allow physical cards">
                <Pressable onPress={() => onChange(!value)}>
                  <LakeText color={colors.gray[700]}>
                    {t("cardProducts.newCardProduct.allowPhysicalCards")}
                  </LakeText>
                </Pressable>
              </TrackPressable>
            </Box>
          )}
        </Field>
      </Tile>

      <SuspendModal
        canEditCardProducts={canEditCardProducts}
        visible={isSuspendModalOpen}
        cardProductId={cardProduct.id}
        onClose={shouldReload => {
          if (shouldReload) {
            onSave();
          }
          setIsSuspendModalOpen(false);
        }}
      />

      <UnsuspendModal
        visible={isUnsuspendModalOpen}
        onClose={() => {
          setIsUnsuspendModalOpen(false);
        }}
      />

      <LakeButtonGroup>
        {cardProduct.status === "Suspended" ? (
          <TrackPressable action="Unsuspend card product">
            <LakeTooltip
              placement="left"
              content={t("common.action.denied")}
              disabled={canEditCardProducts}
            >
              <LakeButton
                color="warning"
                mode="secondary"
                onPress={() => setIsUnsuspendModalOpen(true)}
                icon="dismiss-regular"
                disabled={!canEditCardProducts}
              >
                {t("cardProducts.options.unsuspendCardProduct")}
              </LakeButton>
            </LakeTooltip>
          </TrackPressable>
        ) : (
          <TrackPressable action="Suspend card product">
            <LakeTooltip
              placement="left"
              content={t("common.action.denied")}
              disabled={canEditCardProducts}
            >
              <LakeButton
                color="warning"
                mode="secondary"
                onPress={() => setIsSuspendModalOpen(true)}
                icon="dismiss-regular"
                disabled={!canEditCardProducts}
              >
                {t("cardProducts.options.suspendCardProduct")}
              </LakeButton>
            </LakeTooltip>
          </TrackPressable>
        )}
      </LakeButtonGroup>
    </View>
  );
};

export const CardProductsDetailPage = ({
  canEditCardProducts,
  cardProduct,
  user,
  onSave,
}: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const route = Router.useRoute(settingsCardProductsDetailRoots);

  useCrumb(
    useMemo(
      () => ({
        label: cardProduct.name ?? t("cardProducts.untitledCard"),
        link:
          projectEnv === "live"
            ? Router.LiveSettingsCardProductsDetailDraft({
                projectId,
                cardProductId: cardProduct.id,
              })
            : Router.SandboxSettingsCardProductsDetailCurrent({
                projectId,
                cardProductId: cardProduct.id,
              }),
      }),
      [projectEnv, projectId, cardProduct],
    ),
  );

  const tabs = useMemo(() => {
    return [
      ...(projectEnv === "live"
        ? [
            {
              url: Router.LiveSettingsCardProductsDetailDraft({
                projectId,
                cardProductId: cardProduct.id,
              }),
              label: t("cardProducts.cardDesign.draft"),
              icon: "edit-regular",
            } as const,
            {
              url: Router.LiveSettingsCardProductsDetailPending({
                projectId,
                cardProductId: cardProduct.id,
              }),
              label: t("cardProducts.cardDesign.pending"),
              icon: "clock-regular",
            } as const,
          ]
        : []),
      ...(projectEnv === "live"
        ? [
            {
              url: Router.LiveSettingsCardProductsDetailCurrent({
                projectId,
                cardProductId: cardProduct.id,
              }),
              label: t("cardProducts.cardDesign.current"),
              icon: "live-regular",
            } as const,
          ]
        : [
            {
              url: Router.SandboxSettingsCardProductsDetailCurrent({
                projectId,
                cardProductId: cardProduct.id,
              }),
              label: t("cardProducts.cardDesign.current"),
              icon: "live-regular",
            } as const,
          ]),
      {
        url: Router.SettingsCardProductsDetailOptions({
          projectId,
          projectEnv,
          cardProductId: cardProduct.id,
        }),
        label: t("cardProducts.cardDesign.options"),
        icon: "options-regular",
        withSeparator: true,
      } as const,
    ];
  }, [cardProduct, projectId, projectEnv]);

  return (
    <View style={styles.container}>
      <TabView otherLabel={t("common.tabs.other")} tabs={tabs} hideIfSingleItem={false} />
      <Space height={24} />

      <LakeScrollView contentContainerStyle={styles.contentContainer}>
        {match(route)
          .with({ name: "LiveSettingsCardProductsDetailDraft" }, () => {
            const cardDesign =
              cardProduct.cardDesigns.find(item => item.status === "Draft") ??
              cardProduct.cardDesigns.find(item => item.status === "ToReview") ??
              cardProduct.cardDesigns.find(item => item.status === "Enabled") ??
              cardProduct.cardDesigns.find(item => item.status === "Disabled");

            if (cardDesign == null) {
              // not supposed to happen
              return <ErrorView />;
            } else {
              return (
                <CardDesignEditor
                  key="draft"
                  firstName={user.firstName ?? ""}
                  lastName={user.lastName ?? ""}
                  cardDesign={cardDesign}
                  cardProductId={cardProduct.id}
                  canEditCardProducts={canEditCardProducts}
                  onSave={() => {
                    onSave();
                    if (projectEnv === "live") {
                      Router.push("LiveSettingsCardProductsDetailPending", {
                        projectId,
                        cardProductId: cardProduct.id,
                      });
                    }
                  }}
                />
              );
            }
          })
          .with({ name: "LiveSettingsCardProductsDetailPending" }, () => {
            const cardDesign = cardProduct.cardDesigns.find(item => item.status === "ToReview");

            if (cardDesign == null) {
              return (
                <View style={styles.emptyContainer}>
                  <Icon name="payment-regular" size={32} color={colors.current[500]} />
                  <LakeText>{t("cardProducts.pending.empty")}</LakeText>
                  <LakeText>{t("cardProducts.pending.emptySubtitle")}</LakeText>
                </View>
              );
            } else {
              return (
                <CardDesignEditor
                  key="pending"
                  firstName={user.firstName ?? undefined}
                  lastName={user.lastName ?? undefined}
                  readOnly={true}
                  cardDesign={cardDesign}
                  cardProductId={cardProduct.id}
                  canEditCardProducts={canEditCardProducts}
                />
              );
            }
          })
          .with(
            { name: "LiveSettingsCardProductsDetailCurrent" },
            { name: "SandboxSettingsCardProductsDetailCurrent" },
            ({ name }) => {
              const cardDesign =
                name === "SandboxSettingsCardProductsDetailCurrent"
                  ? (cardProduct.cardDesigns.find(item => item.status === "Draft") ??
                    cardProduct.cardDesigns.find(item => item.status === "ToReview") ??
                    cardProduct.cardDesigns.find(item => item.status === "Enabled") ??
                    cardProduct.cardDesigns.find(item => item.status === "Disabled"))
                  : (cardProduct.cardDesigns.find(item => item.status === "Enabled") ??
                    (cardProduct.defaultCardProduct
                      ? cardProduct.cardDesigns.find(
                          item => item.status === "Draft" && item.version === 1,
                        )
                      : null));

              if (cardDesign == null) {
                return (
                  <View style={styles.emptyContainer}>
                    <Icon name="payment-regular" size={32} color={colors.current[500]} />
                    <LakeText>{t("cardProducts.current.empty")}</LakeText>
                    <LakeText>{t("cardProducts.current.emptySubtitle")}</LakeText>
                  </View>
                );
              } else {
                return (
                  <CardDesignEditor
                    key="current"
                    firstName={user.firstName ?? undefined}
                    lastName={user.lastName ?? undefined}
                    cardDesign={cardDesign}
                    cardProductId={cardProduct.id}
                    readOnly={name !== "SandboxSettingsCardProductsDetailCurrent"}
                    canEditCardProducts={canEditCardProducts}
                  />
                );
              }
            },
          )
          .with({ name: "SettingsCardProductsDetailOptions" }, () => (
            <CardProductOptions
              user={user}
              cardProduct={cardProduct}
              onSave={onSave}
              canEditCardProducts={canEditCardProducts}
            />
          ))
          .otherwise(() => (
            <ErrorView />
          ))}
      </LakeScrollView>
    </View>
  );
};
