import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { FixedListViewPlaceholder } from "@swan-io/lake/src/components/FixedListView";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { isNotNullish, isNullish } from "@swan-io/lake/src/utils/nullish";
import { useMemo } from "react";
import { View } from "react-native";
import { P, match } from "ts-pattern";
import { GetCapitalDepositDocument } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router, capitalDepositDetailRoutes } from "../utils/routes";
import { CapitalDepositDetailGeneral } from "./CapitalDepositDetailGeneral";
import { CapitalDepositShareholderArea } from "./CapitalDepositShareholderArea";
import { CapitalDepositShareholdersList } from "./CapitalDepositShareholdersList";
import { ErrorView } from "./ErrorView";

type Props = {
  capitalDepositId: string;
};

export const CapitalDepositDetailArea = ({ capitalDepositId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(capitalDepositDetailRoutes);

  const [capitalDeposit] = useQuery(GetCapitalDepositDocument, { id: capitalDepositId });

  const companyName = capitalDeposit
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(({ capitalDepositCase }) => Option.fromNullable(capitalDepositCase?.companyName))
    .getOr("");

  useCrumb(
    useMemo(
      () =>
        isNotNullish(capitalDeposit)
          ? {
              label: companyName,
              link: Router.CapitalDepositDetailRoot({
                projectId,
                projectEnv,
                capitalDepositId,
              }),
            }
          : undefined,
      [capitalDeposit, capitalDepositId, companyName, projectEnv, projectId],
    ),
  );

  const shouldRedirect = match(capitalDeposit)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ capitalDepositCase }) =>
      isNullish(capitalDepositCase),
    )
    .otherwise(() => false);

  if (shouldRedirect) {
    Router.push("CapitalDepositList", { projectId, projectEnv });
    return null;
  }

  const tabs = (
    <TabView
      tabs={[
        {
          label: t("capitalDeposit.tabs.general"),
          url: Router.CapitalDepositDetailRoot({ projectId, projectEnv, capitalDepositId }),
        },
        {
          label: t("capitalDeposit.tabs.shareholders"),
          url: Router.CapitalDepositDetailShareholdersList({
            projectId,
            projectEnv,
            capitalDepositId,
          }),
        },
      ]}
      otherLabel={t("common.tabs.other")}
    />
  );

  return match(route)
    .with({ name: "CapitalDepositDetailRoot" }, () => (
      <>
        {tabs}

        <Space height={24} />

        {match(capitalDeposit.mapOk(data => data.capitalDepositCase))
          .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
            <TileGridPlaceholder withTabs={false} />
          ))
          .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
          .with(AsyncData.P.Done(Result.P.Ok(P.nullish)), () => <NotFoundPage />)
          .with(AsyncData.P.Done(Result.P.Ok(P.select(P.nonNullable))), capitalDepositCase => {
            return (
              <LakeScrollView>
                <CapitalDepositDetailGeneral capitalDeposit={capitalDepositCase} />
              </LakeScrollView>
            );
          })
          .exhaustive()}
      </>
    ))
    .with({ name: "CapitalDepositDetailShareholdersList" }, () => (
      <View style={commonStyles.fill}>
        {tabs}

        <Space height={24} />

        {match(capitalDeposit.mapOk(data => data.capitalDepositCase))
          .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
            <FixedListViewPlaceholder
              headerHeight={48}
              rowHeight={48}
              rowVerticalSpacing={4}
              count={20}
            />
          ))
          .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
          .with(AsyncData.P.Done(Result.P.Ok(P.nullish)), () => <NotFoundPage />)
          .with(AsyncData.P.Done(Result.P.Ok(P.select(P.nonNullable))), capitalDepositCase => {
            return (
              <CapitalDepositShareholdersList
                shareholders={capitalDepositCase.shareholders}
                extraInfo={{
                  projectEnv,
                  projectId,
                  capitalDepositId,
                }}
              />
            );
          })
          .exhaustive()}
      </View>
    ))
    .with({ name: "CapitalDepositDetailShareholderArea" }, ({ params }) => (
      <CapitalDepositShareholderArea
        shareholderId={params.shareholderId}
        capitalDepositId={capitalDepositId}
      />
    ))
    .otherwise(() => <NotFoundPage />);
};
