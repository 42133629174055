import { encodeSearch, pushUnsafe, useLocation } from "@swan-io/chicane";
import { FixedListViewEmpty } from "@swan-io/lake/src/components/FixedListView";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { StyleSheet, View } from "react-native";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: "center",
    maxWidth: 600,
    margin: "auto",
  },
});

export const LiveAccessGate = () => {
  const {
    raw: { path },
    search,
  } = useLocation();
  const { projectEnv } = useProjectInfo();
  const canActivateProject = usePermissions(projectEnv).projectStatusManagement.write;

  return (
    <View style={styles.container}>
      <FixedListViewEmpty
        icon="live-regular"
        title={t("activate.projectNotLive")}
        subtitle={t("activate.projectNotLiveSuggestion")}
      >
        <LakeButtonGroup justifyContent="center">
          <TrackPressable action="Activate">
            <LakeTooltip
              placement="center"
              content={t("common.action.denied")}
              disabled={canActivateProject}
            >
              <LakeButton
                size="small"
                icon="flash-filled"
                onPress={() => {
                  pushUnsafe(`${path}${encodeSearch({ ...search, activate: "true" })}`);
                }}
                color="swan"
                disabled={!canActivateProject}
              >
                {t("header.activate")}
              </LakeButton>
            </LakeTooltip>
          </TrackPressable>
        </LakeButtonGroup>
      </FixedListViewEmpty>
    </View>
  );
};
