import { Array, Option, Result } from "@swan-io/boxed";
import { Link } from "@swan-io/chicane";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { colors } from "@swan-io/lake/src/constants/design";

import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import {
  isNotNullish,
  isNotNullishOrEmpty,
  isNullish,
  isNullishOrEmpty,
} from "@swan-io/lake/src/utils/nullish";
import { Request, badStatusToError } from "@swan-io/request";
import {
  Document,
  SupportingDocumentCollection,
} from "@swan-io/shared-business/src/components/SupportingDocumentCollection";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import dayjs from "dayjs";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import {
  CapitalDepositDocumentType,
  GenerateCapitalDepositDocumentUploadUrlDocument,
  GetCapitalDepositQuery,
  SupportingDocumentCollectionStatus,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { formatCurrency, locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { getCapitalDepositDocumentRejectionReason } from "../utils/templateTranslations";

type Props = {
  capitalDeposit: NonNullable<GetCapitalDepositQuery["capitalDepositCase"]>;
};

const styles = StyleSheet.create({
  unknownValue: {
    fontStyle: "italic",
  },
});

const UNKNOWN_VALUE = <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>;

const readonlyPurposes: CapitalDepositDocumentType[] = [
  "CapitalShareDepositCertificate",
  "RegisterExtract",
];

export const CapitalDepositDetailGeneral = ({ capitalDeposit }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const [generateCapitalDepositDocument] = useMutation(
    GenerateCapitalDepositDocumentUploadUrlDocument,
  );

  const hasShareholderWithoutCapitalTransferredStatus = capitalDeposit.shareholders.some(
    ({ status }) => status !== "CapitalTransferred",
  );

  const documentsWithPendingStatus = capitalDeposit.documents.filter(
    ({ statusInfo: { status } }) => status === "Pending",
  ).length;

  const messagesIfWaitingForRequirements: string[] = [];
  if (hasShareholderWithoutCapitalTransferredStatus) {
    messagesIfWaitingForRequirements.push(
      t("capitalDeposit.alert.shareholdersMustFinalizeOnboarding"),
    );
  }
  if (isNullish(capitalDeposit.companyAccountId)) {
    messagesIfWaitingForRequirements.push(t("capitalDeposit.alert.onboardingToFinalize"));
  }
  if (documentsWithPendingStatus > 2) {
    messagesIfWaitingForRequirements.push(t("capitalDeposit.alert.pendingStatus"));
  }

  const docs = Array.filterMap(capitalDeposit.documents, document =>
    match(document)
      .returnType<
        Option<
          Document<CapitalDepositDocumentType> & {
            metadata: {
              updatedAt: string;
              createdAt: string;
              uploadedAt: string | null | undefined;
            };
          }
        >
      >()
      .with(P.nullish, () => Option.None())
      .with({ statusInfo: { __typename: "CapitalDepositDocumentPendingStatusInfo" } }, document =>
        Option.Some({
          purpose: document.type,
          metadata: {
            updatedAt: document.updatedAt,
            createdAt: document.createdAt,
            uploadedAt: document.uploadedAt,
          },
          file: {
            id: document.id,
            name: t("common.document"),
            url: document.downloadUrl ?? "",
            statusInfo: {
              status: "Pending",
            },
          },
        }),
      )
      .with({ statusInfo: { __typename: "CapitalDepositDocumentUploadedStatusInfo" } }, document =>
        Option.Some({
          purpose: document.type,
          metadata: {
            updatedAt: document.updatedAt,
            createdAt: document.createdAt,
            uploadedAt: document.uploadedAt,
          },
          file: {
            id: document.id,
            name: t("common.document"),
            url: document.downloadUrl ?? "",
            statusInfo: {
              status: "Uploaded",
            },
          },
        }),
      )
      .with({ statusInfo: { __typename: "CapitalDepositDocumentRefusedStatusInfo" } }, document =>
        Option.Some({
          purpose: document.type,
          metadata: {
            updatedAt: document.updatedAt,
            createdAt: document.createdAt,
            uploadedAt: document.uploadedAt,
          },
          file: {
            id: document.id,
            name: t("common.document"),
            url: document.downloadUrl ?? "",
            statusInfo: {
              status: "Refused",
              reason: getCapitalDepositDocumentRejectionReason(document.statusInfo.reasonCode),
            },
          },
        }),
      )
      .with({ statusInfo: { __typename: "CapitalDepositDocumentValidatedStatusInfo" } }, document =>
        Option.Some({
          purpose: document.type,
          metadata: {
            updatedAt: document.updatedAt,
            createdAt: document.createdAt,
            uploadedAt: document.uploadedAt,
          },
          file: {
            id: document.id,
            name: t("common.document"),
            url: document.downloadUrl ?? "",
            statusInfo: {
              status: "Validated",
            },
          },
        }),
      )
      .exhaustive(),
  );

  const hasPendingDocs =
    docs.filter(
      ({
        file: {
          statusInfo: { status },
        },
      }) => status !== "Validated",
    ).length > 0;

  return (
    <>
      {match(capitalDeposit)
        .with({ status: "WaitingForRequirements" }, () => {
          return (
            <>
              <LakeAlert title={t("capitalDeposit.alert.waitingForRequirement")} variant="warning">
                <Box>
                  <LakeText color={colors.gray[500]} variant="regular">
                    {messagesIfWaitingForRequirements.join("\n")}
                  </LakeText>
                </Box>
              </LakeAlert>

              <Space height={24} />
            </>
          );
        })
        .with({ status: "WaitingForRegisterExtract" }, () => (
          <>
            <LakeAlert
              title={t("capitalDeposit.alert.waitingForRegisterExtract")}
              variant="warning"
            />

            <Space height={24} />
          </>
        ))
        .with({ status: "WaitingForShareDepositCertificate" }, () => (
          <>
            <LakeAlert
              title={t("capitalDeposit.alert.waitingForShareDepositCertificate")}
              variant="info"
            />

            <Space height={24} />
          </>
        ))
        .with({ status: "WaitingForRegisterExtract" }, () => (
          <>
            <LakeAlert title={t("capitalDeposit.alert.waitingForRegisterExtract")} variant="info" />
            <Space height={24} />
          </>
        ))
        .with({ status: "WaitingForNotaryTransfer" }, () => (
          <>
            <LakeAlert title={t("capitalDeposit.alert.waitingForNotaryTransfer")} variant="info" />
            <Space height={24} />
          </>
        ))
        .with({ status: "Completed" }, () => (
          <>
            <LakeAlert title={t("capitalDeposit.alert.completed")} variant="success" />
            <Space height={24} />
          </>
        ))
        .otherwise(() => null)}

      <TileGrid>
        <Tile title={t("capitalDeposit.capitalDeposit")}>
          <ReadOnlyFieldList>
            <LakeLabel
              label={t("capitalDeposit.companyName")}
              type="view"
              color="current"
              render={() => (
                <LakeText color={colors.gray[900]}>{capitalDeposit.companyName}</LakeText>
              )}
            />

            <LakeLabel
              label={t("capitalDeposit.status")}
              type="view"
              color="current"
              render={() =>
                match(capitalDeposit.status)
                  .with("Canceled", () => (
                    <Tag color="positive">{t("capitalDeposit.status.canceled")}</Tag>
                  ))
                  .with("Completed", () => (
                    <Tag color="positive">{t("capitalDeposit.status.completed")}</Tag>
                  ))
                  .with("WaitingForRequirements", () => (
                    <Tag color="gray">{t("capitalDeposit.status.waitingForRequirements")}</Tag>
                  ))
                  .with("Initiated", () => (
                    <Tag color="negative">{t("capitalDeposit.status.initiated")}</Tag>
                  ))
                  .with("WaitingForShareDepositCertificate", () => (
                    <Tag color="warning">
                      {t("capitalDeposit.status.waitingForShareDepositCertificate")}
                    </Tag>
                  ))
                  .with("WaitingForRegisterExtract", () => (
                    <Tag color="shakespear">
                      {t("capitalDeposit.status.waitingForRegisterExtract")}
                    </Tag>
                  ))
                  .with("WaitingForNotaryTransfer", () => (
                    <Tag color="warning">{t("capitalDeposit.status.waitingForNotaryTransfer")}</Tag>
                  ))
                  .exhaustive()
              }
            />

            <LakeLabel
              label={t("capitalDeposit.id")}
              type="view"
              color="current"
              render={() => <LakeText color={colors.gray[900]}>{capitalDeposit.id}</LakeText>}
              actions={
                <LakeCopyButton
                  valueToCopy={capitalDeposit.id}
                  copyText={t("copyButton.copyTooltip")}
                  copiedText={t("copyButton.copiedTooltip")}
                />
              }
            />

            <LakeLabel
              label={t("capitalDeposit.companyAccountId")}
              type="view"
              color="current"
              actions={
                isNotNullish(capitalDeposit.companyAccountId) && (
                  <Link
                    to={Router.AccountDetailRoot({
                      projectId,
                      projectEnv,
                      accountId: capitalDeposit.companyAccountId,
                    })}
                  >
                    <Icon size={20} name="arrow-right-filled" color={colors.swan[900]} />
                  </Link>
                )
              }
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {capitalDeposit.companyAccountId ?? UNKNOWN_VALUE}
                </LakeText>
              )}
            />

            <LakeLabel
              label={t("capitalDeposit.amount")}
              type="view"
              color="current"
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {capitalDeposit.totalCapitalDepositAmount.value !== ""
                    ? formatCurrency(
                        Number(capitalDeposit.totalCapitalDepositAmount.value),
                        capitalDeposit.totalCapitalDepositAmount.currency,
                      )
                    : UNKNOWN_VALUE}
                </LakeText>
              )}
            />

            <LakeLabel
              label={t("capitalDeposit.createdAt")}
              type="view"
              color="current"
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {dayjs(capitalDeposit.createdAt).format(
                    `${locale.dateFormat} ${locale.timeFormat}`,
                  )}
                </LakeText>
              )}
            />

            <LakeLabel
              label={t("capitalDeposit.updatedAt")}
              type="view"
              color="current"
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {dayjs(capitalDeposit.updatedAt).format(
                    `${locale.dateFormat} ${locale.timeFormat}`,
                  )}
                </LakeText>
              )}
            />
          </ReadOnlyFieldList>
        </Tile>

        <Tile title={t("capitalDeposit.documents")}>
          <SupportingDocumentCollection
            status={match({ capitalDepositStatus: capitalDeposit.status, hasPendingDocs })
              .returnType<SupportingDocumentCollectionStatus>()
              .with({ capitalDepositStatus: "Canceled" }, () => "Canceled")
              .with({ hasPendingDocs: true }, () => "WaitingForDocument")
              .otherwise(() => "Approved")}
            uploadFile={({ upload, file }) =>
              Request.make({
                url: upload.url,
                method: "PUT",
                headers: {
                  "content-type": "multipart/form-data",
                },
                body: file,
              })
                .mapOkToResult(badStatusToError)
                .tapOk(() =>
                  showToast({
                    variant: "success",
                    title: t("capitalDeposit.documentUploaded"),
                  }),
                )
                .tapError(error => {
                  showToast({
                    variant: "error",
                    error,
                    title: translateError(error),
                  });
                })
            }
            generateUpload={({
              fileName,
              purpose,
            }: {
              fileName: string;
              purpose: CapitalDepositDocumentType;
            }) => {
              const documentId = docs.find(doc => doc.purpose === purpose)?.file.id ?? "";

              return generateCapitalDepositDocument({
                input: {
                  filename: fileName,
                  capitalDepositCaseId: capitalDeposit.id,
                  documentId,
                },
              })
                .mapOk(data => data.generateCapitalDepositDocumentUploadUrl)
                .mapOkToResult(input =>
                  match(input)
                    .with({ __typename: "CapitalDepositDocumentCanNotBeUploaded" }, () =>
                      Result.Error(input),
                    )
                    .otherwise(() => filterRejectionsToResult(input)),
                )
                .mapOk(input =>
                  match(input)
                    .with(
                      { __typename: "GenerateCapitalDepositDocumentUploadUrlSuccessPayload" },
                      ({ uploadUrl }) => ({
                        upload: { url: uploadUrl ?? "", fields: [] },
                        id: documentId,
                      }),
                    )
                    .otherwise(() => ({
                      upload: { url: "", fields: [] },
                      id: documentId,
                    })),
                )
                .mapError(error =>
                  showToast({
                    variant: "error",
                    error,
                    title: translateError(error),
                  }),
                );
            }}
            getPurposeMetadata={purpose => {
              const doc = docs.find(({ purpose: p }) => p === purpose);
              const file = doc?.file;
              const values = (
                [
                  isNotNullishOrEmpty(file?.id)
                    ? {
                        type: "copy",
                        title: t("capitalDeposit.documents.id"),
                        value: file.id,
                      }
                    : undefined,
                  isNotNullishOrEmpty(doc?.metadata.updatedAt)
                    ? {
                        type: "text",
                        title: t("capitalDeposit.documents.updatedAt"),
                        value: dayjs(doc?.metadata.updatedAt).format(
                          `${locale.dateFormat} ${locale.timeFormat}`,
                        ),
                      }
                    : undefined,
                  isNotNullishOrEmpty(doc?.metadata.createdAt)
                    ? {
                        type: "text",
                        title: t("capitalDeposit.documents.createdAt"),
                        value: dayjs(doc?.metadata.createdAt).format(
                          `${locale.dateFormat} ${locale.timeFormat}`,
                        ),
                      }
                    : undefined,
                  isNotNullishOrEmpty(doc?.metadata.uploadedAt)
                    ? {
                        type: "text",
                        title: t("capitalDeposit.documents.uploadedAt"),
                        value: dayjs(doc?.metadata.uploadedAt).format(
                          `${locale.dateFormat} ${locale.timeFormat}`,
                        ),
                      }
                    : undefined,
                  isNotNullishOrEmpty(file?.statusInfo.status)
                    ? {
                        type: "text",
                        title: t("capitalDeposit.documents.status"),
                        value: match(file.statusInfo.status)
                          .with("Pending", status => <Tag color="shakespear">{status}</Tag>)
                          .with("Refused", status => <Tag color="negative">{status}</Tag>)
                          .with("Uploaded", status => <Tag color="warning">{status}</Tag>)
                          .with("Uploading", status => <Tag color="gray">{status}</Tag>)
                          .with("Validated", status => <Tag color="positive">{status}</Tag>)
                          .exhaustive(),
                      }
                    : undefined,
                ] as {
                  type?: "text" | "copy";
                  title: string;
                  value: string;
                }[]
              ).filter(isNotNullish);

              if (isNotNullishOrEmpty(file?.id)) {
                return {
                  title: t("capitalDeposit.documents.showDetails"),
                  values,
                };
              }
            }}
            documents={docs.filter(({ file: { url } }) => isNotNullishOrEmpty(url))}
            readonlyDocumentPurposes={readonlyPurposes}
            requiredDocumentPurposes={docs
              .filter(({ file: { url } }) => isNullishOrEmpty(url))
              .map(({ purpose }) => purpose)}
          />
        </Tile>

        <Tile title={t("capitalDeposit.companyOnboarding")}>
          <ReadOnlyFieldList>
            <LakeLabel
              label={t("capitalDeposit.companyOnboardingId")}
              type="view"
              color="current"
              actions={
                isNotNullish(capitalDeposit.companyOnboarding) ? (
                  <Link
                    to={Router.OnboardingDetailRoot({
                      projectId,
                      projectEnv,
                      onboardingId: capitalDeposit.companyOnboarding.id,
                    })}
                  >
                    <Icon size={20} name="arrow-right-filled" color={colors.swan[900]} />
                  </Link>
                ) : (
                  UNKNOWN_VALUE
                )
              }
              render={() => (
                <LakeText color={colors.gray[900]}>{capitalDeposit.companyOnboarding?.id}</LakeText>
              )}
            />

            <LakeLabel
              label={t("capitalDeposit.status")}
              type="view"
              color="current"
              render={() =>
                match(capitalDeposit.companyOnboarding?.statusInfo)
                  .with({ __typename: "OnboardingFinalizedStatusInfo" }, () => (
                    <Tag color="positive">{t("capitalDeposit.companyOnboarding.completed")}</Tag>
                  ))
                  .with(
                    { __typename: "OnboardingInvalidStatusInfo" },
                    { __typename: "OnboardingValidStatusInfo" },
                    () => (
                      <Tag color="warning">{t("capitalDeposit.companyOnboarding.ongoing")}</Tag>
                    ),
                  )
                  .otherwise(() => null)
              }
            />
          </ReadOnlyFieldList>
        </Tile>
      </TileGrid>
    </>
  );
};
