import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { invariantColors } from "@swan-io/lake/src/constants/design";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useRef } from "react";
import { P, match } from "ts-pattern";
import {
  BrandingSettings,
  BrandingSettingsForm,
  SaveRef,
} from "../components/BrandingSettingsForm";
import { GetDraftBrandingPageDocument, StartBrandingReviewDocument } from "../graphql/admin";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

export const BrandingDraftTab = () => {
  const { projectId, projectEnv } = useProjectInfo();
  const canEditBranding = usePermissions(projectEnv).settingsBranding.write;

  const [data] = useQuery(GetDraftBrandingPageDocument, { projectId });

  const [startReview, review] = useMutation(StartBrandingReviewDocument);

  const formRef = useRef<SaveRef | null>(null);

  const onPressSave = () => {
    formRef.current?.save();
  };

  const onStartReview = async (values: BrandingSettings) => {
    const input = {
      projectId,
      name: values.projectName ?? t("projectSettings.branding.defaultName"),
      accentColor: values.accentColor ?? "",
      logoBase64: values.logoUri ?? "",
    };

    return startReview({ input })
      .mapOk(data => data.startProjectBrandingSettingsReview)
      .mapOkToResult(data => Option.fromNullable(data).toResult(new Error("Empty")))
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        showToast({ variant: "success", title: t("toast.success.brandingPublished") });
        Router.push("SettingsBrandingPending", { projectId, projectEnv: "live" });
      })
      .tapError((error: unknown) => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ project }) => {
      const hasPendingBrandingSettings = isNotNullish(project.pendingProjectBrandingSettings?.name);
      const { liveProjectSettings } = project;

      const defaultValues: BrandingSettings = {
        projectName: liveProjectSettings?.name ?? t("projectSettings.branding.defaultName"),
        logoUri: liveProjectSettings?.logoUri,
        accentColor: liveProjectSettings?.accentColor ?? invariantColors.defaultAccentColor,
      };

      return (
        <BrandingSettingsForm
          defaultValues={defaultValues}
          readonly={hasPendingBrandingSettings}
          onSave={values => void onStartReview(values)}
          Header={
            hasPendingBrandingSettings ? (
              <LakeAlert variant="info" title={t("projectSettings.branding.draft.hasPending")} />
            ) : (
              <LakeAlert variant="info" title={t("projectSettings.branding.draft.info")} />
            )
          }
          ref={formRef}
          actions={
            <LakeButtonGroup>
              <TrackPressable action="Start branding review">
                <LakeTooltip
                  placement="left"
                  content={t("common.action.denied")}
                  disabled={canEditBranding}
                >
                  <LakeButton
                    icon="clock-filled"
                    onPress={onPressSave}
                    loading={review.isLoading()}
                    color="current"
                    disabled={!canEditBranding}
                  >
                    {t("projectSettings.branding.draft.startReview")}
                  </LakeButton>
                </LakeTooltip>
              </TrackPressable>
            </LakeButtonGroup>
          }
        />
      );
    })
    .exhaustive();
};
