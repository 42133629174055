import { useQuery } from "@swan-io/graphql-client";
import {
  CopyableRegularTextCell,
  EndAlignedCell,
  SimpleHeaderCell,
  StartAlignedCell,
} from "@swan-io/lake/src/components/FixedListViewCells";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { ColumnConfig, PlainListView } from "@swan-io/lake/src/components/PlainListView";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { backgroundColor, colors, spacings } from "@swan-io/lake/src/constants/design";
import { CollapsibleLakeAlert } from "@swan-io/shared-business/src/components/CollapsibleLakeAlert";
import dayjs from "dayjs";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import {
  GetUserIdentificationsDocument,
  GetUserIdentificationsQuery,
  IdentificationInvalidReason,
} from "../graphql/partner";
import { isTranslationKey, t } from "../utils/i18n";
import { LakeDocumentationLink } from "./DocumentationLink";

const CELL_HEIGHT = 56;

const styles = StyleSheet.create({
  container: {
    minHeight: 10 * CELL_HEIGHT,
    overflow: "hidden",
  },
  identificationCell: {
    alignSelf: "flex-start",
    height: CELL_HEIGHT,
  },
  identificationEndCell: {
    alignSelf: "flex-start",
    flexGrow: 1,
    height: CELL_HEIGHT,
  },
  identificationEndCellContents: {
    height: CELL_HEIGHT,
  },
});

type Props = {
  userId: string;
};

type UserIdentification = NonNullable<
  NonNullable<NonNullable<GetUserIdentificationsQuery["user"]>["identifications"]>["edges"][number]
>;

type UserIdentificationExtraInfo = undefined;

export const translateReason = (reason: IdentificationInvalidReason) => {
  try {
    return match(`user.details.identifications.reasons.${reason}`)
      .with(P.when(isTranslationKey), key => t(key))
      .exhaustive();
  } catch {
    return t("user.details.identifications.reasons.unknown");
  }
};

const userIdentificationColumns: ColumnConfig<UserIdentification, UserIdentificationExtraInfo>[] = [
  {
    id: "process",
    width: 80,
    title: t("user.details.identifications.process"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { process },
      },
    }) => (
      <View style={styles.identificationCell}>
        <StartAlignedCell>
          <Tag color="gray">
            {match(process)
              .with("Expert", () => t("user.details.identifications.process.expert"))
              .with("QES", () => t("user.details.identifications.process.qes"))
              .with("PVID", () => t("user.details.identifications.process.pvid"))
              .exhaustive()}
          </Tag>
        </StartAlignedCell>
      </View>
    ),
  },
  {
    id: "id",
    width: 390,
    title: t("user.details.identifications.id"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }) => (
      <View style={styles.identificationEndCell}>
        <CopyableRegularTextCell
          text={id}
          copyWording={t("copyButton.copyTooltip")}
          copiedWording={t("copyButton.copiedTooltip")}
        />
      </View>
    ),
  },
  {
    id: "createdAt",
    width: 130,
    title: t("user.details.identifications.createdAt"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) => (
      <View style={styles.identificationCell}>
        <StartAlignedCell>
          <LakeText variant="smallRegular" color={colors.gray[900]}>
            {dayjs(createdAt).format("LL")}
          </LakeText>
        </StartAlignedCell>
      </View>
    ),
  },
  {
    id: "updatedAt",
    width: 130,
    title: t("user.details.identifications.updatedAt"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }) => (
      <View style={styles.identificationCell}>
        <StartAlignedCell>
          <LakeText variant="smallRegular" color={colors.gray[900]}>
            {dayjs(updatedAt).format("LL")}
          </LakeText>
        </StartAlignedCell>
      </View>
    ),
  },
  {
    id: "expert",
    width: 100,
    title: t("user.details.identifications.level.expert"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} justifyContent="flex-end" />,
    renderCell: ({ item: { node } }) => {
      const { levels } = node;
      const status =
        levels.expert.__typename === "NotStartedIdentificationLevelStatusInfo"
          ? "Started"
          : levels.expert.status;

      return (
        <View style={styles.identificationEndCell}>
          <View style={styles.identificationEndCellContents}>
            <EndAlignedCell>
              {match(status)
                .with("NotStarted", () => (
                  <Tag color="gray">{t("user.details.identifications.status.started")}</Tag>
                ))
                .with("Canceled", () => (
                  <Tag color="gray">{t("user.details.identifications.status.canceled")}</Tag>
                ))
                .with("Expired", () => (
                  <Tag color="gray">{t("user.details.identifications.status.expired")}</Tag>
                ))
                .with("Invalid", () => (
                  <Tag color="negative">{t("user.details.identifications.status.invalid")}</Tag>
                ))
                .with("NotSupported", () => <LakeText>{"-"}</LakeText>)
                .with("Pending", () => (
                  <Tag color="shakespear">{t("user.details.identifications.status.pending")}</Tag>
                ))
                .with("Started", () => (
                  <Tag color="shakespear">{t("user.details.identifications.status.started")}</Tag>
                ))
                .with("Valid", () => (
                  <Tag color="positive">{t("user.details.identifications.status.valid")}</Tag>
                ))
                .exhaustive()}
            </EndAlignedCell>
          </View>
        </View>
      );
    },
  },
  {
    id: "qes",
    width: 100,
    title: t("user.details.identifications.level.qes"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} justifyContent="flex-end" />,
    renderCell: ({ item: { node } }) => {
      const { levels } = node;
      const status =
        levels.qes.__typename === "NotStartedIdentificationLevelStatusInfo"
          ? "Started"
          : levels.qes.status;

      return (
        <View style={styles.identificationEndCell}>
          <View style={styles.identificationEndCellContents}>
            <EndAlignedCell>
              {match(status)
                .with("NotStarted", () => (
                  <Tag color="gray">{t("user.details.identifications.status.notStarted")}</Tag>
                ))
                .with("Canceled", () => (
                  <Tag color="gray">{t("user.details.identifications.status.canceled")}</Tag>
                ))
                .with("Expired", () => (
                  <Tag color="gray">{t("user.details.identifications.status.expired")}</Tag>
                ))
                .with("Invalid", () => (
                  <Tag color="negative">{t("user.details.identifications.status.invalid")}</Tag>
                ))
                .with("NotSupported", () => <LakeText>{"-"}</LakeText>)
                .with("Pending", () => (
                  <Tag color="shakespear">{t("user.details.identifications.status.pending")}</Tag>
                ))
                .with("Started", () => (
                  <Tag color="shakespear">{t("user.details.identifications.status.started")}</Tag>
                ))
                .with("Valid", () => (
                  <Tag color="positive">{t("user.details.identifications.status.valid")}</Tag>
                ))
                .exhaustive()}
            </EndAlignedCell>
          </View>
        </View>
      );
    },
  },
  {
    id: "pvid",
    width: 100,
    title: t("user.details.identifications.level.pvid"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} justifyContent="flex-end" />,
    renderCell: ({ item: { node } }) => {
      const { levels } = node;
      const status =
        levels.pvid.__typename === "NotStartedIdentificationLevelStatusInfo"
          ? "Started"
          : levels.pvid.status;

      return (
        <View style={styles.identificationEndCell}>
          <View style={styles.identificationEndCellContents}>
            <EndAlignedCell>
              {match(status)
                .with("NotStarted", () => (
                  <Tag color="gray">{t("user.details.identifications.status.notStarted")}</Tag>
                ))
                .with("Canceled", () => (
                  <Tag color="gray">{t("user.details.identifications.status.canceled")}</Tag>
                ))
                .with("Expired", () => (
                  <Tag color="gray">{t("user.details.identifications.status.expired")}</Tag>
                ))
                .with("Invalid", () => (
                  <Tag color="negative">{t("user.details.identifications.status.invalid")}</Tag>
                ))
                .with("NotSupported", () => <LakeText>{"-"}</LakeText>)
                .with("Pending", () => (
                  <Tag color="shakespear">{t("user.details.identifications.status.pending")}</Tag>
                ))
                .with("Started", () => (
                  <Tag color="shakespear">{t("user.details.identifications.status.started")}</Tag>
                ))
                .with("Valid", () => (
                  <Tag color="positive">{t("user.details.identifications.status.valid")}</Tag>
                ))
                .exhaustive()}
            </EndAlignedCell>
          </View>
        </View>
      );
    },
  },
  {
    id: "reason",
    width: 0,
    title: "",
    renderTitle: () => null,
    renderCell: ({ item: { node } }) => {
      const reasons = match(node)
        .with(
          {
            process: "Expert",
            levels: {
              expert: { __typename: "InvalidIdentificationLevelStatusInfo", reasons: P.select() },
            },
          },
          value => value,
        )
        .with(
          {
            process: "QES",
            levels: {
              qes: { __typename: "InvalidIdentificationLevelStatusInfo", reasons: P.select() },
            },
          },
          value => value,
        )
        .with(
          {
            process: "PVID",
            levels: {
              pvid: { __typename: "InvalidIdentificationLevelStatusInfo", reasons: P.select() },
            },
          },
          value => value,
        )
        .otherwise(() => undefined);
      return reasons != null && reasons.length > 0 ? (
        <View
          style={{
            marginLeft: -minWidth,
            width: minWidth,
            marginTop: CELL_HEIGHT,
            marginBottom: spacings[12],
          }}
        >
          <CollapsibleLakeAlert
            title={t("user.details.identifications.reason")}
            variant="error"
            initialCollapsed={true}
          >
            {reasons.map((item, index) => (
              <LakeText color={colors.gray[500]} variant="regular" key={index}>
                {translateReason(item)}
              </LakeText>
            ))}

            <Space height={8} />

            <LakeDocumentationLink to="userIdentifications" color={colors.negative[700]}>
              {t("common.learnMore")}
            </LakeDocumentationLink>
          </CollapsibleLakeAlert>
        </View>
      ) : null;
    },
  },
];

export const minWidth = userIdentificationColumns.reduce(
  (acc, item) => acc + (typeof item.width === "number" ? item.width : 0),
  0,
);

export const UserIdentificationHistory = ({ userId }: Props) => {
  const [data, { isLoading }] = useQuery(GetUserIdentificationsDocument, {
    userId,
    first: 10,
  });

  const identifications = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ user }) => user?.identifications?.edges ?? [])
    .getOr([]);

  return (
    <View style={styles.container}>
      <PlainListView<
        NonNullable<
          NonNullable<GetUserIdentificationsQuery["user"]>["identifications"]
        >["edges"][number],
        UserIdentificationExtraInfo
      >
        headerBackgroundColor={backgroundColor.default}
        data={identifications}
        keyExtractor={item => item.node.id}
        extraInfo={undefined}
        columns={userIdentificationColumns}
        headerHeight={48}
        rowHeight={56}
        getRowLink={() => <View />}
        groupHeaderHeight={48}
        breakpoint={0}
        loading={{
          isLoading,
          count: 10,
        }}
        withoutScroll={true}
      />
    </View>
  );
};
