import { AsyncData, Result } from "@swan-io/boxed";
import { encodeSearch, pushUnsafe, useLocation } from "@swan-io/chicane";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useCallback, useEffect, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import {
  BrandingSettings,
  BrandingSettingsForm,
  SaveRef,
} from "../components/BrandingSettingsForm";
import { ErrorView } from "../components/ErrorView";
import { FirstVisitModal } from "../components/FirstVisitModal";
import { TrackPressable } from "../components/TrackPressable";
import { GetBrandingPageDocument, UpdateBrandingDocument } from "../graphql/admin";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";

type Props = {
  firstVisit?: boolean;
  fullName?: string;
};

const styles = StyleSheet.create({
  button: {
    width: 170,
  },
});

export const BrandingSandboxPage = ({ fullName, firstVisit = false }: Props) => {
  const {
    raw: { path },
    search,
  } = useLocation();

  const { projectId, projectEnv } = useProjectInfo();
  const canEditBranding = usePermissions(projectEnv).settingsBranding.write;

  const [data, { reload }] = useQuery(GetBrandingPageDocument, { projectId });

  const brandingRef = useRef<SaveRef | undefined>();
  const [isFirstVisit, setIsFirstVisit] = useState(() => firstVisit);

  useEffect(() => {
    if (firstVisit) {
      Router.replace("SettingsBrandingRoot", { projectId, projectEnv: "sandbox" });
    }
  }, [firstVisit, projectId]);

  const onPressSave = useCallback(() => {
    if (isNotNullish(brandingRef.current)) {
      brandingRef.current.save();
    }
  }, []);

  const onSave = (values: BrandingSettings) => {
    void updateBranding({
      input: {
        projectId,
        accentColor: values.accentColor,
        name: values.projectName,
        logoBase64: values.logoUri,
      },
    })
      .tapOk(() => {
        showToast({ variant: "success", title: t("toast.success.brandingSaved") });
        reload();
      })
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  const [updateBranding, brandingUpdate] = useMutation(UpdateBrandingDocument);

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => {
      const { project } = data;

      const branding = project.sandboxProjectSettings;

      const defaultValues = {
        projectName: branding?.name,
        accentColor: branding?.accentColor,
        logoUri: branding?.logoUri,
      };

      return (
        <>
          <BrandingSettingsForm
            onSave={values => void onSave(values)}
            ref={brandingRef}
            Header={
              <LakeAlert
                variant="warning"
                title={t("projectSettings.branding.sandbox.info.title")}
                subtitle={t("projectSettings.branding.sandbox.info.subtitle")}
              />
            }
            isLogoRequired={false}
            defaultValues={defaultValues}
            Footer={
              <View style={styles.button}>
                <TrackPressable action="Save branding">
                  <LakeTooltip
                    placement="left"
                    content={t("common.action.denied")}
                    disabled={canEditBranding}
                  >
                    <LakeButton
                      onPress={onPressSave}
                      loading={brandingUpdate.isLoading()}
                      color="current"
                      disabled={!canEditBranding}
                    >
                      {t("common.save")}
                    </LakeButton>
                  </LakeTooltip>
                </TrackPressable>
              </View>
            }
          />

          <FirstVisitModal
            fullName={fullName}
            visible={isFirstVisit}
            onPressTalkToAndExpert={() => {
              setIsFirstVisit(false);
              pushUnsafe(
                `${path}${encodeSearch({ ...search, activate: "true", step: "TalkToAnExpert" })}`,
              );
            }}
            onDismiss={() => setIsFirstVisit(false)}
          />
        </>
      );
    })
    .exhaustive();
};
