import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileFullWidthContent } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotEmpty, isNotNullish, isNullish } from "@swan-io/lake/src/utils/nullish";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import dayjs from "dayjs";
import { Highlight, themes } from "prism-react-renderer";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { P, match } from "ts-pattern";
import { JsonValue } from "type-fest";
import { ErrorView } from "../components/ErrorView";
import { ProjectEnvBadge } from "../components/ProjectEnvBadge";
import { Redirect } from "../components/Redirect";
import { TrackPressable } from "../components/TrackPressable";
import {
  ReplayWebhookEventDocument,
  WebhookEventLogDocument,
  WebhookEventLogQuery,
  WebhookSubscriptionQuery,
} from "../graphql/partner";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingBottom: 40,
  },
  detailsPart: {
    flex: 1,
  },
  headersPayloadContainer: {
    flexGrow: 1,
  },
  codeContainer: {
    flexGrow: 1,
    paddingVertical: spacings[24],
  },
  headersPayload: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    whiteSpace: "pre",
    width: "100%",
    flexGrow: 1,
    fontFamily: [
      "ui-monospace",
      "SFMono-Regular",
      "SF Mono",
      "Menlo",
      "Consolas",
      "Liberation Mono",
      "monospace",
    ].join(","),
  },
  headerText: {
    fontWeight: "600",
  },
  codeBackground: {
    paddingHorizontal: spacings[32],
  },
  retryButtonSuccess: {
    whiteSpace: "nowrap",
    textAlign: "right",
  },
  retryAccessToNewEntry: {
    color: colors.current.primary,
    whiteSpace: "nowrap",
    textAlign: "right",
  },
  retryResult: {
    position: "absolute",
    right: 0,
    top: "100%",
  },
});

const codeStyle: CSSProperties = {
  fontFamily:
    'ui-monospace, SFMono-Regular, "SF Mono", Menlo, Consolas, "Liberation Mono", monospace',
  fontSize: 14,
  lineHeight: 1.65,
  whiteSpace: "pre-wrap",
};

const HightlightJson = ({ data }: { data: JsonValue }) => (
  <View>
    {match<unknown>(data)
      .with(P.string, P.number, value => <Text>{value}</Text>)
      .with(P.boolean, P.nullish, value => <Text>{JSON.stringify(value)}</Text>)
      .otherwise(value => (
        <Highlight theme={themes.github} code={JSON.stringify(value, null, 2)} language="json">
          {({ tokens, getLineProps, getTokenProps }) => (
            <View>
              {tokens.map((line, i) => {
                const { style, ...props } = getLineProps({ line });

                return (
                  <code key={i} {...props} style={{ ...style, ...codeStyle }}>
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token })} />
                    ))}
                  </code>
                );
              })}
            </View>
          )}
        </Highlight>
      ))}
  </View>
);

export const WebhookEvent = ({
  eventLog,
  webhookSubscriptionId,
  webhookSubscription,
}: {
  eventLog: NonNullable<WebhookEventLogQuery>["webhookEventLog"];
  webhookSubscriptionId: string;
  webhookSubscription: NonNullable<WebhookSubscriptionQuery["webhookSubscription"]>;
}) => {
  const { projectId, projectEnv } = useProjectInfo();
  const canRetryWebhooks = usePermissions(projectEnv).webhook.write;

  const [replayWebhookEvent, webhookEventReplay] = useMutation(ReplayWebhookEventDocument);

  const [replayedEntryId, setReplayedEntryId] = useState("");

  useEffect(() => {
    setReplayedEntryId("");
  }, [eventLog?.id]);

  if (!eventLog) {
    return null;
  }

  const succeeded =
    typeof eventLog.statusCode === "number" &&
    eventLog.statusCode !== 0 &&
    eventLog.statusCode < 300;

  const handleReplayEvent = () => {
    replayWebhookEvent({
      input: {
        webhookEventId: eventLog.eventId,
      },
    })
      .mapOk(data => data.replayWebhookEvent)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(({ webhookEventLogEntryId }) => {
        match(webhookEventLogEntryId)
          .with("null", () => showToast({ variant: "error", title: t("error.generic") }))
          .otherwise(() => setReplayedEntryId(webhookEventLogEntryId));
      })
      .tapError((error: unknown) => {
        setReplayedEntryId("");
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  type Payload = {
    headers: Record<string, string>;
    data: JsonValue;
  };

  const { headers: requestHeaders, data: requestPayload } = JSON.parse(
    eventLog.requestPayload,
  ) as Payload;

  const { headers: responseHeaders, data: responsePayload } = isNotNullish(eventLog.responsePayload)
    ? (JSON.parse(eventLog.responsePayload) as Payload)
    : { headers: {}, data: {} };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Tile
        title={
          <>
            {t("webhookEvent.title", { name: webhookSubscription.label })}

            <Space width={16} />
            <ProjectEnvBadge />
          </>
        }
        headerEnd={
          !succeeded && (
            <View>
              <TrackPressable action="Retry webhook event">
                <LakeTooltip
                  placement="right"
                  content={t("common.action.denied")}
                  disabled={canRetryWebhooks}
                >
                  <LakeButton
                    color="current"
                    icon="arrow-counterclockwise-filled"
                    loading={webhookEventReplay.isLoading()}
                    disabled={isNotEmpty(replayedEntryId) || !canRetryWebhooks}
                    size="small"
                    onPress={handleReplayEvent}
                  >
                    {t("webhookEvent.retry")}
                  </LakeButton>
                </LakeTooltip>
              </TrackPressable>

              {isNotEmpty(replayedEntryId) && (
                <View style={styles.retryResult}>
                  <Space height={16} />

                  <LakeText color={colors.positive.primary} style={styles.retryButtonSuccess}>
                    {t("webhookEvent.retry.success")}
                  </LakeText>

                  <Space height={8} />

                  <Link
                    style={styles.retryAccessToNewEntry}
                    to={Router.DevelopersWebhooksSubscriptionEventLog({
                      projectId,
                      projectEnv,
                      webhookSubscriptionId,
                      logId: replayedEntryId,
                    })}
                  >
                    {t("webhookEvent.retry.newLogEntry")}
                  </Link>
                </View>
              )}
            </View>
          )
        }
      >
        <Box direction="row" alignItems="center">
          <LakeText>{t("webhookEvent.label.url")}:</LakeText>
          <Space width={4} />

          <LakeText color={colors.gray[900]} variant="semibold">
            {webhookSubscription.endpoint}
          </LakeText>
        </Box>

        <Space height={12} />

        <Box direction="row" alignItems="center">
          <LakeText>{t("webhookEvent.label.responseStatus")}:</LakeText>
          <Space width={4} />

          <LakeText color={colors.gray[900]} variant="semibold">
            {succeeded ? (
              <Tag color="positive">
                {isNotNullish(eventLog.statusCode) ? `${eventLog.statusCode} OK` : "OK"}
              </Tag>
            ) : (
              <Tag color="negative">
                {isNotNullish(eventLog.statusCode) ? `${eventLog.statusCode} NOK` : "NOK"}
              </Tag>
            )}
          </LakeText>
        </Box>

        <Space height={12} />

        <Box direction="row" alignItems="center">
          <LakeText>{t("webhookEvent.label.dateTime")}:</LakeText>
          <Space width={4} />

          <LakeText color={colors.gray[900]} variant="semibold">
            {dayjs(eventLog.createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
          </LakeText>
        </Box>

        <Space height={12} />

        <Box direction="row">
          <LakeText>{t("webhookEvent.label.responseDuration")}:</LakeText>
          <Space width={4} />

          <LakeText color={colors.gray[900]} variant="semibold">
            {eventLog.duration}ms
          </LakeText>
        </Box>
      </Tile>

      <Space height={24} />

      <Box direction="row" alignItems="stretch" style={commonStyles.fill}>
        <View style={styles.detailsPart}>
          <Tile title={t("webhookEvent.request")} flexGrow={1}>
            <TileFullWidthContent flexGrow={1} fitToBottom={true}>
              <Separator />

              <ScrollView
                horizontal={true}
                style={styles.codeContainer}
                contentContainerStyle={styles.headersPayloadContainer}
              >
                <LakeText
                  color={colors.gray[800]}
                  variant="smallRegular"
                  style={[styles.headersPayload, styles.codeBackground]}
                >
                  {isNotNullish(requestHeaders) && (
                    <>
                      <Text style={styles.headerText}>{t("webhookEvent.headers")}</Text>
                      {"\n"}
                      {"\n"}
                      <HightlightJson data={requestHeaders} />
                      {"\n"}
                      {"\n"}
                    </>
                  )}

                  {isNotNullish(requestPayload) && (
                    <>
                      <Text style={styles.headerText}>{t("webhookEvent.payload")}</Text>
                      {"\n"}
                      {"\n"}
                      <HightlightJson data={requestPayload} />
                      {"\n"}
                      {"\n"}
                    </>
                  )}
                </LakeText>
              </ScrollView>
            </TileFullWidthContent>
          </Tile>
        </View>

        <Space width={24} />

        <View style={styles.detailsPart}>
          <Tile title={t("webhookEvent.response")} flexGrow={1}>
            <TileFullWidthContent flexGrow={1} fitToBottom={true}>
              <Separator />

              <ScrollView
                horizontal={true}
                style={styles.codeContainer}
                contentContainerStyle={styles.headersPayloadContainer}
              >
                <LakeText
                  color={colors.gray[800]}
                  variant="smallRegular"
                  style={[styles.headersPayload, styles.codeBackground]}
                >
                  <Text style={styles.headerText}>{t("webhookEvent.headers")}</Text>
                  {"\n"}
                  {"\n"}
                  <HightlightJson data={responseHeaders} />
                  {"\n"}
                  {"\n"}
                  <Text style={styles.headerText}>{t("webhookEvent.payload")}</Text>
                  {"\n"}
                  {"\n"}
                  <HightlightJson data={responsePayload} />
                </LakeText>
              </ScrollView>
            </TileFullWidthContent>
          </Tile>
        </View>
      </Box>
    </ScrollView>
  );
};

type Props = {
  webhookSubscriptionId: string;
  logId: string;
  webhookSubscription: NonNullable<WebhookSubscriptionQuery["webhookSubscription"]>;
};

export const WebhookSubscriptionEventLog = ({
  webhookSubscriptionId,
  logId,
  webhookSubscription,
}: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const [data] = useQuery(WebhookEventLogDocument, { id: logId });

  useCrumb(
    useMemo(
      () =>
        data
          .toOption()
          .flatMap(result => result.toOption())
          .flatMap(data => Option.fromNullable(data.webhookEventLog))
          .map(webhookEventlogEntry => ({
            label: t("webhookEvent.logName", { eventType: webhookEventlogEntry.eventType }),
            link: Router.DevelopersWebhooksSubscriptionEventLog({
              projectId,
              projectEnv,
              webhookSubscriptionId,
              logId,
            }),
          }))
          .toUndefined(),
      [projectEnv, projectId, webhookSubscriptionId, logId, data],
    ),
  );

  if (isNullish(webhookSubscription.id)) {
    return <Redirect to={Router.DevelopersWebhooksRoot({ projectId, projectEnv })} />;
  }

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok({ webhookEventLog: P.nullish })), () => (
      <Redirect to={Router.DevelopersWebhooksRoot({ projectId, projectEnv })} />
    ))
    .with(AsyncData.P.Done(Result.P.Ok({ webhookEventLog: P.select(P.nonNullable) })), eventLog => (
      <WebhookEvent
        eventLog={eventLog}
        webhookSubscription={webhookSubscription}
        webhookSubscriptionId={webhookSubscriptionId}
      />
    ))
    .exhaustive();
};
