import { Box } from "@swan-io/lake/src/components/Box";
import { Flag } from "@swan-io/lake/src/components/Flag";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeDownloadButton } from "@swan-io/lake/src/components/LakeDownloadButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullish, isNotNullishOrEmpty, isNullish } from "@swan-io/lake/src/utils/nullish";
import { countries, getCountryByCCA3 } from "@swan-io/shared-business/src/constants/countries";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { match, P } from "ts-pattern";
import {
  BusinessActivity,
  EmploymentStatus,
  GetOnboardingDetailsPageQuery,
  MonthlyIncome,
  MonthlyPaymentVolume,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { locale, t, TranslationKey } from "../utils/i18n";
import { Router } from "../utils/routes";
import { getOnboardingMissingFieldLabel } from "../utils/templateTranslations";
import { LinkButton } from "./LinkButton";

const styles = StyleSheet.create({
  unknownValue: {
    fontStyle: "italic",
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  align: {
    display: "flex",
    alignItems: "center",
  },
});

const employmentStatuses: { text: TranslationKey; value: EmploymentStatus }[] = [
  { text: "onboarding.employmentStatus.craftsman", value: "Craftsman" },
  { text: "onboarding.employmentStatus.employee", value: "Employee" },
  { text: "onboarding.employmentStatus.entrepreneur", value: "Entrepreneur" },
  { text: "onboarding.employmentStatus.farmer", value: "Farmer" },
  { text: "onboarding.employmentStatus.manager", value: "Manager" },
  { text: "onboarding.employmentStatus.practitioner", value: "Practitioner" },
  { text: "onboarding.employmentStatus.retiree", value: "Retiree" },
  { text: "onboarding.employmentStatus.shopOwner", value: "ShopOwner" },
  { text: "onboarding.employmentStatus.student", value: "Student" },
  { text: "onboarding.employmentStatus.unemployed", value: "Unemployed" },
];

const monthlyIncomes: { text: TranslationKey; value: MonthlyIncome }[] = [
  { text: "onboarding.monthlyIncome.lessThan500", value: "LessThan500" },
  { text: "onboarding.monthlyIncome.between500And1500", value: "Between500And1500" },
  { text: "onboarding.monthlyIncome.between1500And3000", value: "Between1500And3000" },
  { text: "onboarding.monthlyIncome.between3000And4500", value: "Between3000And4500" },
  { text: "onboarding.monthlyIncome.moreThan4500", value: "MoreThan4500" },
];

const monthlyPaymentVolumes: { text: TranslationKey; value: MonthlyPaymentVolume }[] = [
  { text: "onboarding.monthlyPaymentVolume.lessThan10000", value: "LessThan10000" },
  { text: "onboarding.monthlyPaymentVolume.between10000And50000", value: "Between10000And50000" },
  { text: "onboarding.monthlyPaymentVolume.between50000And100000", value: "Between50000And100000" },
  { text: "onboarding.monthlyPaymentVolume.moreThan100000", value: "MoreThan100000" },
];

const businessActivities: { text: TranslationKey; value: BusinessActivity }[] = [
  { text: "onboarding.businessActivity.administrativeServices", value: "AdministrativeServices" },
  { text: "onboarding.businessActivity.agriculture", value: "Agriculture" },
  { text: "onboarding.businessActivity.arts", value: "Arts" },
  { text: "onboarding.businessActivity.businessAndRetail", value: "BusinessAndRetail" },
  { text: "onboarding.businessActivity.construction", value: "Construction" },
  { text: "onboarding.businessActivity.education", value: "Education" },
  {
    text: "onboarding.businessActivity.electricalDistributionAndWaterSupply",
    value: "ElectricalDistributionAndWaterSupply",
  },
  {
    text: "onboarding.businessActivity.financialAndInsuranceOperations",
    value: "FinancialAndInsuranceOperations",
  },
  { text: "onboarding.businessActivity.health", value: "Health" },
  { text: "onboarding.businessActivity.housekeeping", value: "Housekeeping" },
  {
    text: "onboarding.businessActivity.informationAndCommunication",
    value: "InformationAndCommunication",
  },
  { text: "onboarding.businessActivity.lodgingAndFoodServices", value: "LodgingAndFoodServices" },
  { text: "onboarding.businessActivity.manufacturingAndMining", value: "ManufacturingAndMining" },
  { text: "onboarding.businessActivity.publicAdministration", value: "PublicAdministration" },
  { text: "onboarding.businessActivity.realEstate", value: "RealEstate" },
  { text: "onboarding.businessActivity.scientificActivities", value: "ScientificActivities" },
  { text: "onboarding.businessActivity.transportation", value: "Transportation" },
  { text: "onboarding.businessActivity.other", value: "Other" },
];

type CountryCCA3 = "BEL" | "DEU" | "FRA" | "ITA" | "LUX" | "NLD";

const companyTypeCountryList: CountryCCA3[] = ["BEL", "DEU", "FRA", "ITA", "LUX", "NLD"];
const companyTypesPerCountry: Partial<Record<CountryCCA3, string>> = {
  BEL: "Entreprise (SA, SPRL, SCRL, SCRIS, SNC, SCS, GIE)",
  DEU: "Firma (GmbH, EI, Gbr, KG)",
  FRA: "Entreprise (SA, SARL, SAS, SCI, EI, EIRL, Micro-entreprise…)",
  ITA: "Società (SS, SRL, SPA, SNC, SAS…)",
  LUX: "Entreprise (SA, SCS, SARLI, SNC, SCA, SC)",
  NLD: "Bedrijf (BV, NV, VOF…)",
};

type Props = {
  onboarding: NonNullable<GetOnboardingDetailsPageQuery["onboarding"]>;
};

const UNKNOWN_VALUE = <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>;

export const OnboardingDetailGeneral = ({ onboarding }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const onboardingCountry = onboarding.info.residencyAddress?.country;
  const onboardingLanguage = onboarding.language;

  const country = useMemo(
    () => countries.find(({ cca3 }) => cca3 === onboardingCountry),
    [onboardingCountry],
  );

  const language = useMemo(() => {
    const hasIntl = "Intl" in window && "DisplayNames" in window.Intl;

    const resolver = hasIntl
      ? new Intl.DisplayNames([locale.language], { type: "language" })
      : undefined;

    return isNotNullish(resolver) && isNotNullishOrEmpty(onboardingLanguage)
      ? resolver.of(onboardingLanguage)?.toString()
      : null;
  }, [onboardingLanguage]);

  const companyType = useMemo(() => {
    if (onboarding.info.__typename === "OnboardingCompanyAccountHolderInfo") {
      return match(onboarding.info.companyType)
        .with("Company", () => {
          const country = companyTypeCountryList.find(
            country => country === onboarding.info.residencyAddress?.country,
          );

          return isNotNullish(country)
            ? companyTypesPerCountry[country]
            : t("onboarding.general.companyType.company");
        })
        .with("Association", () => t("onboarding.general.companyType.association"))
        .with("HomeOwnerAssociation", () =>
          t("onboarding.general.companyType.homeOwnerAssociation"),
        )
        .with("SelfEmployed", () => t("onboarding.general.companyType.selfEmployed"))
        .with("Other", () => t("onboarding.general.companyType.other"))
        .otherwise(() => UNKNOWN_VALUE);
    }
  }, [onboarding.info]);

  const isIndividual = onboarding.info.type === "Individual";
  const hasAccountHolderName = isNotNullishOrEmpty(onboarding.accountHolder?.info.name);

  const redirectUrl = onboarding.oAuthRedirectParameters?.redirectUrl;
  const hasAuthTile =
    isNotNullishOrEmpty(redirectUrl) ||
    isNotNullishOrEmpty(onboarding.oAuthRedirectParameters?.state);

  return (
    <>
      {match(onboarding.statusInfo)
        .with({ __typename: "OnboardingInvalidStatusInfo" }, statusInfo => (
          <>
            <LakeAlert
              variant="error"
              title={t("onboarding.general.fieldMissing")}
              subtitle={t("onboarding.general.fieldMissing.subtitle")}
            >
              <Box>
                {statusInfo.errors.map(({ field }, index) => (
                  <LakeText color={colors.gray[500]} variant="regular" key={index}>
                    {getOnboardingMissingFieldLabel(field)}
                  </LakeText>
                ))}
              </Box>
            </LakeAlert>

            <Space height={32} />
          </>
        ))
        .otherwise(() => null)}

      <TileGrid>
        <Tile title={t("onboarding.general.onboarding")}>
          <ReadOnlyFieldList>
            <LakeLabel
              label={t("onboarding.general.type")}
              type="view"
              render={() => (
                <Tag
                  color={isIndividual ? "darkPink" : "shakespear"}
                  icon={isIndividual ? "person-regular" : "building-regular"}
                >
                  {isIndividual
                    ? t("onboarding.general.individual")
                    : t("onboarding.general.company")}
                </Tag>
              )}
            />

            <LakeLabel
              label={t("onboarding.general.accountCountry")}
              type="view"
              render={() => {
                const country = getCountryByCCA3(onboarding.accountCountry);

                return (
                  <LakeText style={styles.align} numberOfLines={1} color={colors.gray[900]}>
                    <Flag code={country.cca2} width={14} />
                    <Space width={8} />

                    {country.name}
                  </LakeText>
                );
              }}
            />

            <LakeLabel
              label={t("onboarding.general.email")}
              type="view"
              render={() =>
                isNotNullish(onboarding.email) ? (
                  <LakeText color={colors.gray[900]}>{onboarding.email}</LakeText>
                ) : (
                  UNKNOWN_VALUE
                )
              }
            />

            <LakeLabel
              label={t("onboarding.general.status")}
              type="view"
              render={() => (
                <Tag
                  color={
                    onboarding.statusInfo.status === "Finalized"
                      ? "positive"
                      : onboarding.statusInfo.status === "Invalid"
                        ? "warning"
                        : "shakespear"
                  }
                >
                  {onboarding.statusInfo.status === "Finalized"
                    ? t("onboarding.general.status.finalized")
                    : onboarding.statusInfo.status === "Valid"
                      ? t("onboarding.general.status.valid")
                      : t("onboarding.general.status.invalid")}
                </Tag>
              )}
            />

            <LakeLabel
              label={t("onboarding.general.id")}
              type="view"
              render={() => <LakeText color={colors.gray[900]}>{onboarding.id}</LakeText>}
              actions={
                <LakeCopyButton
                  valueToCopy={onboarding.id}
                  copyText={t("copyButton.copyTooltip")}
                  copiedText={t("copyButton.copiedTooltip")}
                />
              }
            />

            {match(onboarding)
              .with(
                { statusInfo: { status: P.union("Valid", "Invalid") }, onboardingUrl: P.string },
                ({ onboardingUrl }) => (
                  <LakeLabel
                    label={t("onboarding.general.formUrl")}
                    type="view"
                    render={() => (
                      <LakeText color={colors.gray[900]} style={styles.ellipsis}>
                        {onboardingUrl}
                      </LakeText>
                    )}
                    actions={
                      <>
                        <LakeCopyButton
                          valueToCopy={onboardingUrl}
                          copyText={t("copyButton.copyTooltip")}
                          copiedText={t("copyButton.copiedTooltip")}
                        />

                        <Space width={16} />
                        <LinkButton external={true} to={onboardingUrl} />
                      </>
                    }
                  />
                ),
              )
              .otherwise(() => null)}

            <LakeLabel
              label={t("onboarding.general.accountName")}
              type="view"
              render={() =>
                isNotNullish(onboarding.accountName) ? (
                  <LakeText color={colors.gray[900]}>{onboarding.accountName}</LakeText>
                ) : (
                  UNKNOWN_VALUE
                )
              }
            />

            <LakeLabel
              label={t("onboarding.general.language")}
              type="view"
              render={() =>
                isNotNullish(language) ? (
                  <LakeText color={colors.gray[900]} style={styles.align}>
                    {language}
                  </LakeText>
                ) : (
                  UNKNOWN_VALUE
                )
              }
            />

            <LakeLabel
              label={t("onboarding.general.tcu")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]} numberOfLines={1}>
                  {t("onboarding.general.tcu.filename") + ".pdf"}
                </LakeText>
              )}
              actions={
                <LakeDownloadButton
                  url={onboarding.tcuUrl}
                  filename={t("onboarding.general.tcu.filename") + ".pdf"}
                />
              }
            />
          </ReadOnlyFieldList>
        </Tile>

        <>
          {match(onboarding.info)
            .with({ __typename: "OnboardingCompanyAccountHolderInfo" }, info => (
              <Tile title={t("onboarding.general.company")}>
                <ReadOnlyFieldList>
                  {hasAccountHolderName ? (
                    <LakeLabel
                      label={t("onboarding.general.accountHolder")}
                      type="view"
                      render={() =>
                        isNotNullish(info.name) ? (
                          <LakeText color={colors.gray[900]} numberOfLines={1}>
                            {info.name}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                      actions={
                        isNotNullish(onboarding.accountHolder) && (
                          <Link
                            to={Router.HoldersDetailRoot({
                              projectId,
                              projectEnv,
                              accountHolderId: onboarding.accountHolder.id,
                            })}
                          >
                            <Icon size={20} name="arrow-right-filled" />
                          </Link>
                        )
                      }
                    />
                  ) : (
                    <LakeLabel
                      label={t("onboarding.general.accountHolder")}
                      type="view"
                      render={() =>
                        isNotNullish(info.name) ? (
                          <LakeText color={colors.gray[900]} numberOfLines={1}>
                            {info.name}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                      actions={
                        isNotNullish(onboarding.accountHolder) &&
                        isNotNullishOrEmpty(info.name) && (
                          <Link
                            to={Router.HoldersDetailRoot({
                              projectId,
                              projectEnv,
                              accountHolderId: onboarding.accountHolder.id,
                            })}
                          >
                            <Icon size={20} name="arrow-right-filled" />
                          </Link>
                        )
                      }
                    />
                  )}

                  <LakeLabel
                    label={t("onboarding.general.type")}
                    type="view"
                    render={() => (
                      <LakeText color={colors.gray[900]} numberOfLines={1}>
                        {companyType}
                      </LakeText>
                    )}
                  />

                  <LakeLabel
                    label={t("onboarding.general.registrationNumber")}
                    type="view"
                    render={() =>
                      isNotNullish(info.registrationNumber) ? (
                        <LakeText numberOfLines={1} color={colors.gray[900]}>
                          {info.registrationNumber}
                        </LakeText>
                      ) : (
                        UNKNOWN_VALUE
                      )
                    }
                  />

                  <LakeLabel
                    label={t("onboarding.general.businessActivity")}
                    type="view"
                    render={() => {
                      const businessActivity = businessActivities.find(
                        ({ value }) => value === info.businessActivity,
                      );

                      return isNotNullish(businessActivity) ? (
                        <LakeText numberOfLines={1} color={colors.gray[900]}>
                          {t(businessActivity.text)}
                        </LakeText>
                      ) : (
                        UNKNOWN_VALUE
                      );
                    }}
                  />

                  <LakeLabel
                    label={t("onboarding.general.businessDescription")}
                    type="view"
                    render={() =>
                      isNotNullish(info.businessActivityDescription) ? (
                        <LakeText color={colors.gray[900]}>
                          {info.businessActivityDescription}
                        </LakeText>
                      ) : (
                        UNKNOWN_VALUE
                      )
                    }
                  />

                  <LakeLabel
                    label={t("onboarding.general.paymentVolume")}
                    type="view"
                    render={() => {
                      const paymentVolume = monthlyPaymentVolumes.find(
                        ({ value }) => value === info.monthlyPaymentVolume,
                      );

                      return isNotNullish(paymentVolume) ? (
                        <LakeText numberOfLines={1} color={colors.gray[900]}>
                          {t(paymentVolume.text)}
                        </LakeText>
                      ) : (
                        UNKNOWN_VALUE
                      );
                    }}
                  />

                  <LakeLabel
                    label={t("onboarding.general.typeOfRepresentation")}
                    type="view"
                    render={() => {
                      return match(info.typeOfRepresentation)
                        .with("PowerOfAttorney", () => (
                          <Tag color="shakespear">
                            {t("onboarding.general.typeOfRepresentation.powerOfAttorney")}
                          </Tag>
                        ))
                        .with("LegalRepresentative", () => (
                          <Tag color="darkPink">
                            {t("onboarding.general.typeOfRepresentation.legalRepresentative")}
                          </Tag>
                        ))
                        .otherwise(() => UNKNOWN_VALUE);
                    }}
                  />
                </ReadOnlyFieldList>
              </Tile>
            ))
            .with({ __typename: "OnboardingIndividualAccountHolderInfo" }, info => (
              <Tile title={t("onboarding.general.individual")}>
                <ReadOnlyFieldList>
                  <LakeLabel
                    label={t("onboarding.general.accountHolder")}
                    type="view"
                    render={() => {
                      const name = onboarding.accountHolder?.info.name;
                      return isNotNullish(name) ? (
                        <LakeText numberOfLines={1} color={colors.gray[900]}>
                          {name}
                        </LakeText>
                      ) : (
                        UNKNOWN_VALUE
                      );
                    }}
                    actions={
                      isNotNullish(onboarding.accountHolder) &&
                      hasAccountHolderName && (
                        <Link
                          to={Router.HoldersDetailRoot({
                            projectId,
                            projectEnv,
                            accountHolderId: onboarding.accountHolder.id,
                          })}
                        >
                          <Icon size={20} name="arrow-right-filled" />
                        </Link>
                      )
                    }
                  />

                  <LakeLabel
                    label={t("onboarding.general.employmentStatus")}
                    type="view"
                    render={() => {
                      const employmentStatus = employmentStatuses.find(
                        ({ value }) => value === info.employmentStatus,
                      );

                      return isNotNullish(employmentStatus) ? (
                        <LakeText numberOfLines={1} color={colors.gray[900]}>
                          {t(employmentStatus.text)}
                        </LakeText>
                      ) : (
                        UNKNOWN_VALUE
                      );
                    }}
                  />

                  <LakeLabel
                    label={t("onboarding.general.monthlyIncome")}
                    type="view"
                    render={() => {
                      const monthlyIncome = monthlyIncomes.find(
                        ({ value }) => value === info.monthlyIncome,
                      );

                      return isNotNullish(monthlyIncome) ? (
                        <LakeText numberOfLines={1} color={colors.gray[900]}>
                          {t(monthlyIncome.text)}
                        </LakeText>
                      ) : (
                        UNKNOWN_VALUE
                      );
                    }}
                  />
                </ReadOnlyFieldList>
              </Tile>
            ))
            .exhaustive()}

          <Space height={24} />

          <Tile title={t("onboarding.general.address")}>
            <ReadOnlyFieldList>
              <LakeLabel
                label={t("onboarding.general.address")}
                type="view"
                render={() => {
                  const addressLine1 = onboarding.info.residencyAddress?.addressLine1;
                  const addressLine2 = onboarding.info.residencyAddress?.addressLine2;

                  return isNullish(addressLine1) && isNullish(addressLine2) ? (
                    UNKNOWN_VALUE
                  ) : (
                    <>
                      <LakeText numberOfLines={1} color={colors.gray[900]}>
                        {addressLine1 ?? ""}
                      </LakeText>

                      <LakeText numberOfLines={1} color={colors.gray[900]}>
                        {addressLine2 ?? ""}
                      </LakeText>
                    </>
                  );
                }}
              />

              <LakeLabel
                label={t("onboarding.general.postalCode")}
                type="view"
                render={() => {
                  const postalCode = onboarding.info.residencyAddress?.postalCode;
                  return isNotNullish(postalCode) ? (
                    <LakeText numberOfLines={1} color={colors.gray[900]}>
                      {onboarding.info.residencyAddress?.postalCode}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  );
                }}
              />

              <LakeLabel
                label={t("onboarding.general.city")}
                type="view"
                render={() => {
                  const city = onboarding.info.residencyAddress?.city;

                  return isNotNullish(city) ? (
                    <LakeText numberOfLines={1} color={colors.gray[900]}>
                      {onboarding.info.residencyAddress?.city}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  );
                }}
              />

              <LakeLabel
                label={t("onboarding.general.country")}
                type="view"
                render={() => {
                  return (
                    <LakeText style={styles.align} numberOfLines={1} color={colors.gray[900]}>
                      {isNotNullish(country) ? (
                        <>
                          <Flag code={country.cca2} width={14} />
                          <Space width={8} />

                          {country.name}
                        </>
                      ) : (
                        UNKNOWN_VALUE
                      )}
                    </LakeText>
                  );
                }}
              />
            </ReadOnlyFieldList>
          </Tile>
        </>

        {hasAuthTile && (
          <Tile title={t("onboarding.general.oauthParam")}>
            <ReadOnlyFieldList>
              <LakeLabel
                label={t("onboarding.general.redirectUrl")}
                type="view"
                render={() =>
                  isNotNullish(redirectUrl) ? (
                    <LakeText color={colors.gray[900]}>{redirectUrl}</LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  )
                }
                actions={
                  isNotNullishOrEmpty(redirectUrl) && (
                    <LakeCopyButton
                      valueToCopy={redirectUrl}
                      copyText={t("copyButton.copyTooltip")}
                      copiedText={t("copyButton.copiedTooltip")}
                    />
                  )
                }
              />

              <LakeLabel
                label={t("onboarding.general.oauthState")}
                type="view"
                render={() => {
                  const state = onboarding.oAuthRedirectParameters?.state;

                  return isNotNullish(state) ? (
                    <LakeText numberOfLines={1} color={colors.gray[900]}>
                      {state}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  );
                }}
              />
            </ReadOnlyFieldList>
          </Tile>
        )}
      </TileGrid>
    </>
  );
};
