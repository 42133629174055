import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { FixedListViewEmpty } from "@swan-io/lake/src/components/FixedListView";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { TilePlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors, radii, spacings } from "@swan-io/lake/src/constants/design";
import { useBreakpoint } from "@swan-io/lake/src/hooks/useBreakpoint";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { isNotEmpty, isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import {
  GetAccountMerchantProfilesDocument,
  MerchantProfileDetailFragment,
  RequestMerchantProfileUpdate,
} from "../graphql/partner";
import { formatCurrency, t } from "../utils/i18n";
import { AccountDetailMerchantPaymentMethodsListLegacy } from "./AccountDetailMerchantPaymentMethodsListLegacy";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { ImageUploadInput } from "./ImageUploadInput";
import { MerchantProfileFormLegacy } from "./MerchantProfileFormLegacy";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  alertContainer: {
    paddingHorizontal: 16,
  },
  tile: {
    paddingHorizontal: 0,
  },
  container: {
    paddingHorizontal: 32,
  },
  scrollTracker: {
    pointerEvents: "none",
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
  },
  unknownValue: {
    fontStyle: "italic",
  },
  grid: {
    paddingHorizontal: spacings[16],
    paddingVertical: spacings[4],
  },
  addNewLink: {
    borderColor: colors.gray[500],
    borderRadius: radii[8],
    borderStyle: "dashed",
    minHeight: 200,
  },
  merchantNameContainer: {
    flex: 1,
  },
  merchantNameItem: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

type Props = {
  accountId: string;
};

const IMAGE_STYLE: CSSProperties = {
  top: 0,
  left: 0,
  maxWidth: "100px",
  maxHeight: "50px",
};

const PLACEHOLDERS = Array(4).fill(null); // we render 4 placeholders on page loading
const PER_PAGE = 20;
const UNKNOWN_VALUE = <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>;

type ScrollTrackerProps = {
  onEndReached: () => void;
  onEndReachedThresholdPx?: number;
  children: ReactNode;
};

const ScrollTracker = ({
  onEndReached,
  onEndReachedThresholdPx = 200,
  children,
}: ScrollTrackerProps) => {
  const scrollTrackerRef = useRef<View>(null);

  useEffect(() => {
    const scrollTracker = scrollTrackerRef.current;
    if (scrollTracker != null) {
      const scrollTrackerElement = scrollTracker as unknown as HTMLElement;
      const intersectionObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (isNotNullish(onEndReached)) {
              onEndReached();
            }
          }
        });
      });
      intersectionObserver.observe(scrollTrackerElement);
      return () => intersectionObserver.unobserve(scrollTrackerElement);
    }
  }, [onEndReached]);

  return (
    <>
      {children}

      <View
        style={[styles.scrollTracker, { height: onEndReachedThresholdPx }]}
        ref={scrollTrackerRef}
      />
    </>
  );
};

const merchantProfileTabs = [
  {
    label: t("merchantProfiles.paymentMethods"),
    id: "paymentMethods",
  },
  {
    label: t("merchantProfiles.merchantProfile"),
    id: "merchantProfile",
  },
];

type RequestedUpdateModal = {
  pendingRequest: RequestMerchantProfileUpdate;
  visible: boolean;
  closeRequestedUpdateModal: () => void;
};

const RequestedUpdateMerchantProfileModal = ({
  pendingRequest,
  visible,
  closeRequestedUpdateModal,
}: RequestedUpdateModal) => {
  return (
    <LakeModal
      maxWidth={850}
      icon={"clock-regular"}
      title={t("merchantProfiles.updatedMerchantProfile")}
      visible={visible}
      onPressClose={closeRequestedUpdateModal}
    >
      <LakeText>{t("merchantProfiles.updatedMerchantProfile.description")}</LakeText>
      <Space height={24} />

      <Grid numColumns={2} horizontalSpace={40}>
        <Box>
          <LakeLabel
            type="view"
            color="current"
            label={t("merchantProfiles.merchantName")}
            render={() => (
              <LakeText color={colors.gray[900]}>{pendingRequest.merchantName}</LakeText>
            )}
          />

          <Separator horizontal={false} space={8} />
        </Box>

        <Box>
          <LakeLabel
            type="view"
            color="current"
            label={t("merchantProfiles.productType")}
            render={() =>
              match(pendingRequest.productType)
                .with("GiftsAndDonations", () => (
                  <LakeText color={colors.gray[900]}>
                    {t("merchantProfiles.productType.giftsAndDonations")}
                  </LakeText>
                ))
                .with("Goods", () => (
                  <LakeText color={colors.gray[900]}>
                    {t("merchantProfiles.productType.goods")}
                  </LakeText>
                ))
                .with("Services", () => (
                  <LakeText color={colors.gray[900]}>
                    {t("merchantProfiles.productType.services")}
                  </LakeText>
                ))
                .with("VirtualGoods", () => (
                  <LakeText color={colors.gray[900]}>
                    {t("merchantProfiles.productType.virtualGoods")}
                  </LakeText>
                ))
                .exhaustive()
            }
          />

          <Separator horizontal={false} space={8} />
        </Box>

        <Box>
          <LakeLabel
            type="view"
            color="current"
            label={t("merchantProfiles.expectedMonthlyPaymentVolume")}
            render={() => (
              <LakeText color={colors.gray[900]}>
                {`${pendingRequest.expectedMonthlyPaymentVolume.value} ${pendingRequest.expectedMonthlyPaymentVolume.currency}`}
              </LakeText>
            )}
          />

          <Separator horizontal={false} space={8} />
        </Box>

        <Box>
          <LakeLabel
            type="view"
            color="current"
            label={t("merchantProfiles.expectedAverageBasket")}
            render={() => (
              <LakeText color={colors.gray[900]}>
                {`${pendingRequest.expectedAverageBasket.value} ${pendingRequest.expectedAverageBasket.currency}`}
              </LakeText>
            )}
          />

          <Separator horizontal={false} space={8} />
        </Box>
      </Grid>

      <LakeLabel
        type="view"
        color="current"
        label={t("merchantProfiles.website")}
        render={() =>
          isNotNullish(pendingRequest.merchantWebsite) ? (
            <LakeText color={colors.gray[900]}> {pendingRequest.merchantWebsite}</LakeText>
          ) : (
            UNKNOWN_VALUE
          )
        }
      />

      <Separator horizontal={false} space={8} />

      <LakeLabel
        type="view"
        color="current"
        label={t("merchantProfiles.logoInputLabel")}
        render={() => (
          <ImageUploadInput
            disabled={true}
            accept={["image/png", "image/jpeg"]}
            imageUri={pendingRequest.merchantLogoUrl ?? ""}
            minHeight={100}
            acceptedImageUpload={t("merchantProfiles.logo.acceptedImageUpload")}
            onImageChange={() => {
              return;
            }}
            buttonText={t("merchantProfiles.logoButtonText")}
            onImageLoadError={() => {
              return;
            }}
            onImageRejected={() => {
              showToast({
                variant: "error",
                title: t("toast.error.wrongLogoMultiFormat"),
              });
            }}
          />
        )}
      />
    </LakeModal>
  );
};

const MerchantProfileTile = ({
  merchantProfile,
  reload,
}: {
  merchantProfile: MerchantProfileDetailFragment;
  reload: () => void;
}) => {
  const [activeTab, setActiveTab] = useState(merchantProfileTabs[0]?.id);

  const [
    editMerchantProfileVisible,
    { open: openEditMerchantProfileModal, close: closeEditMerchantProfileModal },
  ] = useDisclosure(false);

  const [
    requestedUpdateModalVisible,
    { open: openRequestedUpdateModal, close: closeRequestedUpdateModal },
  ] = useDisclosure(false);

  const requestedUpdate = merchantProfile.requestedMerchantProfileUpdates?.find(
    ({ status }) => status === "PendingReview",
  );

  return (
    <>
      <MerchantProfileFormLegacy
        visible={editMerchantProfileVisible}
        accountId={merchantProfile.accountId}
        onClose={closeEditMerchantProfileModal}
        reload={reload}
        defaultValues={merchantProfile}
      />

      {requestedUpdate && (
        <RequestedUpdateMerchantProfileModal
          visible={requestedUpdateModalVisible}
          closeRequestedUpdateModal={closeRequestedUpdateModal}
          pendingRequest={requestedUpdate}
        />
      )}

      <Tile flexGrow={1} flexShrink={1} style={styles.tile} key={merchantProfile.id}>
        <Box direction="row" style={styles.container}>
          <Box direction="row" style={styles.merchantNameContainer} alignItems="center">
            {isNotNullish(merchantProfile.merchantLogoUrl) ? (
              <img src={merchantProfile.merchantLogoUrl} style={IMAGE_STYLE} />
            ) : (
              <LakeHeading variant="h3" level={3} style={styles.merchantNameItem}>
                {merchantProfile.merchantName}
              </LakeHeading>
            )}

            <Space width={24} />

            {match(merchantProfile.statusInfo.status)
              .with("Enabled", () => (
                <Tag color="positive">{t("merchantProfiles.status.enabled")}</Tag>
              ))
              .with("PendingReview", () => (
                <Tag color="shakespear">{t("merchantProfiles.status.pendingReview")}</Tag>
              ))
              .with("Rejected", () => (
                <Tag color="negative">{t("merchantProfiles.status.rejected")}</Tag>
              ))
              .with("Suspended", () => (
                <Tag color="negative">{t("merchantProfiles.status.suspended")}</Tag>
              ))
              .otherwise(() => null)}
          </Box>

          <Space width={8} />

          <Box direction="row" alignItems="center">
            {requestedUpdate && (
              <TrackPressable action="See merchant profile pending updates">
                <LakeButton
                  ariaLabel={t("merchantProfile.seePendingUpdates")}
                  onPress={openRequestedUpdateModal}
                  icon="clock-regular"
                  mode="tertiary"
                  size="small"
                />
              </TrackPressable>
            )}

            <TrackPressable action="Edit merchant profile">
              <LakeButton
                ariaLabel={t("merchantProfile.edit")}
                onPress={openEditMerchantProfileModal}
                icon="edit-regular"
                mode="tertiary"
                size="small"
              />
            </TrackPressable>
          </Box>
        </Box>

        <Space height={24} />

        <TabView
          activeTabId={activeTab}
          onChange={setActiveTab}
          tabs={merchantProfileTabs}
          otherLabel={t("common.tabs.other")}
          padding={32}
        />

        {match(activeTab)
          .with("paymentMethods", () => (
            <AccountDetailMerchantPaymentMethodsListLegacy merchantProfile={merchantProfile} />
          ))
          .with("merchantProfile", () => (
            <>
              {match(merchantProfile.statusInfo)
                .with({ status: "PendingReview" }, () => (
                  <LakeAlert
                    anchored={true}
                    variant="info"
                    title={t("merchantProfiles.alert.pendingReview")}
                  />
                ))
                .with({ status: "Rejected" }, () => (
                  <LakeAlert
                    anchored={true}
                    variant="error"
                    title={t("merchantProfiles.alert.rejected")}
                  />
                ))
                .with({ status: "Suspended" }, () => (
                  <LakeAlert
                    anchored={true}
                    variant="error"
                    title={t("merchantProfiles.alert.suspended")}
                  />
                ))
                .otherwise(() => null)}

              <Box style={styles.container}>
                <Space height={24} />

                <ReadOnlyFieldList>
                  <LakeLabel
                    label={t("merchantProfiles.id")}
                    type="view"
                    render={() => (
                      <LakeText color={colors.gray[900]}>{merchantProfile.id}</LakeText>
                    )}
                    actions={
                      <LakeCopyButton
                        valueToCopy={merchantProfile.id}
                        copyText={t("copyButton.copyTooltip")}
                        copiedText={t("copyButton.copiedTooltip")}
                      />
                    }
                  />

                  <LakeLabel
                    type="view"
                    label={t("merchantProfilesDetail.name")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>{merchantProfile.merchantName}</LakeText>
                    )}
                  />

                  <LakeLabel
                    type="view"
                    label={t("merchantProfilesDetail.productType")}
                    render={() =>
                      match(merchantProfile.productType)
                        .with("GiftsAndDonations", () => (
                          <LakeText color={colors.gray[900]}>
                            {t("merchantProfiles.productType.giftsAndDonations")}
                          </LakeText>
                        ))
                        .with("Goods", () => (
                          <LakeText color={colors.gray[900]}>
                            {t("merchantProfiles.productType.goods")}
                          </LakeText>
                        ))
                        .with("Services", () => (
                          <LakeText color={colors.gray[900]}>
                            {t("merchantProfiles.productType.services")}
                          </LakeText>
                        ))
                        .with("VirtualGoods", () => (
                          <LakeText color={colors.gray[900]}>
                            {t("merchantProfiles.productType.virtualGoods")}
                          </LakeText>
                        ))
                        .exhaustive()
                    }
                  />

                  <LakeLabel
                    type="view"
                    label={t("merchantProfilesDetail.expectedMonthlyPaymentVolume")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {isNotEmpty(merchantProfile.expectedMonthlyPaymentVolume.value)
                          ? formatCurrency(
                              Number(merchantProfile.expectedMonthlyPaymentVolume.value),
                              merchantProfile.expectedMonthlyPaymentVolume.currency,
                            )
                          : "-"}
                      </LakeText>
                    )}
                  />

                  <LakeLabel
                    type="view"
                    label={t("merchantProfilesDetail.expectedAverageBasket")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {merchantProfile.expectedAverageBasket.value !== ""
                          ? formatCurrency(
                              Number(merchantProfile.expectedAverageBasket.value),
                              merchantProfile.expectedAverageBasket.currency,
                            )
                          : "-"}
                      </LakeText>
                    )}
                  />

                  <LakeLabel
                    label={t("merchantProfilesDetail.website")}
                    type="view"
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {merchantProfile.merchantWebsite ?? UNKNOWN_VALUE}
                      </LakeText>
                    )}
                  />
                </ReadOnlyFieldList>
              </Box>
            </>
          ))
          .otherwise(() => null)}
      </Tile>
    </>
  );
};

export const AccountDetailMerchantListLegacy = ({ accountId }: Props) => {
  const [data, { isLoading, reload, setVariables }] = useQuery(GetAccountMerchantProfilesDocument, {
    accountId,
    first: PER_PAGE,
  });

  const [formModalVisible, formModal] = useDisclosure(false);
  const numColumns = useBreakpoint(1200) ? 2 : 1;

  const merchantProfiles = data
    .mapOk(({ account }) => account?.merchantProfiles)
    .flatMapOk(value => (value == null ? AsyncData.NotAsked() : AsyncData.Done(Result.Ok(value))));

  return (
    <>
      {match(merchantProfiles)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <Grid numColumns={numColumns} horizontalSpace={32} verticalSpace={32} style={styles.grid}>
            {PLACEHOLDERS.map((_, index) => (
              <TilePlaceholder key={`placeholder-${index}`} />
            ))}
          </Grid>
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), list => (
          <Connection connection={list}>
            {list => (
              <>
                <MerchantProfileFormLegacy
                  accountId={accountId}
                  onClose={formModal.close}
                  reload={() => {
                    reload();
                  }}
                  visible={formModalVisible}
                />

                {list.totalCount === 0 ? (
                  <Box justifyContent="center" style={commonStyles.fill}>
                    <FixedListViewEmpty
                      borderedIcon={true}
                      icon="lake-inbox-empty"
                      title={t("merchantProfiles.empty.title")}
                      subtitle={t("merchantProfiles.empty.subtitle")}
                    >
                      <Space height={24} />

                      <TrackPressable action="New merchant profile">
                        <LakeButton
                          size="small"
                          icon="add-circle-filled"
                          color="current"
                          onPress={formModal.open}
                        >
                          {t("merchantProfiles.new")}
                        </LakeButton>
                      </TrackPressable>
                    </FixedListViewEmpty>
                  </Box>
                ) : (
                  <>
                    <Box style={styles.alertContainer}>
                      <LakeAlert variant={"warning"} title={t("merchantProfiles.alert.title")}>
                        <LakeText>{t("merchantProfiles.alert.subtitle")}</LakeText>
                      </LakeAlert>
                    </Box>

                    <Space height={24} />

                    <ScrollTracker
                      onEndReached={() => {
                        if (list.pageInfo.hasNextPage ?? false) {
                          setVariables({ after: list.pageInfo.endCursor ?? undefined });
                        }
                      }}
                    >
                      <Grid
                        numColumns={numColumns}
                        horizontalSpace={32}
                        verticalSpace={32}
                        style={styles.grid}
                      >
                        {list.edges.map(({ node: merchantProfile }) => (
                          <MerchantProfileTile
                            key={merchantProfile.id}
                            merchantProfile={merchantProfile}
                            reload={() => {
                              reload();
                            }}
                          />
                        ))}

                        {isLoading
                          ? PLACEHOLDERS.map((_, index) => (
                              <TilePlaceholder key={`placeholder-${index}`} />
                            ))
                          : null}

                        <TrackPressable action="New merchant profile">
                          <LakeButton
                            onPress={formModal.open}
                            mode="secondary"
                            direction="column"
                            icon="add-circle-regular"
                            color="gray"
                            iconSize={28}
                            style={styles.addNewLink}
                          >
                            {t("merchantProfiles.addNewMerchantProfile")}
                          </LakeButton>
                        </TrackPressable>
                      </Grid>
                    </ScrollTracker>
                  </>
                )}
              </>
            )}
          </Connection>
        ))
        .exhaustive()}

      <Space height={32} />
    </>
  );
};
