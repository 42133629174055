import { useMutation } from "@swan-io/graphql-client";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { useCallback, useMemo, useRef } from "react";
import { UpdateWebhookSubscriptionDocument, WebhookSubscriptionQuery } from "../graphql/partner";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { TrackPressable } from "./TrackPressable";
import { WebhookDeleteAction } from "./WebhookDeleteAction";
import { SaveRef, WebhookOptions, WebhookSubscriptionEditor } from "./WebhookSubscriptionEditor";

type Props = {
  webhookSubscriptionId: string;
  webhookSubscription: NonNullable<WebhookSubscriptionQuery["webhookSubscription"]>;
  onSave: () => void;
  reexecuteQuery: () => void;
};

export const WebhookSubscriptionDetailGeneral = ({
  webhookSubscriptionId,
  webhookSubscription,
  reexecuteQuery,
}: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const canEditWebhooks = usePermissions(projectEnv).webhook.write;

  const editorRef = useRef<SaveRef | undefined>();

  const initialEditorState = useMemo(() => {
    return {
      enabled: webhookSubscription.statusInfo.status === "Enabled",
      label: webhookSubscription.label,
      endpoint: webhookSubscription.endpoint,
      eventTypes: webhookSubscription.eventTypes,
      secret: webhookSubscription.secret,
    };
  }, [webhookSubscription]);

  const onPressSave = useCallback(() => {
    if (isNotNullish(editorRef.current)) {
      editorRef.current.save();
    }
  }, []);

  const [updateWebhookSubscription, webhookSubscriptionUpdate] = useMutation(
    UpdateWebhookSubscriptionDocument,
  );

  const onSave = (values: WebhookOptions) =>
    updateWebhookSubscription({
      input: {
        status: values.enabled ? "Enabled" : "Disabled",
        id: webhookSubscriptionId,
        endpoint: values.endpoint,
        label: values.label,
        eventTypes: values.eventTypes,
        secret: values.secret === "" ? null : values.secret,
      },
    })
      .mapOk(data => data.updateWebhookSubscription)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        reexecuteQuery();
        showToast({ variant: "success", title: t("toast.success.webhookUpdated") });
      })
      .tapError((error: unknown) => {
        showToast({ variant: "error", error, title: t("toast.error.webhookUpdated") });
      });

  return (
    <>
      <Tile>
        <WebhookSubscriptionEditor
          ref={editorRef}
          showActivationButtons={true}
          initialEditorState={initialEditorState}
          onSave={values => void onSave(values)}
        />
      </Tile>

      <LakeButtonGroup>
        <TrackPressable action="Save webhook">
          <LakeTooltip
            placement="left"
            content={t("common.action.denied")}
            disabled={canEditWebhooks}
          >
            <LakeButton
              color="current"
              onPress={onPressSave}
              loading={webhookSubscriptionUpdate.isLoading()}
              disabled={!canEditWebhooks}
            >
              {t("common.save")}
            </LakeButton>
          </LakeTooltip>
        </TrackPressable>

        <LakeTooltip
          placement="left"
          content={t("common.action.denied")}
          disabled={canEditWebhooks}
        >
          <WebhookDeleteAction
            webhookId={webhookSubscriptionId}
            webhookName={webhookSubscription.label}
            onSuccess={() => {
              Router.push("DevelopersWebhooksRoot", { projectId, projectEnv });
            }}
          >
            {({ onPress }) => (
              <TrackPressable action="Delete webhook">
                <LakeButton
                  mode="secondary"
                  color="negative"
                  onPress={event => {
                    event.preventDefault();
                    onPress();
                  }}
                  icon="delete-filled"
                  ariaLabel={t("webhook.delete")}
                  disabled={!canEditWebhooks}
                />
              </TrackPressable>
            )}
          </WebhookDeleteAction>
        </LakeTooltip>
      </LakeButtonGroup>
    </>
  );
};
