import { useFocusReset } from "@swan-io/chicane";
import { ClientContext } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Breadcrumbs, BreadcrumbsRoot, useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { ReactNode, useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { ValueOf } from "type-fest";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { BillingChargeManualBankingFeeSimulatorPage } from "../pages/simulator/BillingChargeManualBankingFee";
import { BillingGenerateInvoiceSimulatorPage } from "../pages/simulator/BillingGenerateInvoiceSimulatorPage";
import { CardCreditReverseSimulatorPage } from "../pages/simulator/CardCreditReverseSimulatorPage";
import { CardDebitReverseSimulatorPage } from "../pages/simulator/CardDebitReverseSimulatorPage";
import { CardOutAdviceSimulatorPage } from "../pages/simulator/CardOutAdviceSimulatorPage";
import { CardOutAuthorizationReleaseSimulatorPage } from "../pages/simulator/CardOutAuthorizationReleaseSimulatorPage";
import { CardOutAuthorizationSimulatorPage } from "../pages/simulator/CardOutAuthorizationSimulatorPage";
import { CardOutCreditSimulatorPage } from "../pages/simulator/CardOutCreditSimulatorPage";
import { CardOutDebitSimulatorPage } from "../pages/simulator/CardOutDebitSimulatorPage";
import { CardTransactionEnrichmentPage } from "../pages/simulator/CardTransactionEnrichmentPage";
import { CreateSandboxUserIdentification } from "../pages/simulator/CreateSandboxUserIdentificationPage";
import { IctBookingSimulatorPage } from "../pages/simulator/IctBookingSimulatorPage";
import { IctReceptionSimulatorPage } from "../pages/simulator/IctReceptionSimulatorPage";
import { IctRejectionSimulatorPage } from "../pages/simulator/IctRejectionSimulatorPage";
import { PhysicalCardDeliverySimulatorPage } from "../pages/simulator/PhysicalCardDeliverySimulatorPage";
import { PhysicalCardReceptionSimulatorPage } from "../pages/simulator/PhysicalCardReceptionSimulatorPage";
import { PhysicalCardRenewalSimulatorPage } from "../pages/simulator/PhysicalCardRenewalSimulatorPage";
import { PromoteAccountHolderToProjectOwnerPage } from "../pages/simulator/PromoteAccountHolderToProjectOwnerPage";
import { RequestSupportingDocumentsPage } from "../pages/simulator/RequestSupportingDocumentsPage";
import { SctInRecallSimulatorPage } from "../pages/simulator/SctInRecallSimulatorPage";
import { SctInReceptionSimulatorPage } from "../pages/simulator/SctInReceptionSimulatorPage";
import { SctInReturnSimulatorPage } from "../pages/simulator/SctInReturnSimulatorPage";
import { SctOutBookingSimulatorPage } from "../pages/simulator/SctOutBookingSimulatorPage";
import { SctOutRecallSimulatorPage } from "../pages/simulator/SctOutRecallSimulatorPage";
import { SctOutRejectionSimulatorPage } from "../pages/simulator/SctOutRejectionSimulatorPage";
import { SctOutReturnSimulatorPage } from "../pages/simulator/SctOutReturnSimulatorPage";
import { SddOutCancelSimulatorPage } from "../pages/simulator/SddOutCancelSimulatorPage";
import { SddOutReceptionSimulatorPage } from "../pages/simulator/SddOutReceptionSimulatorPage";
import { SddOutRejectSimulatorPage } from "../pages/simulator/SddOutRejectSimulatorPage";
import { SddOutReturnSimulatorPage } from "../pages/simulator/SddOutReturnSimulatorPage";
import { SddOutReverseSimulatorPage } from "../pages/simulator/SddOutReverseSimulatorPage";
import { UpdateAccountHolderPage } from "../pages/simulator/UpdateAccountHolderSimulatorPage";
import { UpdateCapitalDepositDocumentStatusPage } from "../pages/simulator/UpdateCapitalDepositDocumentStatusPage";
import { UpdateFundingLimitSettingsChangeRequestPage } from "../pages/simulator/UpdateFundingLimitSettingsChangeRequestPage";
import { UpdateMerchantPaymentMethodPage } from "../pages/simulator/UpdateMerchantPaymentMethodPage";
import { UpdateMerchantProfileStatusPage } from "../pages/simulator/UpdateMerchantProfileStatusPage";
import { UpdateSandboxUserIdentification } from "../pages/simulator/UpdateSandboxUserIdentificationPage";
import { UpdateSupportingDocumentCollectionStatusPage } from "../pages/simulator/UpdateSupportingDocumentCollectionStatusPage";
import { UpdateSupportingDocumentStatusPage } from "../pages/simulator/UpdateSupportingDocumentStatusPage";
import { ValidateCapitalDepositPage } from "../pages/simulator/ValidateCapitalDepositPage";
import { XPayDigitalizationSimulatorPage } from "../pages/simulator/XPayDigitalizationSimulatorPage";
import { XPayFinishInAppSimulatorPage } from "../pages/simulator/XPayFinishInAppSimulatorPage";
import {
  sandboxPartnerAdminByProjectIdClient,
  sandboxPartnerAdminByProjectIdClient__projectMember,
  sandboxPartnerAdminClient,
} from "../utils/gql";
import { t } from "../utils/i18n";
import { TrackingProvider } from "../utils/matomo";
import { RouteParams, Router, simulatorSubRoutes, simulatorTopLevelRoutes } from "../utils/routes";
import { useTgglFlag } from "../utils/tggl";
import { LakeDocumentationLink } from "./DocumentationLink";
import { SimulatorHome } from "./SimulatorHome";

const styles = StyleSheet.create({
  header: { zIndex: 15 },
});

type SimulatorSubRoute = (typeof simulatorSubRoutes)[number];

type SimulatorSubRouteTab = ValueOf<{
  [K in SimulatorSubRoute]: {
    url: K;
    label: string;
    render: (params: RouteParams[K]) => ReactNode;
  };
}>;

type SimulatorTabsProps = {
  title: string;
  link: string;
  selected?: SimulatorSubRoute;
  tabs: SimulatorSubRouteTab[];
  currentRoute: string;
};

const SimulatorTabs = ({ title, link, tabs, selected, currentRoute }: SimulatorTabsProps) => {
  const { projectId } = useProjectInfo();

  const containerRef = useRef<View | null>(null);
  const selectedTab = tabs.find(({ url }) => url === selected) ?? undefined;
  const route = Router.useRoute(simulatorSubRoutes);

  const siblings = useMemo(
    () => [
      {
        url: Router.SandboxDevelopersSimulatorSepaCreditTransferReceptionIn({ projectId }),
        isMatching: currentRoute === "SandboxDevelopersSimulatorSepaCreditTransferArea",
        label: t("simulatorPage.SCT"),
      },
      {
        url: Router.SandboxDevelopersSimulatorSepaDirectDebitReceptionOut({ projectId }),
        isMatching: currentRoute === "SandboxDevelopersSimulatorSepaDirectDebitArea",
        label: t("simulatorPage.SDD"),
      },
      {
        url: Router.SandboxDevelopersSimulatorInternationalCreditTransferBooking({ projectId }),
        isMatching: currentRoute === "SandboxDevelopersSimulatorInternationalCreditTransferArea",
        label: t("simulatorPage.ICT"),
      },
      {
        url: Router.SandboxDevelopersSimulatorCardAuthorizationOut({ projectId }),
        isMatching: currentRoute === "SandboxDevelopersSimulatorCardArea",
        label: t("simulatorPage.card"),
      },
      {
        url: Router.SandboxDevelopersSimulatorXPaySimulateDigitalization({ projectId }),
        isMatching: currentRoute === "SandboxDevelopersSimulatorXPayArea",
        label: t("simulatorPage.xPay"),
      },
      {
        url: Router.SandboxDevelopersSimulatorAccountHolderUpdateVerificationStatus({
          projectId,
        }),
        isMatching: currentRoute === "SandboxDevelopersSimulatorAccountHolderArea",
        label: t("simulatorPage.accountHolder"),
      },
      {
        url: Router.SandboxDevelopersSimulatorMerchantAcquiringUpdateProfileStatus({ projectId }),
        isMatching: currentRoute === "SandboxDevelopersSimulatorMerchantAcquiringArea",
        label: t("simulatorPage.merchantPaymentCollection"),
      },
      {
        url: Router.SandboxDevelopersSimulatorBillingGenerateInvoice({ projectId }),
        isMatching: currentRoute === "SandboxDevelopersSimulatorBillingArea",
        label: t("simulatorPage.billing"),
      },
      {
        url: Router.SandboxDevelopersSimulatorCapitalDepositValidate({ projectId }),
        isMatching: currentRoute === "SandboxDevelopersSimulatorCapitalDepositArea",
        label: t("simulatorPage.capitalDeposit"),
      },
    ],
    [projectId, currentRoute],
  );

  useCrumb(
    useMemo(() => {
      return {
        label: title,
        link,
        siblings,
      };
    }, [title, link, siblings]),
  );

  useFocusReset({ route, containerRef });

  if (route == null) {
    return <NotFoundPage />;
  }

  return (
    <>
      <TabView
        hideIfSingleItem={false}
        tabs={tabs.map(tab => ({
          url: Router[tab.url]({ projectId }),
          label: tab.label,
        }))}
        otherLabel={t("common.tabs.other")}
      />

      <Space height={20} />

      <LakeScrollView>
        <View ref={containerRef}>{selectedTab?.render(route.params) ?? <NotFoundPage />}</View>
        <Space height={16} />
      </LakeScrollView>
    </>
  );
};

export const SimulatorArea = () => {
  const { projectId, projectEnv } = useProjectInfo();

  const route = Router.useRoute(simulatorTopLevelRoutes);
  const subRoute = Router.useRoute(simulatorSubRoutes);
  const rootLevelCrumbs = useMemo(
    () => [
      {
        label: t("simulator.allSimulatorEvents"),
        link: Router.DevelopersSimulatorRoot({ projectId, projectEnv }),
      },
    ],
    [projectId, projectEnv],
  );

  const shouldUseProjectMemberToken = useTgglFlag("dashboardProjectMemberToken").getOr(false);

  return (
    <BreadcrumbsRoot rootLevelCrumbs={rootLevelCrumbs}>
      <Box direction="row" justifyContent="spaceBetween" alignItems="center" style={styles.header}>
        <Breadcrumbs />

        <Box direction="row" justifyContent="end" alignItems="center">
          <LakeDocumentationLink to="simulatorApi">{t("common.learnMore")}</LakeDocumentationLink>
          <Space width={16} />
          <InformationTooltip text={t("simulator.subtitle")} />
        </Box>
      </Box>

      <Space height={16} />

      <TrackingProvider category="Simulators">
        {match(route)
          .with({ name: "DevelopersSimulatorRoot" }, () => (
            <LakeScrollView>
              <SimulatorHome />
              <Space height={24} />
            </LakeScrollView>
          ))
          .with({ name: "SandboxDevelopersSimulatorSepaCreditTransferArea" }, ({ name }) => {
            return (
              <SimulatorTabs
                currentRoute={name}
                title={t("simulatorPage.SCT")}
                link={Router.SandboxDevelopersSimulatorSepaCreditTransferReceptionIn({ projectId })}
                selected={subRoute?.name}
                tabs={[
                  {
                    url: "SandboxDevelopersSimulatorSepaCreditTransferReceptionIn",
                    label: t("simulatorPage.receptionIn"),
                    render: () => <SctInReceptionSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorSepaCreditTransferReturnIn",
                    label: t("simulatorPage.returnIn"),
                    render: () => <SctInReturnSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorSepaCreditTransferRecallIn",
                    label: t("simulatorPage.recallIn"),
                    render: () => <SctInRecallSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorSepaCreditTransferBookingOut",
                    label: t("simulatorPage.bookingOut"),
                    render: () => <SctOutBookingSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorSepaCreditTransferRejectionOut",
                    label: t("simulatorPage.rejectionOut"),
                    render: () => <SctOutRejectionSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorSepaCreditTransferRecallOut",
                    label: t("simulatorPage.recallOut"),
                    render: () => <SctOutRecallSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorSepaCreditTransferReturnOut",
                    label: t("simulatorPage.returnOut"),
                    render: () => <SctOutReturnSimulatorPage />,
                  },
                ]}
              />
            );
          })
          .with({ name: "SandboxDevelopersSimulatorSepaDirectDebitArea" }, ({ name }) => {
            return (
              <SimulatorTabs
                currentRoute={name}
                title={t("simulatorPage.SDD")}
                link={Router.SandboxDevelopersSimulatorSepaDirectDebitReceptionOut({ projectId })}
                selected={subRoute?.name}
                tabs={[
                  {
                    url: "SandboxDevelopersSimulatorSepaDirectDebitReceptionOut",
                    label: t("simulatorPage.receptionSddOut"),
                    render: () => <SddOutReceptionSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorSepaDirectDebitCancelOut",
                    label: t("simulatorPage.cancelSddOut"),
                    render: () => <SddOutCancelSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorSepaDirectDebitRejectOut",
                    label: t("simulatorPage.rejectSddOut"),
                    render: () => <SddOutRejectSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorSepaDirectDebitReturnOut",
                    label: t("simulatorPage.returnSddOut"),
                    render: () => <SddOutReturnSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorSepaDirectDebitReverseOut",
                    label: t("simulatorPage.reverseSddOut"),
                    render: () => <SddOutReverseSimulatorPage />,
                  },
                ]}
              />
            );
          })

          .with(
            { name: "SandboxDevelopersSimulatorInternationalCreditTransferArea" },
            ({ name }) => {
              return (
                <SimulatorTabs
                  currentRoute={name}
                  title={t("simulatorPage.ICT")}
                  link={Router.SandboxDevelopersSimulatorInternationalCreditTransferBooking({
                    projectId,
                  })}
                  selected={subRoute?.name}
                  tabs={[
                    {
                      url: "SandboxDevelopersSimulatorInternationalCreditTransferBooking",
                      label: t("simulatorPage.ictBooking"),
                      render: () => <IctBookingSimulatorPage />,
                    },
                    {
                      url: "SandboxDevelopersSimulatorInternationalCreditTransferReception",
                      label: t("simulatorPage.ictReception"),
                      render: () => <IctReceptionSimulatorPage />,
                    },
                    {
                      url: "SandboxDevelopersSimulatorInternationalCreditTransferRejection",
                      label: t("simulatorPage.ictRejection"),
                      render: () => <IctRejectionSimulatorPage />,
                    },
                  ]}
                />
              );
            },
          )

          .with({ name: "SandboxDevelopersSimulatorCardArea" }, ({ name }) => {
            return (
              <SimulatorTabs
                currentRoute={name}
                title={t("simulatorPage.card")}
                link={Router.SandboxDevelopersSimulatorCardAuthorizationOut({ projectId })}
                selected={subRoute?.name}
                tabs={[
                  {
                    url: "SandboxDevelopersSimulatorCardAuthorizationOut",
                    label: t("simulatorPage.authorizeCardOut"),
                    render: () => <CardOutAuthorizationSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorCardAdviceOut",
                    label: t("simulatorPage.adviceCardOut"),
                    render: () => <CardOutAdviceSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorCardAuthorizationReleaseOut",
                    label: t("simulatorPage.authorizeReleaseCardOut"),
                    render: () => <CardOutAuthorizationReleaseSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorCardDebitOut",
                    label: t("simulatorPage.debitCardOut"),
                    render: () => <CardOutDebitSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorCardDebitReversal",
                    label: t("simulatorPage.reverseDebitCard"),
                    render: () => <CardDebitReverseSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorCardCreditOut",
                    label: t("simulatorPage.creditCardOut"),
                    render: () => <CardOutCreditSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorCardCreditReversal",
                    label: t("simulatorPage.reverseCreditCard"),
                    render: () => <CardCreditReverseSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorCardSimulationPhysicalCardDelivery",
                    label: t("simulatorPage.simulationPhysicalCardDelivery"),
                    render: () => <PhysicalCardDeliverySimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorCardSimulationPhysicalCardReception",
                    label: t("simulatorPage.simulationPhysicalCardReception"),
                    render: () => <PhysicalCardReceptionSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorCardSimulationPhysicalCardRenewal",
                    label: t("simulatorPage.simulationPhysicalCardRenewal"),
                    render: () => <PhysicalCardRenewalSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorCardTransactionEnrichment",
                    label: t("simulatorPage.cardTransactionEnrichment"),
                    render: () => <CardTransactionEnrichmentPage />,
                  },
                ]}
              />
            );
          })
          .with({ name: "SandboxDevelopersSimulatorXPayArea" }, ({ name }) => {
            return (
              <SimulatorTabs
                currentRoute={name}
                title={t("simulatorPage.xPay")}
                link={Router.SandboxDevelopersSimulatorXPaySimulateDigitalization({ projectId })}
                selected={subRoute?.name}
                tabs={[
                  {
                    url: "SandboxDevelopersSimulatorXPaySimulateDigitalization",
                    label: t("simulatorPage.xPay.simulateDigitalization"),
                    render: () => <XPayDigitalizationSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorXPayFinishInApp",
                    label: t("simulatorPage.xPay.finishInApp"),
                    render: () => <XPayFinishInAppSimulatorPage />,
                  },
                ]}
              />
            );
          })
          .with({ name: "SandboxDevelopersSimulatorAccountHolderArea" }, ({ name }) => {
            return (
              <SimulatorTabs
                currentRoute={name}
                title={t("simulatorPage.accountHolder")}
                link={Router.SandboxDevelopersSimulatorAccountHolderUpdateVerificationStatus({
                  projectId,
                })}
                selected={subRoute?.name}
                tabs={[
                  {
                    url: "SandboxDevelopersSimulatorAccountHolderUpdateVerificationStatus",
                    label: t("simulatorPage.accountHolder.updateVerificationStatus"),
                    render: () => <UpdateAccountHolderPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorAccountHolderUpdateFundingLimitSettingsChangeRequest",
                    label: t("simulatorPage.accountHolder.updateFundingLimitSettingsChangeRequest"),
                    render: () => <UpdateFundingLimitSettingsChangeRequestPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorAccountHolderUpdateSupportingDocumentCollectionStatus",
                    label: t(
                      "simulatorPage.accountHolder.updateSupportingDocumentCollectionStatus",
                    ),
                    render: () => <UpdateSupportingDocumentCollectionStatusPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorAccountHolderUpdateSupportingDocumentStatus",
                    label: t("simulatorPage.accountHolder.updateSupportingDocumentStatus"),
                    render: () => <UpdateSupportingDocumentStatusPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorAccountHolderRequestSupportingDocuments",
                    label: t("simulatorPage.accountHolder.requestSupportingDocuments"),
                    render: () => <RequestSupportingDocumentsPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorPromoteAccountHolderToProjectOwner",
                    label: t("simulatorPage.accountHolder.promoteAccountHolderToProjectOwner"),
                    render: () => <PromoteAccountHolderToProjectOwnerPage />,
                  },
                ]}
              />
            );
          })
          .with({ name: "SandboxDevelopersSimulatorMerchantAcquiringArea" }, ({ name }) => {
            return (
              <SimulatorTabs
                currentRoute={name}
                title={t("simulatorPage.merchantPaymentCollection")}
                link={Router.SandboxDevelopersSimulatorMerchantAcquiringUpdateProfileStatus({
                  projectId,
                })}
                selected={subRoute?.name}
                tabs={[
                  {
                    url: "SandboxDevelopersSimulatorMerchantAcquiringUpdateProfileStatus",
                    label: t("simulatorPage.merchantPaymentCollection.updateMerchantProfileStatus"),
                    render: () => <UpdateMerchantProfileStatusPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorMerchantAcquiringUpdatePaymentProductStatus",
                    label: t(
                      "simulatorPage.merchantPaymentCollection.updateMerchantPaymentMethodStatus",
                    ),
                    render: () => <UpdateMerchantPaymentMethodPage />,
                  },
                ]}
              />
            );
          })
          .with({ name: "SandboxDevelopersSimulatorSandboxUsersArea" }, ({ name }) => {
            return (
              <ClientContext.Provider value={sandboxPartnerAdminClient}>
                <SimulatorTabs
                  currentRoute={name}
                  title={t("simulatorPage.sandboxUsers")}
                  link={Router.SandboxDevelopersSimulatorSandboxUsersCreateIdentification({
                    projectId,
                  })}
                  selected={subRoute?.name}
                  tabs={[
                    {
                      url: "SandboxDevelopersSimulatorSandboxUsersCreateIdentification",
                      label: t("simulatorPage.sandboxUsers.createIdentification"),
                      render: ({ userId }) => <CreateSandboxUserIdentification userId={userId} />,
                    },
                    {
                      url: "SandboxDevelopersSimulatorSandboxUsersUpdateIdentification",
                      label: t("simulatorPage.sandboxUsers.updateIdentification"),
                      render: () => <UpdateSandboxUserIdentification />,
                    },
                  ]}
                />
              </ClientContext.Provider>
            );
          })
          .with({ name: "SandboxDevelopersSimulatorBillingArea" }, ({ name }) => {
            return (
              <SimulatorTabs
                currentRoute={name}
                title={t("simulatorPage.billing")}
                link={Router.SandboxDevelopersSimulatorBillingGenerateInvoice({
                  projectId,
                })}
                selected={subRoute?.name}
                tabs={[
                  {
                    url: "SandboxDevelopersSimulatorBillingGenerateInvoice",
                    label: t("simulatorPage.billing.generateInvoice"),
                    render: () => <BillingGenerateInvoiceSimulatorPage />,
                  },
                  {
                    url: "SandboxDevelopersSimulatorBillingChargeManualBankingFee",
                    label: t("simulatorPage.billing.chargeManualBankingFee"),
                    render: () => <BillingChargeManualBankingFeeSimulatorPage />,
                  },
                ]}
              />
            );
          })
          .with(
            { name: "SandboxDevelopersSimulatorCapitalDepositArea" },
            ({ name, params: { projectId } }) => {
              return (
                <ClientContext.Provider
                  value={
                    shouldUseProjectMemberToken
                      ? sandboxPartnerAdminByProjectIdClient__projectMember(projectId)
                      : sandboxPartnerAdminByProjectIdClient(projectId)
                  }
                >
                  <SimulatorTabs
                    currentRoute={name}
                    title={t("simulatorPage.capitalDeposit")}
                    link={Router.SandboxDevelopersSimulatorCapitalDepositValidate({
                      projectId,
                    })}
                    selected={subRoute?.name}
                    tabs={[
                      {
                        url: "SandboxDevelopersSimulatorCapitalDepositValidate",
                        label: t("simulatorPage.capitalDeposit.validateCapitalDeposit"),
                        render: () => <ValidateCapitalDepositPage />,
                      },
                      {
                        url: "SandboxDevelopersSimulatorCapitalDepositUpdateDocument",
                        label: t("simulatorPage.capitalDeposit.updateDocumentStatus"),
                        render: () => <UpdateCapitalDepositDocumentStatusPage />,
                      },
                    ]}
                  />
                </ClientContext.Provider>
              );
            },
          )
          .otherwise(() => (
            <NotFoundPage />
          ))}
      </TrackingProvider>
    </BreadcrumbsRoot>
  );
};
