import { Array, AsyncData, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { colors } from "@swan-io/lake/src/constants/design";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import {
  isNotNullish,
  isNotNullishOrEmpty,
  isNullishOrEmpty,
} from "@swan-io/lake/src/utils/nullish";
import { Request, badStatusToError } from "@swan-io/request";
import {
  Document,
  SupportingDocumentCollection,
} from "@swan-io/shared-business/src/components/SupportingDocumentCollection";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import dayjs from "dayjs";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import {
  CapitalDepositDocumentType,
  GenerateCapitalDepositDocumentUploadUrlDocument,
  GetCapitalDepositShareholderDocument,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { formatCurrency, locale, t } from "../utils/i18n";
import { Router, capitalDepositShareholderRoutes } from "../utils/routes";
import { getCapitalDepositDocumentRejectionReason } from "../utils/templateTranslations";
import { ErrorView } from "./ErrorView";

type Props = {
  shareholderId: string;
  capitalDepositId: string;
};

const styles = StyleSheet.create({
  unknownValue: {
    fontStyle: "italic",
  },
});

const UNKNOWN_VALUE = <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>;

const readonlyPurposes: CapitalDepositDocumentType[] = ["ProofOfIdentity"];

export const CapitalDepositShareholderArea = ({ shareholderId, capitalDepositId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(capitalDepositShareholderRoutes);

  const [data] = useQuery(GetCapitalDepositShareholderDocument, { id: shareholderId });

  const [generateCapitalDepositDocument] = useMutation(
    GenerateCapitalDepositDocumentUploadUrlDocument,
  );

  const shareholder = data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(({ shareholder }) => Option.fromNullable(shareholder))
    .toNull();

  const shareholderName = match(shareholder?.info)
    .with({ __typename: "CompanyShareholder" }, ({ name }) => {
      return name;
    })
    .with({ __typename: "IndividualShareholder" }, ({ firstName, lastName }) => {
      return `${firstName} ${lastName}`;
    })
    .otherwise(() => undefined);

  useCrumb(
    useMemo(
      () =>
        isNotNullish(shareholder) && isNotNullish(shareholderName)
          ? {
              label: shareholderName,
              link: Router.CapitalDepositDetailShareholderRoot({
                projectId,
                projectEnv,
                capitalDepositId,
                shareholderId: shareholder.id,
              }),
            }
          : undefined,
      [capitalDepositId, projectEnv, projectId, shareholder, shareholderName],
    ),
  );

  return match(data.mapOk(data => data.shareholder))
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TileGridPlaceholder />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.nullish)), () => <NotFoundPage />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select(P.nonNullable))), shareholder => {
      const capitalDepositAmount = shareholder.capitalDepositAmount.value;
      const docs = Array.filterMap(shareholder.documents, document =>
        match(document)
          .returnType<
            Option<
              Document<CapitalDepositDocumentType> & {
                metadata: {
                  updatedAt: string;
                  createdAt: string;
                  uploadedAt: string | null | undefined;
                };
              }
            >
          >()
          .with(P.nullish, () => Option.None())
          .with(
            { statusInfo: { __typename: "CapitalDepositDocumentPendingStatusInfo" } },
            document =>
              Option.Some({
                purpose: document.type,
                metadata: {
                  updatedAt: document.updatedAt,
                  createdAt: document.createdAt,
                  uploadedAt: document.uploadedAt,
                },
                file: {
                  id: document.id,
                  name: t("common.document"),
                  url: document.downloadUrl ?? "",
                  statusInfo: {
                    status: "Pending",
                  },
                },
              }),
          )
          .with(
            { statusInfo: { __typename: "CapitalDepositDocumentUploadedStatusInfo" } },
            document =>
              Option.Some({
                purpose: document.type,
                metadata: {
                  updatedAt: document.updatedAt,
                  createdAt: document.createdAt,
                  uploadedAt: document.uploadedAt,
                },
                file: {
                  id: document.id,
                  name: t("common.document"),
                  url: document.downloadUrl ?? "",
                  statusInfo: {
                    status: "Uploaded",
                  },
                },
              }),
          )
          .with(
            { statusInfo: { __typename: "CapitalDepositDocumentRefusedStatusInfo" } },
            document =>
              Option.Some({
                purpose: document.type,
                metadata: {
                  updatedAt: document.updatedAt,
                  createdAt: document.createdAt,
                  uploadedAt: document.uploadedAt,
                },
                file: {
                  id: document.id,
                  name: t("common.document"),
                  url: document.downloadUrl ?? "",
                  statusInfo: {
                    status: "Refused",
                    reason: getCapitalDepositDocumentRejectionReason(
                      document.statusInfo.reasonCode,
                    ),
                  },
                },
              }),
          )
          .with(
            { statusInfo: { __typename: "CapitalDepositDocumentValidatedStatusInfo" } },
            document =>
              Option.Some({
                purpose: document.type,
                metadata: {
                  updatedAt: document.updatedAt,
                  createdAt: document.createdAt,
                  uploadedAt: document.uploadedAt,
                },
                file: {
                  id: document.id,
                  name: t("common.document"),
                  url: document.downloadUrl ?? "",
                  statusInfo: {
                    status: "Validated",
                  },
                },
              }),
          )
          .exhaustive(),
      );

      const hasPendingDocs =
        docs.filter(
          ({
            file: {
              statusInfo: { status },
            },
          }) => status !== "Validated",
        ).length > 0;

      return match(route)
        .with({ name: "CapitalDepositDetailShareholderRoot" }, () => (
          <LakeScrollView>
            {match(shareholder)
              .with({ status: "PendingOnboarding" }, () => {
                return (
                  <>
                    <LakeAlert
                      title={t("capitalDeposit.alert.pendingOnboarding", {
                        amount: capitalDepositAmount,
                      })}
                      variant="warning"
                    />

                    <Space height={24} />
                  </>
                );
              })
              .with(
                {
                  status: "WaitingForVerification",
                  onboarding: { accountHolder: { verificationStatus: "NotStarted" } },
                },
                () => {
                  return (
                    <>
                      <LakeAlert
                        title={t("capitalDeposit.alert.verifyIdentity", {
                          amount: capitalDepositAmount,
                        })}
                        variant="warning"
                      />

                      <Space height={24} />
                    </>
                  );
                },
              )
              .with(
                { status: "WaitingForVerification" },
                { status: "WaitingForTransfer" },
                ({ onboarding }) => {
                  if (
                    isNotNullish(onboarding) &&
                    isNotNullish(onboarding.account) &&
                    isNotNullish(onboarding.account.IBAN)
                  ) {
                    return (
                      <>
                        <LakeAlert
                          title={t("capitalDeposit.alert.amountToDeposit", {
                            amount: capitalDepositAmount,
                            iban: onboarding.account.IBAN,
                          })}
                          variant="info"
                        />

                        <Space height={24} />
                      </>
                    );
                  }
                },
              )
              .with({ status: "CapitalTransferred" }, () => (
                <>
                  <LakeAlert title={t("capitalDeposit.alert.capitalTransferred")} variant="info" />
                  <Space height={24} />
                </>
              ))
              .with({ status: "CapitalFundsWiredToNotary" }, () => (
                <>
                  <LakeAlert
                    title={t("capitalDeposit.alert.capitalFundsWiredToNotary")}
                    variant="success"
                  />

                  <Space height={24} />
                </>
              ))
              .otherwise(() => null)}

            <TileGrid>
              <Tile title={t("capitalDeposit.shareholder")}>
                <ReadOnlyFieldList>
                  <LakeLabel
                    type="view"
                    label={t("capitalDeposit.shareholders.name")}
                    render={() =>
                      match(shareholder.info)
                        .with(
                          { __typename: "IndividualShareholder" },
                          ({ firstName, lastName }) => (
                            <LakeText color={colors.gray[900]}>
                              {`${firstName} ${lastName}`}
                            </LakeText>
                          ),
                        )
                        .with({ __typename: "CompanyShareholder" }, ({ name }) => (
                          <LakeText color={colors.gray[900]}> {name}</LakeText>
                        ))
                        .exhaustive()
                    }
                  />

                  <LakeLabel
                    label={t("capitalDeposit.shareholder.status")}
                    type="view"
                    color="current"
                    render={() =>
                      match(shareholder.status)
                        .with("CapitalFundsWiredToNotary", () => (
                          <Tag color="positive">
                            {t("capitalDeposit.shareholders.status.capitalFundsWiredToNotary")}
                          </Tag>
                        ))
                        .with("CapitalTransferred", () => (
                          <Tag color="shakespear">
                            {t("capitalDeposit.shareholders.status.capitalTransferred")}
                          </Tag>
                        ))
                        .with("PendingOnboarding", () => (
                          <Tag color="gray">
                            {t("capitalDeposit.shareholders.status.pendingOnboarding")}
                          </Tag>
                        ))
                        .with("WaitingForTransfer", () => (
                          <Tag color="warning">
                            {t("capitalDeposit.shareholders.status.waitingForTransfer")}
                          </Tag>
                        ))
                        .with("WaitingForVerification", () => (
                          <Tag color="shakespear">
                            {t("capitalDeposit.shareholders.status.waitingForVerification")}
                          </Tag>
                        ))
                        .with("CapitalDepositCanceled", () => (
                          <Tag color="gray">
                            {t("capitalDeposit.shareholders.status.waitingForVerification")}
                          </Tag>
                        ))
                        .exhaustive()
                    }
                  />

                  <LakeLabel
                    type="view"
                    label={t("capitalDeposit.shareholder.type")}
                    render={() =>
                      match(shareholder.info.type)
                        .with("Company", () => (
                          <Tag color="shakespear" icon="building-regular">
                            {shareholder.info.type}
                          </Tag>
                        ))
                        .with("Individual", () => (
                          <Tag color="darkPink" icon="person-regular">
                            {shareholder.info.type}
                          </Tag>
                        ))
                        .exhaustive()
                    }
                  />

                  <LakeLabel
                    label={t("capitalDeposit.shareholders.id")}
                    type="view"
                    color="current"
                    render={() => <LakeText color={colors.gray[900]}>{shareholder.id}</LakeText>}
                    actions={
                      <LakeCopyButton
                        valueToCopy={shareholder.id}
                        copyText={t("copyButton.copyTooltip")}
                        copiedText={t("copyButton.copiedTooltip")}
                      />
                    }
                  />

                  <LakeLabel
                    label={t("capitalDeposit.shareholders.accountId")}
                    type="view"
                    render={() =>
                      isNotNullish(shareholder.accountId) ? (
                        <LakeText color={colors.gray[900]}>{shareholder.accountId}</LakeText>
                      ) : (
                        UNKNOWN_VALUE
                      )
                    }
                    actions={
                      isNotNullish(shareholder.accountId) ? (
                        <Link
                          to={Router.AccountDetailRoot({
                            projectId,
                            projectEnv,
                            accountId: shareholder.accountId,
                          })}
                        >
                          <Icon size={20} name="arrow-right-filled" />
                        </Link>
                      ) : null
                    }
                  />

                  <LakeLabel
                    type="view"
                    label={t("capitalDeposit.shareholder.amount")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {shareholder.capitalDepositAmount.value !== ""
                          ? formatCurrency(
                              Number(shareholder.capitalDepositAmount.value),
                              shareholder.capitalDepositAmount.currency,
                            )
                          : UNKNOWN_VALUE}
                      </LakeText>
                    )}
                  />

                  <LakeLabel
                    type="view"
                    label={t("capitalDeposit.shareholder.uploadedDocuments")}
                    render={() => {
                      const documentsWithValidatedOrUploadedStatus = shareholder.documents.filter(
                        ({ statusInfo: { status } }) =>
                          status === "Validated" || status === "Uploaded",
                      ).length;

                      return (
                        <LakeText color={colors.gray[900]}>
                          {String(documentsWithValidatedOrUploadedStatus)}
                        </LakeText>
                      );
                    }}
                  />

                  <LakeLabel
                    type="view"
                    label={t("capitalDeposit.shareholder.createdAt")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {dayjs(shareholder.createdAt).format(
                          `${locale.dateFormat} ${locale.timeFormat}`,
                        )}
                      </LakeText>
                    )}
                  />

                  <LakeLabel
                    type="view"
                    label={t("capitalDeposit.shareholder.updatedAt")}
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {dayjs(shareholder.updatedAt).format(
                          `${locale.dateFormat} ${locale.timeFormat}`,
                        )}
                      </LakeText>
                    )}
                  />
                </ReadOnlyFieldList>
              </Tile>

              <Tile title={t("capitalDeposit.documents")}>
                <SupportingDocumentCollection
                  status={hasPendingDocs ? "WaitingForDocument" : "Approved"}
                  uploadFile={({ upload, file }) =>
                    Request.make({
                      url: upload.url,
                      method: "PUT",
                      headers: {
                        "content-type": "multipart/form-data",
                      },
                      body: file,
                    })
                      .mapOkToResult(badStatusToError)
                      .tapOk(() =>
                        showToast({
                          variant: "success",
                          title: t("capitalDeposit.documentUploaded"),
                        }),
                      )
                      .tapError(error => {
                        showToast({
                          variant: "error",
                          error,
                          title: translateError(error),
                        });
                      })
                  }
                  generateUpload={({
                    fileName,
                    purpose,
                  }: {
                    fileName: string;
                    purpose: CapitalDepositDocumentType;
                  }) => {
                    const documentId = docs.find(doc => doc.purpose === purpose)?.file?.id ?? "";
                    return generateCapitalDepositDocument({
                      input: {
                        filename: fileName,
                        capitalDepositCaseId: capitalDepositId,
                        documentId,
                      },
                    })
                      .mapOk(data => data.generateCapitalDepositDocumentUploadUrl)
                      .mapOkToResult(input =>
                        match(input)
                          .with({ __typename: "CapitalDepositDocumentCanNotBeUploaded" }, () =>
                            Result.Error(input),
                          )
                          .otherwise(() => filterRejectionsToResult(input)),
                      )
                      .mapOk(input =>
                        match(input)
                          .with(
                            {
                              __typename: "GenerateCapitalDepositDocumentUploadUrlSuccessPayload",
                            },
                            ({ uploadUrl }) => ({
                              upload: { url: uploadUrl ?? "", fields: [] },
                              id: documentId,
                            }),
                          )
                          .otherwise(() => ({
                            upload: { url: "", fields: [] },
                            id: documentId,
                          })),
                      )
                      .mapError(error =>
                        showToast({
                          variant: "error",
                          error,
                          title: translateError(error),
                        }),
                      );
                  }}
                  documents={docs.filter(({ file: { url } }) => isNotNullishOrEmpty(url))}
                  readonlyDocumentPurposes={readonlyPurposes}
                  requiredDocumentPurposes={docs
                    .filter(({ file: { url } }) => isNullishOrEmpty(url))
                    .map(({ purpose }) => purpose)}
                  getPurposeMetadata={purpose => {
                    const doc = docs.find(({ purpose: p }) => p === purpose);
                    const file = doc?.file;
                    const values = (
                      [
                        isNotNullishOrEmpty(file?.id)
                          ? {
                              type: "copy",
                              title: t("capitalDeposit.documents.id"),
                              value: file.id,
                            }
                          : undefined,
                        isNotNullishOrEmpty(doc?.metadata.updatedAt)
                          ? {
                              type: "text",
                              title: t("capitalDeposit.documents.updatedAt"),
                              value: dayjs(doc?.metadata.updatedAt).format(
                                `${locale.dateFormat} ${locale.timeFormat}`,
                              ),
                            }
                          : undefined,
                        isNotNullishOrEmpty(doc?.metadata.createdAt)
                          ? {
                              type: "text",
                              title: t("capitalDeposit.documents.createdAt"),
                              value: dayjs(doc?.metadata.createdAt).format(
                                `${locale.dateFormat} ${locale.timeFormat}`,
                              ),
                            }
                          : undefined,
                        isNotNullishOrEmpty(doc?.metadata.uploadedAt)
                          ? {
                              type: "text",
                              title: t("capitalDeposit.documents.uploadedAt"),
                              value: dayjs(doc?.metadata.uploadedAt).format(
                                `${locale.dateFormat} ${locale.timeFormat}`,
                              ),
                            }
                          : undefined,
                        isNotNullishOrEmpty(file?.statusInfo.status)
                          ? {
                              type: "text",
                              title: t("capitalDeposit.documents.status"),
                              value: match(file.statusInfo.status)
                                .with("Pending", status => <Tag color="shakespear">{status}</Tag>)
                                .with("Refused", status => <Tag color="negative">{status}</Tag>)
                                .with("Uploaded", status => <Tag color="warning">{status}</Tag>)
                                .with("Uploading", status => <Tag color="gray">{status}</Tag>)
                                .with("Validated", status => <Tag color="positive">{status}</Tag>)
                                .exhaustive(),
                            }
                          : undefined,
                      ] as {
                        type?: "text" | "copy";
                        title: string;
                        value: string;
                      }[]
                    ).filter(isNotNullish);

                    if (isNotNullishOrEmpty(file?.id)) {
                      return {
                        title: t("capitalDeposit.documents.showDetails"),
                        values,
                      };
                    }
                  }}
                />
              </Tile>

              <Tile title={t("capitalDeposit.shareholder.onboarding")}>
                <ReadOnlyFieldList>
                  <LakeLabel
                    label={t("capitalDeposit.shareholder.companyOnboardingId")}
                    type="view"
                    color="current"
                    actions={
                      isNotNullish(shareholder.onboarding) ? (
                        <Link
                          to={Router.OnboardingDetailRoot({
                            projectId,
                            projectEnv,
                            onboardingId: shareholder.onboarding.id,
                          })}
                        >
                          <Icon size={20} name="arrow-right-filled" color={colors.swan[900]} />
                        </Link>
                      ) : (
                        UNKNOWN_VALUE
                      )
                    }
                    render={() => (
                      <LakeText color={colors.gray[900]}>{shareholder.onboarding?.id}</LakeText>
                    )}
                  />

                  <LakeLabel
                    label={t("capitalDeposit.shareholder.status")}
                    type="view"
                    color="current"
                    render={() =>
                      match(shareholder.onboarding?.statusInfo)
                        .with({ __typename: "OnboardingFinalizedStatusInfo" }, () => (
                          <Tag color="positive">
                            {t("capitalDeposit.companyOnboarding.completed")}
                          </Tag>
                        ))
                        .with(
                          { __typename: "OnboardingInvalidStatusInfo" },
                          { __typename: "OnboardingValidStatusInfo" },
                          () => (
                            <Tag color="warning">
                              {t("capitalDeposit.companyOnboarding.ongoing")}
                            </Tag>
                          ),
                        )
                        .otherwise(() => null)
                    }
                  />
                </ReadOnlyFieldList>
              </Tile>
            </TileGrid>
          </LakeScrollView>
        ))
        .otherwise(() => null);
    })
    .exhaustive();
};
