import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useForm } from "@swan-io/use-form";
import { useCallback } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import {
  SimulateUpdateAccountHolderDocument,
  VerificationStatus,
} from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

type FormValues = {
  companyAccountHolderId: string;
  verificationStatus: VerificationStatus;
};

const verificationStatusList: { value: VerificationStatus; name: string }[] = [
  { value: "NotStarted" as const, name: t("simulatorForm.verificationStatus.notStarted") },
  { value: "Pending" as const, name: t("simulatorForm.verificationStatus.pending") },
  { value: "Verified" as const, name: t("simulatorForm.verificationStatus.verified") },
  {
    value: "Refused" as const,
    name: t("simulatorForm.verificationStatus.refused"),
  },
  {
    value: "WaitingForInformation" as const,
    name: t("simulatorForm.verificationStatus.waitingForInformation"),
  },
];

export const UpdateAccountHolderPage = () => {
  const [simulate, simulation] = useMutation(SimulateUpdateAccountHolderDocument);

  const accountHolderResult = simulation.mapOkToResult(simulation =>
    match(simulation.updateAccountHolder)
      .with({ __typename: "UpdateAccountHolderSuccessPayload" }, ({ accountHolder }) =>
        Result.Ok([{ key: "accountHolderId", value: accountHolder.id }]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const { Field, submitForm, formStatus } = useForm<FormValues>({
    companyAccountHolderId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    verificationStatus: {
      initialValue: "NotStarted",
    },
  });

  const handleSubmit = useCallback(() => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isSome()) {
          const { companyAccountHolderId, verificationStatus } = option.get();
          return simulate({
            input: {
              accountHolderId: companyAccountHolderId,
              verificationStatus,
            },
          });
        }
      },
    });
  }, [simulate, submitForm]);

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.updateAccountHolderDescription")}{" "}
            <DocumentationLink to="simulatorCompanyHolders">
              {t("common.learnMore")}
            </DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="companyAccountHolderId">
            {({ value, onChange, error, valid }) => (
              <LakeLabel
                label={`${t("simulatorForm.accountHolderId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.accountHolderIdPlaceHolder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                  />
                )}
              />
            )}
          </Field>

          <Field name="verificationStatus">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.verificationStatus")}
                render={id => (
                  <LakeSelect
                    id={id}
                    items={verificationStatusList}
                    value={value}
                    onValueChange={onChange}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit account folder form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={handleSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "accountHolderId",
              label: t("simulatorForm.accountHolderId"),
              placeholder: "-",
            },
          ]}
          results={accountHolderResult}
        />
      </Box>
    </Form>
  );
};
