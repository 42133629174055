import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import {
  ColumnConfig,
  FixedListView,
  FixedListViewEmpty,
  FixedListViewPlaceholder,
} from "@swan-io/lake/src/components/FixedListView";
import {
  CellAction,
  CopyableRegularTextCell,
  EndAlignedCell,
  SimpleHeaderCell,
  SimpleRegularTextCell,
} from "@swan-io/lake/src/components/FixedListViewCells";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Toggle } from "@swan-io/lake/src/components/Toggle";
import { colors } from "@swan-io/lake/src/constants/design";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import dayjs from "dayjs";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import {
  MerchantProfileFiltersInput,
  MerchantProfileFragment,
  MerchantProfilesDocument,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { GetRouteParams, Router } from "../utils/routes";
import { AccountDetailMerchantProfileRequestWizard } from "./AccountDetailMerchantProfileRequestWizard";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";

const styles = StyleSheet.create({
  endFilters: {
    flexGrow: 0,
    flexShrink: 1,
  },
});

type Props = {
  accountId: string;
  params: GetRouteParams<"AccountDetailMerchantList">;
};

const PER_PAGE = 20;

type ExtraInfo = undefined;

const columns: ColumnConfig<MerchantProfileFragment, ExtraInfo>[] = [
  {
    id: "merchantName",
    width: 200,
    title: t("merchantProfile.list.merchantName"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item }) => <SimpleRegularTextCell text={item.merchantName} />,
  },
  {
    id: "merchantId",
    width: 400,
    title: t("merchantProfile.list.merchantId"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item }) => (
      <CopyableRegularTextCell
        text={item.id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    id: "updatedAt",
    width: 200,
    title: t("merchantProfile.list.updatedAt"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item }) => (
      <SimpleRegularTextCell
        text={dayjs(item.updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
      />
    ),
  },
];

const stickedToEndColumns: ColumnConfig<MerchantProfileFragment, ExtraInfo>[] = [
  {
    id: "status",
    width: 200,
    title: "Status",
    renderTitle: () => null,
    renderCell: ({ item }) => (
      <EndAlignedCell>
        {match(item.statusInfo.status)
          .with("Enabled", () => <Tag color="positive">{t("merchantProfile.status.enabled")}</Tag>)
          .with("PendingReview", () => (
            <Tag color="shakespear">{t("merchantProfile.status.pendingReview")}</Tag>
          ))
          .with("Rejected", () => (
            <Tag color="negative">{t("merchantProfile.status.rejected")}</Tag>
          ))
          .with("Suspended", () => (
            <Tag color="warning">{t("merchantProfile.status.suspended")}</Tag>
          ))
          .with("Disabled", () => <Tag color="gray">{t("merchantProfile.status.disabled")}</Tag>)
          .otherwise(() => null)}
      </EndAlignedCell>
    ),
  },
  {
    id: "actions",
    width: 100,
    title: "",
    renderTitle: () => null,
    renderCell: ({ isHovered }) => (
      <EndAlignedCell>
        <CellAction>
          <Box direction="row" justifyContent="end" alignItems="center">
            <Icon
              name="chevron-right-filled"
              color={isHovered ? colors.gray[900] : colors.gray[500]}
              size={16}
            />
          </Box>
        </CellAction>
      </EndAlignedCell>
    ),
  },
];

export const AccountDetailMerchantList = ({ accountId, params }: Props) => {
  const { status = "Active" } = params;
  const { projectId, projectEnv } = useProjectInfo();

  const filters: MerchantProfileFiltersInput = useMemo(() => {
    return {
      status: match(status)
        .returnType<MerchantProfileFiltersInput["status"]>()
        .with("Active", () => ["Enabled", "PendingReview"])
        .with("Inactive", () => ["Disabled", "Rejected", "Suspended"])
        .exhaustive(),
    } as const;
  }, [status]);

  const [data, { isLoading, setVariables, reload }] = useQuery(MerchantProfilesDocument, {
    first: PER_PAGE,
    accountId,
    filters,
  });

  const merchantProfiles = data.mapOkToResult(data =>
    Option.fromNullable(data.account?.merchantProfiles).toResult(undefined),
  );

  return match(merchantProfiles)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
      <FixedListViewPlaceholder
        headerHeight={48}
        rowHeight={48}
        rowVerticalSpacing={4}
        count={20}
      />
    ))
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
      <>
        <>
          <Box direction="row" alignItems="center">
            <LakeButton
              size="small"
              color="current"
              icon="add-circle-filled"
              onPress={event => {
                event.preventDefault();
                event.stopPropagation();

                Router.push("AccountDetailMerchantList", {
                  ...params,
                  new: "true",
                });
              }}
            >
              {t("merchant.new")}
            </LakeButton>

            <Space width={16} />

            <LakeButton
              ariaLabel={t("common.refresh")}
              mode="secondary"
              size="small"
              icon="arrow-counterclockwise-filled"
              onPress={() => {
                reload();
              }}
            />

            <Fill minWidth={16} />

            <Box direction="row" alignItems="center" justifyContent="end" style={styles.endFilters}>
              <Toggle
                value={status === "Active"}
                onToggle={status =>
                  Router.push("AccountDetailMerchantList", {
                    accountId,
                    projectEnv,
                    projectId,
                    status: status ? "Active" : "Inactive",
                  })
                }
                onLabel={t("merchantProfile.list.Active")}
                offLabel={t("merchantProfile.list.Inactive")}
              />
            </Box>
          </Box>

          <Space height={16} />

          <Connection connection={data}>
            {merchantProfiles => (
              <FixedListView
                data={merchantProfiles?.edges.map(({ node }) => node) ?? []}
                keyExtractor={item => item.id}
                extraInfo={undefined}
                columns={columns}
                stickedToEndColumns={stickedToEndColumns}
                headerHeight={48}
                rowHeight={48}
                rowVerticalSpacing={4}
                onEndReached={() => {
                  if (merchantProfiles?.pageInfo.hasNextPage === true) {
                    setVariables({ after: merchantProfiles.pageInfo.endCursor });
                  }
                }}
                loading={{ isLoading, count: PER_PAGE }}
                renderEmptyList={() => (
                  <FixedListViewEmpty
                    icon="lake-merchant"
                    borderedIcon={true}
                    title={t("merchantProfile.list.noResults")}
                  />
                )}
                getRowLink={({ item }) => (
                  <Link
                    to={Router.AccountDetailMerchantProfile({
                      accountId,
                      projectEnv,
                      projectId,
                      merchantProfileId: item.id,
                    })}
                  />
                )}
              />
            )}
          </Connection>
        </>

        <LakeModal
          visible={params.new === "true"}
          title={t("merchantProfile.request.title")}
          icon="add-circle-regular"
          maxWidth={850}
        >
          <AccountDetailMerchantProfileRequestWizard
            accountId={accountId}
            onPressClose={() =>
              Router.push("AccountDetailMerchantList", {
                accountId,
                projectEnv: params.projectEnv,
                projectId: params.projectId,
              })
            }
          />
        </LakeModal>
      </>
    ))
    .exhaustive();
};
