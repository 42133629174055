import { Array, AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { FixedListViewPlaceholder, LinkConfig } from "@swan-io/lake/src/components/FixedListView";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { identity } from "@swan-io/lake/src/utils/function";
import { isNotNullish, nullishOrEmptyToUndefined } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import { useCallback, useMemo } from "react";
import { P, match } from "ts-pattern";
import { AccountMembershipList } from "../components/AccountMembershipList";
import {
  AccountMembershipsFilters,
  AccountMembershipsFiltersForm,
  parseBooleanParam,
} from "../components/AccountMembershipsFiltersForm";
import { Connection } from "../components/Connection";
import { ErrorView } from "../components/ErrorView";
import {
  AccountMembershipListFragment,
  AccountMembershipOrderByFieldInput,
  AccountMembershipOrderByInput,
  GetAccountMembershipListDocument,
  OrderByDirection,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";

type Props = {
  params: RouteParams["AccountMembershipsRoot"];
};

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: AccountMembershipOrderByInput) => void;
  sortBy?: AccountMembershipOrderByInput;
  reexecuteQuery: () => void;
};

const PER_PAGE = 20;

const statusList = [
  "BindingUserError",
  "ConsentPending",
  "Disabled",
  "Enabled",
  "InvitationSent",
  "Suspended",
] as const;

export const AccountMembershipListPage = ({ params }: Props) => {
  const { projectEnv, projectId } = useProjectInfo();
  const serializedStatus = JSON.stringify(params.status ?? null);

  const filters: AccountMembershipsFilters = useMemo(() => {
    const status = JSON.parse(serializedStatus) as string[] | null | undefined;

    return {
      status: isNotNullish(status)
        ? Array.filterMap(status, item =>
            match(item)
              .with(...statusList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
      canViewAccount: parseBooleanParam(params.canViewAccount),
      canManageCards: parseBooleanParam(params.canManageCards),
      canInitiatePayments: parseBooleanParam(params.canInitiatePayments),
      canManageAccountMembership: parseBooleanParam(params.canManageAccountMembership),
      canManageBeneficiaries: parseBooleanParam(params.canManageBeneficiaries),
    } as const;
  }, [
    params.canViewAccount,
    params.canManageCards,
    params.canInitiatePayments,
    params.canManageAccountMembership,
    params.canManageBeneficiaries,
    serializedStatus,
  ]);

  const sortBy: AccountMembershipOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<AccountMembershipOrderByFieldInput>()
        .with("createdAt", "updatedAt", identity)
        .otherwise(() => "createdAt"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const search = nullishOrEmptyToUndefined(params.search);
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetAccountMembershipListDocument, {
    first: PER_PAGE,
    filters: { ...filters, search },
    orderBy: sortBy,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("AccountMembershipsRoot", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
      reexecuteQuery: reload,
    };
  }, [projectEnv, projectId, sortBy, reload, params]);

  const getRowLink = useCallback(
    ({
      item: {
        node: { id },
      },
      extraInfo: { projectEnv, projectId },
    }: LinkConfig<GetEdge<AccountMembershipListFragment>, ExtraInfo>) => (
      <Link
        to={Router.AccountMembershipsDetailRoot({
          projectId,
          projectEnv,
          accountMembershipId: id,
        })}
      />
    ),
    [],
  );

  const accountMemberships = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ accountMemberships }) => accountMemberships);

  const totalCount = accountMemberships.map(({ totalCount }) => totalCount);

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <AccountMembershipsFiltersForm
        filters={filters}
        search={search}
        totalCount={totalCount}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("AccountMembershipsRoot", {
            ...params,
            ...filters,
            canViewAccount: String(filters.canViewAccount),
            canManageCards: String(filters.canManageCards),
            canInitiatePayments: String(filters.canInitiatePayments),
            canManageAccountMembership: String(filters.canManageAccountMembership),
            canManageBeneficiaries: String(filters.canManageBeneficiaries),
          });
        }}
        onChangeSearch={search => {
          Router.replace("AccountMembershipsRoot", { ...params, search });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <FixedListViewPlaceholder
            headerHeight={48}
            rowHeight={48}
            rowVerticalSpacing={4}
            count={PER_PAGE}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.accountMemberships}>
            {accountMemberships => (
              <AccountMembershipList
                accountMemberships={accountMemberships.edges}
                onEndReached={() => {
                  if (accountMemberships.pageInfo.hasNextPage === true) {
                    setVariables({ after: accountMemberships.pageInfo.endCursor ?? undefined });
                  }
                }}
                isLoading={isLoading}
                perPage={PER_PAGE}
                extraInfo={extraInfo}
                emptyListTitle={t("accountsMemberships.list.empty")}
                getRowLink={getRowLink}
                hasSearchOrFilters={hasSearchOrFilters}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
