import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabelledCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { colors } from "@swan-io/lake/src/constants/design";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { countries as countryList } from "@swan-io/shared-business/src/constants/countries";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useForm } from "@swan-io/use-form";
import dayjs from "dayjs";
import { useCallback } from "react";
import { StyleSheet } from "react-native";
import { Rifm } from "rifm";
import { CreateSandboxUserDocument } from "../graphql/sandbox-partner-admin";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { locale, rifmDateProps, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { validateDate, validateRequired } from "../utils/validations";

const API_BIRTHDATE_FORMAT = "YYYY-MM-DD";

const styles = StyleSheet.create({
  optionFlag: {
    display: "inline-block",
    width: 21,
  },
});

const countries = countryList.map(country => ({
  name: country.name,
  icon: (
    <LakeText color={colors.gray[900]} style={styles.optionFlag}>
      {country.flag}
    </LakeText>
  ),
  value: country.cca3,
}));

type Props = {
  onSave: () => void;
  onPressCancel?: () => void;
};

export const NewSandboxUserPage = ({ onSave, onPressCancel }: Props) => {
  const { projectId } = useProjectInfo();
  const [createSandboxUser, sandboxUserCreation] = useMutation(CreateSandboxUserDocument);

  const { Field, submitForm, formStatus } = useForm({
    firstName: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    lastName: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    birthCountryCCA3: {
      initialValue: "FRA",
      validate: validateRequired,
    },
    birthDate: {
      initialValue: "",
      validate: validateDate,
    },
    autoConsent: {
      initialValue: false,
    },
    nationalityCCA3: {
      initialValue: "FRA",
    },
  });

  const onPressCreate = useCallback(() => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isSome()) {
          const { birthDate, ...input } = option.get();

          return createSandboxUser({
            input: {
              ...input,
              birthDate: dayjs(birthDate, locale.dateFormat, true).format(API_BIRTHDATE_FORMAT),
            },
          })
            .mapOk(data => data.createSandboxUser)
            .mapOkToResult(data => (isNotNullish(data) ? Result.Ok(data) : Result.Error(undefined)))
            .mapOkToResult(filterRejectionsToResult)
            .tapOk(() => {
              showToast({ variant: "success", title: t("toast.success.sandboxUserCreated") });
              Router.push("DevelopersUsersRoot", { projectId, projectEnv: "sandbox" });
              onSave();
            })
            .tapError(error => {
              showToast({ variant: "error", error, title: translateError(error) });
            })
            .toPromise();
        }
      },
    });
  }, [createSandboxUser, projectId, submitForm, onSave]);

  return (
    <>
      <Field name="firstName">
        {({ value, valid, error, onChange, onBlur }) => (
          <LakeLabel
            label={t("sandboxUsers.form.firstName")}
            render={id => (
              <LakeTextInput
                id={id}
                value={value}
                placeholder="John"
                onChangeText={onChange}
                onBlur={onBlur}
                valid={valid}
                error={error}
              />
            )}
          />
        )}
      </Field>

      <Field name="lastName">
        {({ value, valid, error, onChange, onBlur }) => (
          <LakeLabel
            label={t("sandboxUsers.form.lastName")}
            render={id => (
              <LakeTextInput
                id={id}
                value={value}
                placeholder="Doe"
                onChangeText={onChange}
                onBlur={onBlur}
                valid={valid}
                error={error}
              />
            )}
          />
        )}
      </Field>

      <Field name="nationalityCCA3">
        {({ value, onChange }) => (
          <LakeLabel
            label={t("sandboxUsers.form.nationality")}
            render={id => (
              <LakeSelect id={id} value={value} items={countries} onValueChange={onChange} />
            )}
          />
        )}
      </Field>

      <Field name="birthDate">
        {({ value, valid, error, onChange, onBlur }) => (
          <LakeLabel
            label={t("sandboxUsers.form.birthdate")}
            render={id => (
              <Rifm value={value} onChange={onChange} {...rifmDateProps}>
                {({ value, onChange }) => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={locale.datePlaceholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    valid={valid}
                    error={error}
                  />
                )}
              </Rifm>
            )}
          />
        )}
      </Field>

      <Field name="birthCountryCCA3">
        {({ value, onChange }) => (
          <LakeLabel
            label={t("sandboxUsers.form.birthCountry")}
            render={id => (
              <LakeSelect id={id} value={value} items={countries} onValueChange={onChange} />
            )}
          />
        )}
      </Field>

      <Field name="autoConsent">
        {({ value, onChange }) => (
          <LakeLabelledCheckbox
            value={value}
            onValueChange={onChange}
            label={t("sandboxUsers.form.autoConsent")}
          />
        )}
      </Field>

      <Space height={12} />

      <LakeButtonGroup>
        {onPressCancel != null ? (
          <LakeButton onPress={onPressCancel} mode="secondary" grow={true}>
            {t("common.cancel")}
          </LakeButton>
        ) : null}

        <LakeButton
          onPress={onPressCreate}
          grow={true}
          icon="add-circle-filled"
          disabled={formStatus === "submitting"}
          color="current"
          loading={sandboxUserCreation.isLoading()}
        >
          {t("common.create")}
        </LakeButton>
      </LakeButtonGroup>
    </>
  );
};
