import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import {
  EndAlignedCell,
  SimpleHeaderCell,
  StartAlignedCell,
} from "@swan-io/lake/src/components/FixedListViewCells";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { ColumnConfig, PlainListView } from "@swan-io/lake/src/components/PlainListView";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { backgroundColor, colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { useBoolean } from "@swan-io/lake/src/hooks/useBoolean";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import dayjs from "dayjs";
import { useState } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { GetUserQuery, UserIdentificationsFragment } from "../graphql/partner";
import { t } from "../utils/i18n";
import { LakeDocumentationLink } from "./DocumentationLink";
import { EmptyTile } from "./EmptyTile";
import { UserIdentificationHistory, minWidth, translateReason } from "./UserIdentificationHistory";
import { UserIdentificationProcessHelp } from "./UserIdentificationProcessHelp";

const styles = StyleSheet.create({
  identificationCell: {
    alignSelf: "flex-start",
    height: 56,
  },
  identificationEndCell: {
    flexGrow: 1,
    flexDirection: "column",
  },
  identificationEndCellContents: {
    height: 56,
  },
  identificationModalContents: {
    marginHorizontal: negativeSpacings[40],
  },
});

type UserIdentificationsTileProps = {
  user: UserIdentificationsFragment;
};

type UserIdentification = NonNullable<
  NonNullable<UserIdentificationsFragment["identifications"]>["edges"][number]
>;

type UserIdentificationExtraInfo = {
  openReasons: Set<string>;
  onToggleReason: (id: string) => void;
};

export const NB_MAX_IDENTIFICATIONS_TO_DISPLAY = 8;

const userIdentificationColumns: ColumnConfig<UserIdentification, UserIdentificationExtraInfo>[] = [
  {
    id: "process",
    width: 80,
    title: t("user.details.identifications.process"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { process },
      },
    }) => (
      <View style={styles.identificationCell}>
        <StartAlignedCell>
          <Tag color="gray">
            {match(process)
              .with("Expert", () => t("user.details.identifications.process.expert"))
              .with("QES", () => t("user.details.identifications.process.qes"))
              .with("PVID", () => t("user.details.identifications.process.pvid"))
              .exhaustive()}
          </Tag>
        </StartAlignedCell>
      </View>
    ),
  },
  {
    id: "createdAt",
    width: 140,
    title: t("user.details.identifications.createdAt"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) => (
      <View style={styles.identificationCell}>
        <StartAlignedCell>
          <LakeText variant="smallRegular" color={colors.gray[900]}>
            {dayjs(createdAt).format("LL")}
          </LakeText>
        </StartAlignedCell>
      </View>
    ),
  },
  {
    id: "updatedAt",
    width: 140,
    title: t("user.details.identifications.updatedAt"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }) => (
      <View style={styles.identificationCell}>
        <StartAlignedCell>
          <LakeText variant="smallRegular" color={colors.gray[900]}>
            {dayjs(updatedAt).format("LL")}
          </LakeText>
        </StartAlignedCell>
      </View>
    ),
  },
  {
    id: "status",
    width: "grow",
    title: t("user.details.identifications.status"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} justifyContent="flex-end" />,
    renderCell: ({ item: { node }, extraInfo: { openReasons, onToggleReason } }) => {
      const { id, process, levels } = node;
      const status = match(process)
        .with("Expert", () =>
          levels.expert.__typename === "NotStartedIdentificationLevelStatusInfo"
            ? "Started"
            : levels.expert.status,
        )
        .with("QES", () =>
          levels.qes.__typename === "NotStartedIdentificationLevelStatusInfo"
            ? "Started"
            : levels.qes.status,
        )
        .with("PVID", () =>
          levels.pvid.__typename === "NotStartedIdentificationLevelStatusInfo"
            ? "Started"
            : levels.pvid.status,
        )
        .exhaustive();

      const reasons = match(node)
        .with(
          {
            process: "Expert",
            levels: {
              expert: { __typename: "InvalidIdentificationLevelStatusInfo", reasons: P.select() },
            },
          },
          value => value,
        )
        .with(
          {
            process: "QES",
            levels: {
              qes: { __typename: "InvalidIdentificationLevelStatusInfo", reasons: P.select() },
            },
          },
          value => value,
        )
        .with(
          {
            process: "PVID",
            levels: {
              pvid: { __typename: "InvalidIdentificationLevelStatusInfo", reasons: P.select() },
            },
          },
          value => value,
        )
        .otherwise(() => undefined);

      return (
        <View style={styles.identificationEndCell}>
          <View style={styles.identificationEndCellContents}>
            <EndAlignedCell>
              {match(status)
                .with("NotStarted", () => (
                  <Tag color="gray">{t("user.details.identifications.status.notStarted")}</Tag>
                ))
                .with("Canceled", () => (
                  <Tag color="gray">{t("user.details.identifications.status.canceled")}</Tag>
                ))
                .with("Expired", () => (
                  <Tag color="gray">{t("user.details.identifications.status.expired")}</Tag>
                ))
                .with("Invalid", () => (
                  <>
                    {reasons != null && reasons.length > 0 ? (
                      <>
                        <LakeButton
                          mode="tertiary"
                          icon="info-regular"
                          ariaLabel={t("common.learnMore")}
                          onPress={() => onToggleReason(id)}
                        />

                        <Space width={4} />
                      </>
                    ) : null}

                    <Tag color="negative">{t("user.details.identifications.status.invalid")}</Tag>
                  </>
                ))
                .with("NotSupported", () => (
                  <Tag color="gray">{t("user.details.identifications.status.notSupported")}</Tag>
                ))
                .with("Pending", () => (
                  <Tag color="shakespear">{t("user.details.identifications.status.pending")}</Tag>
                ))
                .with("Started", () => (
                  <Tag color="shakespear">{t("user.details.identifications.status.started")}</Tag>
                ))
                .with("Valid", () => (
                  <Tag color="positive">{t("user.details.identifications.status.valid")}</Tag>
                ))
                .exhaustive()}
            </EndAlignedCell>
          </View>

          {openReasons.has(id) ? (
            <>
              <Space height={8} />

              <View
                style={{
                  marginLeft: -userIdentificationColumns.reduce(
                    (acc, item) => acc + (typeof item.width === "number" ? item.width : 0),
                    0,
                  ),
                }}
              >
                <LakeAlert title={t("user.details.identifications.reason")} variant="error">
                  {reasons?.map((item, index) => (
                    <LakeText color={colors.gray[500]} variant="regular" key={index}>
                      {translateReason(item)}
                    </LakeText>
                  ))}

                  <Space height={8} />

                  <LakeDocumentationLink to="userIdentifications" color={colors.negative[700]}>
                    {t("common.learnMore")}
                  </LakeDocumentationLink>
                </LakeAlert>
              </View>

              <Space height={8} />
            </>
          ) : null}
        </View>
      );
    },
  },
];

export const UserIdentificationsTile = ({ user }: UserIdentificationsTileProps) => {
  const identifications: NonNullable<
    NonNullable<GetUserQuery["user"]>["identifications"]
  >["edges"] = user.identifications?.edges ?? [];

  const [openReasons, setOpenReasons] = useState<Set<string>>(() => new Set());
  const [openDetailedList, setOpenDetailedList] = useBoolean(false);
  const [openHelp, setOpenHelp] = useBoolean(false);

  if (identifications.length === 0) {
    return (
      <EmptyTile
        title={t("user.details.identifications.identificationHistory")}
        link={t("user.details.identifications.empty.seeAll")}
        icon="lake-id-card"
        subTitle={t("user.details.identifications.empty.subtitle")}
        description={t("user.details.identifications.empty.description")}
      />
    );
  }

  return (
    <Tile flexGrow={1}>
      <Box direction="row" alignItems="center" style={{ marginRight: negativeSpacings[24] }}>
        <LakeHeading level={2} variant="h3">
          {t("user.details.identifications.identificationHistory")}
        </LakeHeading>

        <Fill minWidth={16} />

        <LakeButton mode="tertiary" onPress={setOpenHelp.on} icon="question-circle-regular">
          {t("user.details.identifications.help")}
        </LakeButton>

        <LakeButton mode="tertiary" onPress={setOpenDetailedList.on}>
          {t("user.details.identifications.seeMore", {
            count: user.identifications?.totalCount ?? 0,
          })}
        </LakeButton>
      </Box>

      <ScrollView
        style={{
          height: 48 + 56 * NB_MAX_IDENTIFICATIONS_TO_DISPLAY + NB_MAX_IDENTIFICATIONS_TO_DISPLAY,
          marginHorizontal: negativeSpacings[40],
        }}
      >
        <PlainListView<
          NonNullable<NonNullable<GetUserQuery["user"]>["identifications"]>["edges"][number],
          UserIdentificationExtraInfo
        >
          headerBackgroundColor={backgroundColor.accented}
          data={identifications.slice(0, NB_MAX_IDENTIFICATIONS_TO_DISPLAY)}
          keyExtractor={item => item.node.id}
          extraInfo={{
            openReasons,
            onToggleReason: (id: string) => {
              setOpenReasons(reasons => {
                const reasonsAsArray = [...reasons.values()];
                return reasons.has(id)
                  ? new Set(reasonsAsArray.filter(item => item != id))
                  : new Set([...reasonsAsArray, id]);
              });
            },
          }}
          columns={userIdentificationColumns}
          headerHeight={48}
          rowHeight={56}
          getRowLink={() => <View />}
          groupHeaderHeight={48}
          breakpoint={0}
          withoutScroll={true}
        />
      </ScrollView>

      <LakeModal
        maxWidth={minWidth + 40 * 2}
        visible={openDetailedList}
        onPressClose={setOpenDetailedList.off}
        title={t("user.details.identifications.identificationHistory")}
      >
        <View style={styles.identificationModalContents}>
          <UserIdentificationHistory userId={user.id} />
        </View>
      </LakeModal>

      <LakeModal
        visible={openHelp}
        onPressClose={setOpenHelp.off}
        title={t("user.details.identifications.identificationProcess")}
        icon="question-circle-regular"
      >
        <UserIdentificationProcessHelp />
      </LakeModal>
    </Tile>
  );
};
