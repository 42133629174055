import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { MultiSelect } from "@swan-io/lake/src/components/MultiSelect";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { deriveUnion } from "@swan-io/lake/src/utils/function";
import { useForm } from "@swan-io/use-form";
import { useCallback } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import { SupportingDocumentPurposeEnum } from "../../graphql/exposed-internal";
import { RequestSupportingDocumentsDocument } from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

const items = deriveUnion<SupportingDocumentPurposeEnum>({
  AdministratorDecisionOfAppointment: true,
  AssociationRegistration: true,
  Banking: true,
  CompanyObligations: true,
  CompanyRegistration: true,
  CompanyTreasury: true,
  Donation: true,
  FinancialStatements: true,
  GamblingPrizeWinnings: true,
  GeneralAssemblyMinutes: true,
  Inheritance: true,
  Investment: true,
  LegalRepresentativeProofOfIdentity: true,
  NIFAccreditationCard: true,
  Other: true,
  PersonalIncome: true,
  PersonalSavings: true,
  PowerOfAttorney: true,
  PresidentDecisionOfAppointment: true,
  ProofOfCompanyAddress: true,
  ProofOfCompanyIncome: true,
  ProofOfIdentity: true,
  ProofOfIndividualAddress: true,
  ProofOfIndividualIncome: true,
  ProofOfOriginOfFunds: true,
  RealEstateIncome: true,
  SignedStatus: true,
  SwornStatement: true,
  Trade: true,
  UBODeclaration: true,
  UltimateBeneficialOwnerProofOfAddress: true,
  UltimateBeneficialOwnerProofOfIdentity: true,
}).array.map(purpose => ({
  name: purpose,
  value: purpose,
  group: t("simulatorForm.supportingDocumentPurpose"),
  label: purpose,
}));

export const RequestSupportingDocumentsPage = () => {
  const [simulate, simulation] = useMutation(RequestSupportingDocumentsDocument);

  const documentResult = simulation.mapOkToResult(simulation =>
    match(simulation.requestSupportingDocuments)
      .with(
        { __typename: "RequestSupportingDocumentsSuccessPayload" },
        ({ supportingDocumentCollection }) =>
          Result.Ok([
            { key: "supportingDocumentCollectionId", value: supportingDocumentCollection.id },
          ]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const { Field, submitForm, formStatus } = useForm<{
    requiredSupportingDocumentPurposes: SupportingDocumentPurposeEnum[];
    supportingDocumentCollectionId: string;
  }>({
    supportingDocumentCollectionId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    requiredSupportingDocumentPurposes: {
      initialValue: [],
    },
  });

  const handleSubmit = useCallback(() => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isSome()) {
          const { requiredSupportingDocumentPurposes, supportingDocumentCollectionId } =
            option.get();

          return simulate({
            input: {
              supportingDocumentCollectionId,
              requiredSupportingDocumentPurposes,
            },
          });
        }
      },
    });
  }, [simulate, submitForm]);

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.updateSupportingDocumentCollectionStatusDescription")}

            <DocumentationLink to="simulatorSupportingCollectionStatus">
              {t("common.learnMore")}
            </DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="supportingDocumentCollectionId">
            {({ value, onChange, error, valid }) => (
              <LakeLabel
                label={`${t("simulatorForm.supportingDocumentCollectionId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    error={error}
                    valid={valid}
                    placeholder={t("simulatorForm.supportingDocumentCollectionIdPlaceholder")}
                    value={value}
                    onChangeText={onChange}
                  />
                )}
              />
            )}
          </Field>

          <Field name="requiredSupportingDocumentPurposes">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.supportingDocumentPurposes")}
                render={id => (
                  <MultiSelect
                    id={id}
                    color="current"
                    emptyResultText={t("common.noResult")}
                    filterPlaceholder={t("common.search.placeholder")}
                    items={items}
                    onValueChange={value => onChange(value as SupportingDocumentPurposeEnum[])}
                    placeholder={t("simulatorForm.supportingDocumentPurposePlaceholder")}
                    values={value}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit supporting document collection status form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={handleSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "supportingDocumentCollectionId",
              label: t("simulatorForm.supportingDocumentCollectionId"),
              placeholder: "-",
            },
          ]}
          results={documentResult}
        />
      </Box>
    </Form>
  );
};
