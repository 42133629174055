import { Array, AsyncData, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { P, isMatching, match } from "ts-pattern";
import {
  CancelConsentByIdDocument,
  GetServerConsentProjectSettingsDocument,
} from "../graphql/admin";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { ErrorView } from "./ErrorView";
import { ServerConsentForm } from "./ServerConsentForm";
import { TrackPressable } from "./TrackPressable";

export const ServerConsentPendingTab = () => {
  const { projectId, projectEnv } = useProjectInfo();

  const [consentCancelVisible, { open: openCancelConfirmModal, close: closeCancelConfirmModal }] =
    useDisclosure(false);

  const [data] = useQuery(GetServerConsentProjectSettingsDocument, {
    projectId,
    env: capitalize(projectEnv),
  });

  const [cancelConsentById, cancelation] = useMutation(CancelConsentByIdDocument);

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TileGridPlaceholder withTabs={false} />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => {
      const settings = data.serverConsentProjectSettings;
      const hasPendingConsent = isNotNullish(settings?.pendingConsent);

      const pendingIpAddresses = Array.findMap(
        settings?.pendingConsent?.consentOperations ?? [],
        item => Option.fromNullable(item.props),
      )
        .flatMap(props => {
          if (Array.isArray(props)) {
            return Array.findMap(props, x =>
              x.name === "AllowedIpAddresses" ? Option.Some(x.value) : Option.None(),
            );
          } else {
            return Option.None();
          }
        })
        .flatMap(values => {
          try {
            const parsed: unknown = JSON.parse(values);
            if (isMatching(P.array(P.string), parsed)) {
              return Option.Some(parsed);
            }
            return Option.None();
          } catch {
            return Option.None();
          }
        });

      const initialPublicKey = settings?.pendingConsent?.consentOperations
        .flat()
        .map(item => item?.props ?? [])
        .flat()
        .find(item => item.name === "PublicKey")?.value;

      const onPressCancel = () => {
        const pendingConsentId = settings?.pendingConsentId;

        if (isNotNullish(pendingConsentId)) {
          cancelConsentById({
            input: {
              consentId: pendingConsentId,
              env: capitalize(projectEnv),
            },
          })
            .mapOk(data => data.cancelConsentById)
            .tapOk(data => {
              if (data.status === "Canceled") {
                showToast({ variant: "success", title: t("toast.success.consentCanceled") });
                Router.push("DevelopersServerConsentDraft", { projectId, projectEnv });
              }
            })
            .tapError(error => {
              showToast({ variant: "error", error, title: translateError(error) });
            });
        }
      };

      if (!hasPendingConsent) {
        return (
          <Box alignItems="center" justifyContent="center" style={commonStyles.fill}>
            <Icon color={colors.current.primary} name="cloud-sync-regular" size={42} />
            <Space height={12} />
            <LakeText variant="medium">{t("serverConsent.pending.empty.title")}</LakeText>
            <LakeText variant="medium">{t("serverConsent.pending.empty.subtitle")}</LakeText>
          </Box>
        );
      }

      return (
        <>
          <LakeModal
            visible={consentCancelVisible}
            title={t("serverConsent.cancel.title")}
            icon="delete-regular"
            color="negative"
            onPressClose={closeCancelConfirmModal}
          >
            <LakeText>{t("serverConsent.cancel.subtitle")}</LakeText>
            <Space height={24} />

            <TrackPressable action="Cancel pending server consent">
              <LakeButton onPress={onPressCancel} color="negative">
                {t("serverConsent.form.cancel")}
              </LakeButton>
            </TrackPressable>
          </LakeModal>

          <LakeAlert variant="info" title={t("serverConsent.confirm.text")} />
          <Space height={24} />

          <ServerConsentForm
            initialPublicKey={initialPublicKey}
            initialAllowedIpAddresses={pendingIpAddresses.getOr([])}
            readOnly={true}
            variant="Pending"
            onCancelPending={openCancelConfirmModal}
            loading={cancelation.isLoading()}
          />
        </>
      );
    })
    .exhaustive();
};
